import React, { Component } from 'react';
import '../../App.css';


class Widgets extends Component {
    
    render() {
        //console.log("this.props",this.props);
    var listItems = this.props.recentSearch && this.props.recentSearch.map((item,index)=> {
        return (           
          item.length>0 && <button key={index} onClick={()=>this.props.searchQuery(item)} className="btn btn-outline-secondary rounded d-inline-block mr-3 mt-3 text-left " >{item}</button>
        );
    });
    return (
    <div className="clearfix pt-3 mt-3" >
    

   
                {this.props.recentSearch && this.props.recentSearch.length > 0 && 
                <div className="card mt-3 rounded shadow p-2 radius-lg" >
                <div className="card-body">
                  <div className="card-title border-bottom pb-2 ">
                  <a className="d-block" data-toggle="collapse" href="#test-block" aria-expanded="true" aria-controls="test-block">
                  Recent queries <span className="pl-5 ml-5"><i className="fa fa-angle-down pl-3"></i></span>
        </a>
        
                    </div>
                    <div id="test-block" class="collapse show">
                  {listItems}
                  </div>
                   </div>
                </div>}         


         {this.props.quickActions ? <div className="card mt-5 rounded shadow p-2 radius-lg" >
                <div className="card-body">
                  <div className="card-title border-bottom pb-2">Quick Actions</div>
                  <button disabled={true} className="btn btn-outline-secondary rounded text-dark d-inline mr-3 mt-3" >My Bookmarks</button>
                  <button disabled={true} className="btn btn-outline-secondary rounded text-dark d-inline mr-3 mt-3" >Inbox</button>
                  <button disabled={true} className="btn btn-outline-secondary rounded text-dark d-inline mr-3 mt-3" >Past Searches</button>
                   </div>
              </div> : ""}
   
   
        
        </div>
        );
      }
    }
    export default Widgets;
