import React, { Component } from 'react';
import {
  Button,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { TreeSelect } from 'antd';

import book from '../../images/book.JPG';
import '../../App.css';
import * as apiConstants from '../../service-api-constants.js';

import axios from 'axios';

import Loader from 'react-loader';
//import Loader from '../../components/loader/loader'

import ExtractedText from '../extracted-text/extracted-text.js'
import { Resizable, ResizableBox } from 'react-resizable';

import Frame from 'react-frame-component';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from 'react-select-nested';
import SelectEditable from '../select-editable/select-editable.js'
import _ from 'lodash'
import { ListBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import AddTag from '../add-tag/add-tag.js';


class AdminUpload extends Component {

  constructor(props) {
    super(props);
    this.listviewInstance = null;
    this.fields = { text: 'Name' };
    this.toolbarSettings = { items: ['moveUp', 'moveDown', 'moveTo', 'moveFrom', 'moveAllTo', 'moveAllFrom'] };
    this.state = {
      sending: false,
      toasts: [],
      fileName: '',
      progress: null,
      uploadProgress: undefined,
      fileSize: 0,
      buttonLabel: "CREATE KNOWLEDGE BASE",
      showKnowledgeBase: true,
      createKnowledgeBase: false,
      value: '',
      suggestions: [],
      knowledgeBaseName: '',
      knowledgeBaseNames: [],
      searchedItems: [],
      languages: [],
      showCheckbox: '',
      itmsChecked: [],
      selectAll: false,
      indexCreatedSuccess: null,
      indexCreationError: null,
      indexDeleteSuccess: null,
      indexDeleteError: null,
      indexDeleteSuccessMsg: '',
      indexDeleteErrorMsg: '',
      mimeType: '',
      bulkMimeType: '',
      editing: false,
      documentName: '',
      documentNameSuccess: '',
      knowledgeType: '',
      bulkKnowledgeType: '',
      docUrl: '',
      bulkDocUrl: '',
      zipFile: null,
      file: null,
      zipFileName: null,
      fileName: null,
      zipFileSize: null,
      fileSize: null,
      extractedText: null,
      loaded: true,
      showExtractedText: false,
      loadMore: false, error: '',
      listKbDocs: false,
      listKbDocsData: [],
      showExtractedDoc: false,
      error: '',
      editDocIndexSuccess: '',
      fileUrl: "",
      zipFileUrl: "",
      matched: true,
      titles: [],
      selectedTitle: '',
      titleDeleteSuccess: '',
      titleDeleteError: '',
      dataA: [
        { "text": "General", "field1": "ORG", "field2": "Author", "field3": "GEO" },
      ],
      dataB: [
        { "text": "Pharma", "field1": "Chemical", "field2": "Disease" },
      ],
      progressStatus: {},
      currentTaskId: '',
      ingestingKb: '',


      uploadType: 'url',
      bulkUploadType: 'url',
      inProgress: false,
      toasts: [], autohide: false,
      fileUrlError: false, docUrlError: false, bulkDocUrlError: false, zipFileUrlError: false,
      showMimeSelect: false,
      customTags: [],
      mockData: [],
      targetKeys: [],
      htmlData:''

    }
  }

  componentDidMount() {
    //knowledgeBaseNames.map((name, index) => {
    //this.setState({searchedItems: this.state.knowledgeBaseNames,[index]:})
    //});

    this.getIndex();
    //this.getListPacks();
    this.setState({
      buttonLabel: "CREATE KNOWLEDGE BASE",
      showKnowledgeBase: true,
      createKnowledgeBase: false, editing: false, matched: true, indexCreatedSuccess: null, indexCreationError: null, showExtractedText: false, listKbDocs: false,
      listKbDocsData: [], indexDeleteSuccess: null,
      indexDeleteError: null,
      indexDeleteSuccessMsg: '',
      indexDeleteErrorMsg: '',
      showExtractedDoc: false, extractedText: null, editDocIndexSuccess: '', titleDeleteSuccess: '',
      titleDeleteError: ''
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.showKnowledgeBase) {
      //this.getIndex();

    }

    if (this.props.ingestionKb !== nextProps.ingestionKb && this.props.ingestionKb.length > 0 && nextProps.currentTaskId.length === 0) {
      this.getTitles(this.props.ingestionKb);
    }

  }
  componentDidUpdate() {
    if (this.state.focusKnowledgeBase) {
      this.nameInput.focus();
    }
    if (this.props.stopIngestion) {
      this.stopIngestion();
    }

  }

  toastRetry = (text) => {
    this.addToast(text, 'ok', false);
  };
  addToast = (text, action, autohide = true) => {
    this.setState((state) => {
      const toasts = state.toasts.slice();
      toasts.push({ text, action });
      return { toasts, autohide };
    });
  };

  dismissToast = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  changeUploadType = (value, changeEvent) => {
    this.setState({ uploadType: value });
  }
  changeBulkUploadType = (value, changeEvent) => {
    this.setState({ bulkUploadType: value });
  }



  getIndex = () => {
    //this.setState({loaded:false});
    axios.get(apiConstants.GETKNOWLEDGEBASES,{
      params: {
        userName: this.props.userName
      }
      ,
      headers: {
        'Content-Type': "application/json",
        'x-api-key': this.props.apiKey
      }
    })
      .then((response) => {
        //handle success
        //console.log(response);
        const data = typeof response.data === "string" ? JSON.parse(response.data) : response.data;
       // const data = JSON.parse(response.data);
        Array.isArray(data) && data.length > 0 && data.map((item, index) => {
          item.checked = false;
        });
        //console.log("data", data);
        if (Array.isArray(data) && data.length > 0) {
          this.setState({ knowledgeBaseNames: data, searchedItems: data, loaded: true });
        } else {
          this.setState({ loaded: true, knowledgeBaseNames: [], searchedItems: [] });
        }

      })
      .catch((error) => {
        //console.log("error", error);
        this.setState({ error: error.toString(), loaded: true });
      });
  };

  getListPacks = () => {
    this.setState({ loaded: false });
    axios.get(apiConstants.GET_LIST_PACKS,
      
      {
        params: {
          userName: this.props.userName
        },
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.props.apiKey
        }
      })
      .then((response) => {
        //handle success
        //console.log(response);
        let pTags = Object.keys(response.data);

        let cTags = pTags.map((ptag, i) => {
          let children = Object.keys(response.data[ptag]).map((tag, ind) => {
            return {
              "key": `${ptag}:${tag}-${i.toString()}-${ind.toString()}`,
              "title": tag,
              "chosen": false,
              checkable: true
            }
          });
          return {
            "key": i.toString(),
            "title": ptag,
            "children": children,
            "chosen": false,
            disableCheckbox: true,
            checkable: false,
            disabled: true
          }
        });
        //console.log("cTags", cTags)
        this.setState({ loaded: true, mockData: cTags });
        /* const fil_title = Object.keys(response.data);
         const filters = fil_title.length>0 && fil_title.map((fil)=>{ 
           return {"name":fil,"value":Object.keys(response.data[fil].entities)};
         })
         let packs = filters.length>0 && filters.map((pack,index)=>{
           let value = {"text": pack.name,"field3":"","field4":""};
           pack.value.map((val,i)=>{
           value[`field${i+1}`] = val
           });
           delete value['json'];  
           return value           
          
         });
         console.log("filters keys",packs)
         this.setState({loaded:true,dataA:[packs[1]],dataB:[packs[0]]});  */
      })
      .catch((error) => {
        console.log("error", error);

        this.setState({ error: error.toString(), loaded: true });
      });
  };



  stopIngestion = () => {

    axios.post(apiConstants.STOP_INGESTION,
      { 'userName': this.props.userName, 'task_id': this.props.currentTaskId },
      {
        headers: {
          'Content-Type': "application/json",
          'x-api-key': this.props.apiKey
        }
      }
    ).then((response) => {
      //handle success
      //console.log(response);
      if (response.data.status === 'Stopped') {
        this.setState({ loaded: true, progressStatus: { "state": response.data.status, "percentage": "100" }, inProgress: false });

        this.props.setProgressStatus({ "state": response.data.status, "percentage": "100" }, false, "", "");
      } else {
        this.getTaskStatus(response.data[this.props.currentTaskId]);
      }
    }).catch((error) => {
      console.log(error);
      this.setState({ error: error.toString(), loaded: true, progressStatus: {} });
    });
  };

  progressTimeout = null;
  uploadProgressTimeout = null;

  fileUrlChange = (value) => {
    if (value.length > 0 && !this.isURL(value)) {
      this.setState({ fileUrl: value, fileUrlError: true })
    } else {
      this.setState({ fileUrl: value, fileUrlError: false })
    }

  };

  zipFileUrlChange = (value) => {
    if (value.length > 0 && !this.isURL(value)) {
      this.setState({ zipFileUrl: value, zipFileUrlError: true })
    } else {
      this.setState({ zipFileUrl: value, zipFileUrlError: false })
    }

  };



  /**
   * There is no native support for watching progress with fetch, so you can do it by getting the
   * reader from the response and looping over the results.
   */
  handleServerProgress = async (reader) => {
    const result = await reader.read();
    const chunk = result.value;

    if (result.done) {

      this.setState({ uploadProgress: 100 });
      this.uploadProgressTimeout = setTimeout(() => {
        this.uploadProgressTimeout = null;
        this.setState({ uploadProgress: undefined });
      }, 500);
      return null;
    }

    const bytes = chunk.byteLength;
    this.setState(({ uploadProgress, fileSize }) => ({
      uploadProgress: uploadProgress + ((bytes / fileSize) * bytes),
    }));

    return this.handleServerProgress(reader);
  };


  handleProgress = (file, progress) => {
    this.setState({ progress });
  };

  handleLoad = ({ name, size }, uploadedData) => {
    //console.log("uploadedData", uploadedData);
    this.progressTimeout = setTimeout(() => {
      this.progressTimeout = null;
      this.setState({ progress: null });
    }, 500);
    this.setState({ fileName: name, fileSize: size, uploadedData: uploadedData });
  };

  handleLoadStart = () => {
    this.setState({ progress: 0 });
  };

  handleBulkLoad = ({ name, size }, uploadedData) => {
    //console.log("uploadedData", uploadedData);
    this.progressTimeout = setTimeout(() => {
      this.progressTimeout = null;
      this.setState({ progress: null });
    }, 500);
    this.setState({ zipFileName: name, zipFileSize: size, uploadedData: uploadedData });
  };

  handleBulkLoadStart = () => {
    this.setState({ progress: 0 });
  };



  handleReset = () => {
    this.setState({ fileName: '' });
  };

  submitEditedForIndexing = () => {
    this.setState({ loaded: false, error: '', editDocIndexSuccess: '', inProgress: true });
    if (this.state.toasts.length > 0) { this.dismissToast() }

    let editedContent = _.cloneDeep(this.state.extractedText);
    editedContent.document.title = this.state.documentName;
    if (editedContent.document.extra_pack && editedContent.document.extra_pack.contentTags) {
      let customTags = [...editedContent.document.extra_pack.contentTags, ...this.state.customTags]
      //console.log("customTags", customTags);
      editedContent.document.extra_pack.contentTags = customTags;
      //console.log("editedContent.document.contentTags", editedContent.document.extra_pack.contentTags);
    }
    axios.post(apiConstants.CREATEKBDOCSINDEXING,
      { knowledgeBaseName: this.state.knowledgeBaseName, content: editedContent },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.props.apiKey
        }
      }
    ).then((response) => {
      //handle success
      console.log(response);
      if (response.data.status) {
        if (response.data.status.result && response.data.status.result === "created") {
          this.toastRetry("Updated Knowledgebase index successfully");
          this.setState({
            loaded: false, itmsChecked: [], indexDeleteSuccess: null,
            showExtractedText: false,
            listKbDocs: false,
      listKbDocsData: [],
            indexDeleteError: null,
            indexDeleteSuccessMsg: '',
            indexDeleteErrorMsg: '',
            editDocIndexSuccess: "Updated Knowledgebase index successfully", /*loaded: true,*/ inProgress: false
          })
          let kbname = this.state.knowledgeBaseName;
          setTimeout(()=>{
            this.getTitles(kbname,editedContent.document.title);
          this.getListPacks();            
          },1000)
          
         // this.setState({ editDocIndexSuccess: "Updated Knowledgebase index successfully", /*loaded: true,*/ inProgress: false });
        } else {
          this.toastRetry("Error in updating Knowledgebase index");
          this.setState({ editDocIndexSuccess: "Error in updating Knowledgebase index", loaded: true, inProgress: false });
        }
      } else {
        this.toastRetry("Error in updating Knowledgebase index");
        this.setState({ editDocIndexSuccess: "Error in updating Knowledgebase index", loaded: true, inProgress: false });
      }
      window.scrollTo(0, 0);
    })
      .catch((error) => {
        //console.log("error", error.toString());
        this.toastRetry(error.toString());
        this.setState({ loaded: true, inProgress: false });
        window.scrollTo(0, 0);
      });
  }

  createKnowledgebase = (event) => {
    this.setState({ titles: [] });
    if (event.target.innerText === "SAVE" && this.nameInput.value.length > 0) {
      if (this.state.toasts.length > 0) { this.dismissToast() }
      const newValue = this.state.knowledgeBaseName;
      this.setState({ loaded: false, error: '' });


      axios.post(apiConstants.CREATEKNOWLEDGEBASE,
        { knowledgeBaseName: this.state.knowledgeBaseName, userName: this.props.userName },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': this.props.apiKey
          }
        }
      ).then((response) => {
        //handle success
        console.log(response);
        if (response.data.message) {
          let msg = response.data.message;
          let msg1 = msg.split(' ')
          //console.log("msg1",msg1)
          let msg2 = msg1[1].replace("[", "").split('/');
          //console.log("msg2",msg2)
          msg1[1] = msg2[0];
          let msg3 = msg1.join();
          let finalMsg = msg3.split(',').join(" ")
          //console.log("final",finalMsg)
          this.toastRetry(finalMsg);

        }
        if (response.data.status) {

          this.setState({ indexCreatedSuccess: response.data.status, loaded: true, editing: true });
          this.getIndex();
          this.getListPacks();
          this.props.updateKB();
        } else {
          this.setState({ indexCreatedSuccess: response.data.status, loaded: true, editing: false });
        }



      })
        .catch((error) => {
          console.log("error", error.toString());
          this.toastRetry(error.toString());
          this.setState({ indexCreationError: error.toString(), editing: false, loaded: true });
        });

    } else {
      //console.log("event", event);
      this.setState({
        buttonLabel: "SAVE", createKnowledgeBase: true, listKbDocs: false,
        listKbDocsData: [], showKnowledgeBase: false, focusKnowledgeBase: true, knowledgeBaseName: '', indexDeleteSuccess: null,
        indexDeleteError: null,
        indexDeleteSuccessMsg: '',
        indexDeleteErrorMsg: ''
      });
    }

  };

  filterList = (value) => {
    if (this.state.searchedItems.length > 0) {
      var updatedList = this.state.knowledgeBaseNames;
      updatedList = updatedList.filter(function (item) {
        return item.name.toLowerCase().search(
          value.toLowerCase()) !== -1;
      });
      this.setState({ searchedItems: updatedList })
    }
    else {
      this.setState({ searchedItems: this.state.knowledgeBaseNames })
    }
  }

  onChange = (event) => {
    var patt = /^([a-z]+\s*$)*[0-9a-z]+$/;
    var test = patt.test(event.target.value);
    var pass = event.target.value.split(" ").length > 0;
    //console.log("test", test);
    this.setState({
      value: event.target.value,
      knowledgeBaseName: event.target.value,
      matched: event.target.value.length > 0 ? test : true
    });
  };

  onSearchHover = (event) => {
    event.preventDefault();
    // console.log("this.refs.check_me.checked", this.checkInput.checked);
    this.setState({ showCheckbox: event.target.innerText });
  };
  onSearchNotHover = (event) => {
    event.preventDefault();
    this.setState({ showCheckbox: null });
  };


  onBlur = (event) => { this.setState({ focusKnowledgeBase: false }) }

  onSuggestionsFetchRequested = ({ value }) => {
    //console.log("onSuggestionsFetchRequested", value);
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  checkboxChange = (e) => {
    let x = e.target.id.split('c').join("");
    let itmsChecked = [...this.state.itmsChecked]
    let searchedItems= [...this.state.searchedItems];
    let knowledgeBaseNames = [...this.state.knowledgeBaseNames];


    let checkedItem =  [...this.state.itmsChecked];
    //let allitems = this.state.searchedItems;
   /* if (e.target.checked) {
      checkedItem.push(e.target.name);
    }*/
    /* else{
      this.state.itmsChecked.pop();
    }*/
   /* searchedItems.length > 0 && searchedItems.map((item, index) => {
      if (item.checked) {
        item.checked = false;
        //itemschecked.push(item.name)
      }
    });*/
   // this.state.searchedItems[x].checked = e.target.checked;
    //this.state.knowledgeBaseNames[x].checked = e.target.checked;
   // console.log("this.state.itmsChecked", this.state.itmsChecked);


    //multiple delete
    if(e.target.checked){
      //checkedItem.push(e.target.name);
      if( itmsChecked.length > 0){
        itmsChecked.map((item,index)=>{
         if(item.name !== e.target.name){
          itmsChecked.push(e.target.name);
         }
         
       });
      }else{
       itmsChecked.push(e.target.name);
      }
      checkedItem = itmsChecked;
             
     }else{
      
       let filtered = itmsChecked.filter(function(item, index, arr){

         return item !== e.target.name;
     
     });
     //console.log("filtered",filtered);
     checkedItem = filtered;
     }
  
     searchedItems[x].checked = e.target.checked;
     knowledgeBaseNames[x].checked = e.target.checked;
     //console.log("itmsChecked",itmsChecked);
     //console.log("checkedItem",checkedItem)
    this.setState({
      searchedItems: searchedItems,
      knowledgeBaseNames: knowledgeBaseNames,
      itmsChecked: checkedItem,
      indexDeleteSuccess: null,
      indexDeleteError: null,
      indexDeleteSuccessMsg: '',
      indexDeleteErrorMsg: ''
    });
  };

  allCheckboxChange = (e) => {
    var allitems = this.state.searchedItems;
    var itemschecked = [];
    if (e.target.checked) {

      allitems.map((item, index) => {
        if (!item.checked) {
          item.checked = true;
          itemschecked.push(item.name)
        }
      });
      //console.log("setAllChecked", allitems);
      this.setState({ searchedItems: allitems, knowledgeBaseNames: allitems, selectAll: e.target.checked, itmsChecked: itemschecked })
    } else {
      allitems.map((item, index) => {
        if (item.checked) {
          item.checked = false;
        }
      });
      //console.log("setAllChecked", allitems);
      this.setState({ selectAll: e.target.checked, searchedItems: allitems, knowledgeBaseNames: allitems, itmsChecked: itemschecked })
    }

  };

  deleteSelectedItems = () => {
    let itemToDelete =  Array.from(new Set([...this.state.itmsChecked]));//[...this.state.itmsChecked];//this.state.itmsChecked.join()//for multiple delete;

    /*var filtered = itms.filter(function(item, index, arr){

        return item.checked == true;
    
    });
    console.log("filtered",filtered);*/
    this.setState({ loaded: false });
    if (this.state.toasts.length > 0) { this.dismissToast() }

    axios.post(apiConstants.DELETEKNOWLEDGEBASE,
      { knowledgeBaseName: itemToDelete, userName: this.props.userName },
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.props.apiKey
        }
      }
    ).then((response) => {
      //handle success
      //console.log(response);
      if (response.data.message) { this.toastRetry(response.data.message); }
      if (response.data.status) {
        this.setState({ indexDeleteSuccess: response.data.status, loaded: true, indexDeleteSuccessMsg: response.data.message, editing: false, itmsChecked: [] });
        this.getIndex();
      } else {
        this.setState({ indexDeleteSuccess: response.data.status, loaded: true, indexDeleteErrorMsg: response.data.message, editing: false, itmsChecked: [] });
      }
      this.props.updateKB();
    })
      .catch((error) => {
        console.log("error", error.toString());
        this.toastRetry(error.toString())
        this.setState({ indexCreationError: error.toString(), editing: false, loaded: true, itmsChecked: [] });
      });
    // this.getIndex();
    // this.setState({searchedItems:filtered,knowledgeBaseNames:filtered,itmsChecked:[]});
  };
  cancelClick = () => {
    let allitems = this.state.searchedItems;
    allitems.map((item, index) => {
      if (item.checked) {
        item.checked = false;
      }
    });
    this.setState({ itmsChecked: [], searchedItems: allitems, knowledgeBaseNames: allitems });
  };

  editItem = (event) => {
    this.setState({
      loaded: false, itmsChecked: [], indexDeleteSuccess: null,
      indexDeleteError: null,
      indexDeleteSuccessMsg: '',
      indexDeleteErrorMsg: ''
    })
    var kbname = event.target.innerText;
    this.getTitles(kbname);
    this.getListPacks();
  };

  getTitles = (kbname,selTitle) => {
    const titleFormData = new FormData();
    titleFormData.set('knowledgeBaseName', kbname);
    titleFormData.set('isFAQ', 'false');
    axios.post(apiConstants.GET_TITLES,
      titleFormData,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.props.apiKey

        }
      }
    ).then((response) => {
      //console.log("GET_TITLE respo", response.data);
      response.data.titles = {...response.data};
      if (response.data.titles !== '<html><head><title>Processing Failed</title></head><body><b>Processing Failed</b></body></html>') {
        const titles = Object.keys(response.data.titles);
        //console.log("titles", titles)
        if (titles.length > 0) {
          this.setState({ titles: titles, selectedTitle: selTitle ? selTitle : titles.length > 0 ? titles[0] : '', buttonLabel: "SAVE", loaded: true, createKnowledgeBase: true, showKnowledgeBase: false, knowledgeBaseName: kbname, error: '' });
          this.getDocsByTitle(selTitle ? selTitle : titles[0], kbname);
        } else {
          this.setState({ titles: [], selectedTitle: '', buttonLabel: "SAVE", loaded: true, editing: true, extractedText: null, listKbDocs: false, listKbDocsData: [], createKnowledgeBase: true, showKnowledgeBase: false, knowledgeBaseName: kbname, error: '' });
        }
      } else {
        this.setState({ titles: [], selectedTitle: '', buttonLabel: "SAVE", loaded: true, editing: true, extractedText: null, listKbDocs: false, listKbDocsData: [], createKnowledgeBase: true, showKnowledgeBase: false, knowledgeBaseName: kbname, error: response.data.titles });
      }
      //this.setState({buttonLabel:"SAVE",loaded:true, extractedText: null,listKbDocs:true, listKbDocsData: response.data,createKnowledgeBase: true, showKnowledgeBase: false, knowledgeBaseName:kbName,editing: true,error:''});
    }).catch((error) => {
      this.setState({ error: error.toString(), loaded: true });
    });
  }

  getDocsByTitle = (title, kbName) => {
    this.setState({ extractedText: null, listKbDocs: true, listKbDocsData: [], loaded: false })
    const titleFormData = new FormData();
    titleFormData.set('knowledgeBaseName', kbName);
    titleFormData.set('title', title);
    axios.post(apiConstants.GETDOCSBYTITLES,
      titleFormData,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.props.apiKey

        }
      }
    ).then((response) => {
      response.data.docs = {...response.data};
      if (response.data.docs !== '<html><head><title>Processing Failed</title></head><body><b>Processing Failed</b></body></html>') {
        this.setState({ buttonLabel: "SAVE", loaded: true, extractedText: null, listKbDocs: true, listKbDocsData: response.data.docs, createKnowledgeBase: true, showKnowledgeBase: false, knowledgeBaseName: kbName, editing: true, error: '' });
      } else {
        this.setState({ error: response.data.docs, loaded: true, editing: true, buttonLabel: "SAVE", createKnowledgeBase: true })
      }
    }).catch((error) => {
      this.toastRetry(error.toString());
      this.setState({ loaded: true });
    });
  };

  deleteTitle = () => {
    this.setState({
      loaded: false, titleDeleteSuccess: '',
      titleDeleteError: ''
    });
    
    if (this.state.toasts.length > 0) { this.dismissToast() }
    const title = _.cloneDeep(this.state.selectedTitle)
    let titleFormData = new FormData();
    titleFormData.set('knowledgeBaseName', this.state.knowledgeBaseName);
    titleFormData.set('title', title);
    if (title.length > 0) {
      axios.post(apiConstants.DELETE_TITLES,
        titleFormData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': this.props.apiKey

          }
        }
      ).then((response) => {
        //console.log("res", response.data);
        if (response.data.status && response.data.status.status && response.data.status.status === true) {
          this.toastRetry(`Title '${title}' deleted successfully.`);
          this.setState({ loaded: true, titleDeleteSuccess: `Title '${title}' deleted successfully.` });
          this.getTitles(this.state.knowledgeBaseName)
        } else {
          this.toastRetry(`Error in deleting '${title}' title`);
          this.setState({ loaded: true, titleDeleteError: `Error in deleting '${title}' title` });
        }

        //this.setState({buttonLabel:"SAVE",loaded:true, extractedText: null,listKbDocs:true, listKbDocsData: response.data,createKnowledgeBase: true, showKnowledgeBase: false, knowledgeBaseName:kbName,editing: true,error:''});
      }).catch((error) => {
        this.setState({ error: error.toString(), loaded: true });
      });
    }
  };

  titleChange = (e, value = undefined) => {
    //console.log("titleChange", e)
    if (value !== undefined && value !== null) {
      if (value.length > 0) {
        if (this.state.titles.length > 0) {
          const sug = this.state.titles.filter((title, i) => {
            return title === value
          })
          if (sug.length > 0) {
            this.setState({ selectedTitle: value });
            this.getDocsByTitle(value, this.state.knowledgeBaseName);
          } else {
            this.setState({ selectedTitle: value });
          }
        }

      } else {
        this.setState({ selectedTitle: value });
      }
    } else {
      this.setState({ selectedTitle: e && e.target && e.target.value !== undefined ? e.target.value : this.state.titles[0] });
    }

    //   this.textInput.current.focus();     
  };

  titleKeyPress = (e) => {
    let title = this.state.titles.filter((title, i) => {
      return title === e.target.value
    })
    if (title.length > 0) {
      this.setState({ selectedTitle: e.target.value });
    }
  }

  filterTitles = () => {
    if (this.state.selectedTitle.length > 0) {
      this.getDocsByTitle(this.state.selectedTitle, this.state.knowledgeBaseName);
    }
  }

  mimeSelect = (value) => {
    //console.log("mime value", value.val);

    this.setState({ mimeType: value.parentVal !== undefined ? value.val : "", uploadType: value.parentVal !== undefined && value.val === 'html' ? 'url' : 'upload', fileUrl: '', docUrl: '', file: null, fileName: '', docUrlError: false, fileUrlError: false })

  };

  bulkMimeSelect = (value) => {
    //console.log("bulk mime value", value.val);

    this.setState({ bulkMimeType: value.parentVal !== undefined ? value.val : "", bulkUploadType: value.parentVal !== undefined && value.val === 'html' ? 'url' : 'upload', bulkDocUrlError: false, zipFileUrlError: false, zipFile: null, zipFileName: '', zipFileUrl: '', bulkDocUrl: '' })

  };



  createKnowledgeDoc = () => {
   
    this.setState({
      loaded: false, error: '', showExtractedText: false, titleDeleteSuccess: '',
      titleDeleteError: '', inProgress: true, indexCreatedSuccess: false, htmlData: ''
    });

    if (this.state.toasts.length > 0) { this.dismissToast() }

    var tKeys = this.state.targetKeys;
    let finalObj = {};
    tKeys.forEach((key, i) => {

      let x = key.split("-");
      //console.log("x", x);
      let y = x[0].split(":");
      //console.log("y", y);
      if (finalObj[y[0]]) {
        finalObj[y[0]].push(y[1])
      } else {
        finalObj[y[0]] = [];
        finalObj[y[0]].push(y[1])
      }
    });
    //console.log("finalObj", finalObj)

    const bodyFormData = new FormData();
    bodyFormData.set('title', this.state.documentName);
    bodyFormData.set('customExtractionEndpoint', this.props.customExtractionUrl);
    bodyFormData.set('splitContent', this.props.documentIngestion === "sections" ? true : false);
    bodyFormData.set('knowledgeBaseName', this.state.knowledgeBaseName);
    bodyFormData.set('url', this.state.mimeType === 'html' || this.props.customExtractionUrl.length > 0 ? this.state.docUrl.trim() : this.state.fileUrl.length > 0 ? this.state.fileUrl.trim() : 'None');
    bodyFormData.set('type', this.props.customExtractionUrl.length > 0 ? 'custom' : this.state.mimeType);
    bodyFormData.set('knowledgeType', this.state.knowledgeType === 'Knowledge Base Article' ? 'raw' : this.state.knowledgeType.toLowerCase());
    bodyFormData.set('file', ((this.state.mimeType === 'pptx' || this.state.mimeType === 'docx' || this.state.mimeType === 'pdf' || this.state.mimeType === 'scanned pdf' || this.state.mimeType === 'csv' || this.state.mimeType === 'txt' || this.state.mimeType === 'jpg' || this.state.mimeType === 'png' || this.state.mimeType === 'html' || this.state.mimeType === 'dita' || this.props.customExtractionUrl.length > 0)) ? this.state.file !== null ? this.state.file : 'None' : 'None');
    if (Object.keys(finalObj).length > 0) {
      Object.keys(finalObj).forEach((obj) => {
        bodyFormData.set(obj, finalObj[obj].join());
      })
    }

    //console.log("bodyFormData", bodyFormData)
    axios.post(apiConstants.CREATEKNOWLEDGEBASEDOCS,      
      bodyFormData,
      {timeout: 1000 * 60},
      {
        headers: {
          'Content-Type': "multipart/form-data",
          'x-api-key': this.props.apiKey
        },        
      }
    ).then((response) => {
      //handle success
      //console.log(response);
      if(response.data["Task id"]){
        this.getExtractTaskStatus(response.data["Task id"]);
      }else{
        this.setState({loaded: true});
        this.toastRetry("Error in ingesting document");
      }
      /*if (response.data.error) {
        this.toastRetry(response.data.error);
        this.setState({ loaded: true, inProgress: false, editDocIndexSuccess: '' });
      } else {
        if (response.data.document) {
          this.setState({ documentNameSuccess: this.state.documentName, extractedText: response.data, loaded: true, editDocIndexSuccess: '', showExtractedText: true, showExtractedDoc: false, inProgress: false });
          if (this.state.mimeType === 'html' && response.data.document.s3_content_url.length > 0) {

            let url = response.data.document.s3_content_url;
            let urlArray = url.split("/");
            //console.log("urlArray", urlArray);
            let bucket = urlArray[2].split(".s3")[0];
            //console.log("bucket", bucket);
            let key = `${urlArray[3]}/${urlArray[4]}`;
            //console.log("key", key);

            axios.post(apiConstants.GETS3HTMLDATA,
              { "bucket": bucket, "key": key },
              {
                headers: {
                  'Content-Type': "application/json",
                  'x-api-key': this.props.apiKey
                }
              }
            ).then((response) => {
              this.setState({ htmlData: response.data.data ? response.data.data.toString('utf-8') : "" })
            }).catch((error) => {
              console.log(error);
              this.setState({ error: error.toString(), loaded: true });
            });

          }
        } else {
          this.toastRetry("Error in extracting the document");
          this.setState({ loaded: true, inProgress: false, editDocIndexSuccess: '', extractedText: null });
        }

        //this.viewExtractedText();
      }*/
    }).catch((error) => {
      console.log(error);
      if (error.response) {       
        //console.log(error.response.status);
        if(error.response.status === 504){
          this.toastRetry("Error in extracting document");
        }else{
          this.toastRetry(error.toString());
        }       
      }else{
        this.toastRetry(error.toString());        
      }
      this.setState({/* error: error.toString(),*/ loaded: true, editDocIndexSuccess: '', inProgress: false });
    });

    /* fetch(apiConstants.CREATEKNOWLEDGEBASEDOCS, {
   method: 'POST',
   headers: {
       'Content-Type': 'multipart/form-data'
     },
   body: formData
 })
 .then(r => r.json())
 .then(data => {
   console.log("fetch data",data)
 })*/

  };

  getExtractTaskStatus = (task_id) => {
    
    axios.get(apiConstants.EXTRACT_TASK_STATUS(task_id),
      {
        headers: {
          'Content-Type': "application/json",
          'x-api-key': this.state.apiKey
        }
      },
    ).then((response) => {
      const {status,result} = response.data;
      if (status !== 'SUCCESS' && status !== 'FAILURE' && (status === 'PENDING' || status === 'PROGRESS' )){


       
        setTimeout(() => {         
            this.getExtractTaskStatus(task_id);          
        }, 5000);
      } else {
        if (status === 'FAILURE') {
          this.toastRetry(result.error ? result.error : "Sorry, but the document ingested is too big for us to extract. Can you kindly try with a smaller document again.");
          this.setState({ loaded: true, inProgress: false, editDocIndexSuccess: '' });
        } else {
          if (result.document) {
            this.setState({ documentNameSuccess: this.state.documentName, extractedText: result, loaded: true, editDocIndexSuccess: '', showExtractedText: true, showExtractedDoc: false, inProgress: false });
            if (this.state.mimeType === 'html' && result.document.s3_content_url.length > 0) {
  
              let url = result.document.s3_content_url;
              let urlArray = url.split("/");
              //console.log("urlArray", urlArray);
              let bucket = urlArray[2].split(".s3")[0];
              //console.log("bucket", bucket);
              let key = `${urlArray[3]}/${urlArray[4]}`;
              //console.log("key", key);
  
              axios.post(apiConstants.GETS3HTMLDATA,
                { "bucket": bucket, "key": key },
                {
                  headers: {
                    'Content-Type': "application/json",
                    'x-api-key': this.props.apiKey
                  }
                }
              ).then((response) => {
                this.setState({ htmlData: response.data.data ? response.data.data.toString('utf-8') : "" })
              }).catch((error) => {
                console.log(error);
                this.setState({ error: error.toString(), loaded: true });
              });
  
            }
          } else {
            this.toastRetry("Error in extracting the document");
            this.setState({ loaded: true, inProgress: false, editDocIndexSuccess: '', extractedText: null });
          }
  
          //this.viewExtractedText();
        }
        }

    }).catch((error) => {
      console.log(error);
      this.setProgressStatus({}, false, '', '');
      this.setState({ error: error.toString(), loaded: true });


    });
  };

  discardExtraction = (s3Url) => {
    let url = s3Url;
            let urlArray = url.split("/");
            //console.log("urlArray", urlArray);
            let bucket = urlArray[2].split(".s3")[0];
            //console.log("bucket", bucket);
            let key = `${urlArray[3]}/${urlArray[4]}`;
            //console.log("key", key);

            axios.post(apiConstants.DELETES3HTMLDATA,
              { "bucket": bucket, "key": key },
              {
                headers: {
                  'Content-Type': "application/json",
                  'x-api-key': this.props.apiKey
                }
              }
            ).then((response) => {
              if(response.data && response.data.status === "True"){
                this.toastRetry("File successfully discarded");
              }else{
                this.toastRetry("Error in discarding files.");
              }
              
             this.setState({
                loaded: true, itmsChecked: [], indexDeleteSuccess: null,
                createKnowledgeBase:true,
                editing:true,
                showExtractedText: false,
                listKbDocs: false,
          listKbDocsData: [],
                indexDeleteError: null,
                indexDeleteSuccessMsg: '',
                indexDeleteErrorMsg: '',
                documentNameSuccess: "", extractedText: null, editDocIndexSuccess: '', showExtractedDoc: false, inProgress: false 
              })
              this.getTitles(this.state.knowledgeBaseName)
            }).catch((error) => {
              console.log(error);
              this.setState({ error: error.toString(), loaded: true });
            });
  }

  bulkIngestion = () => {
    //console.log("this.state.zipFile", this.state.zipFile);
    var file = JSON.stringify(this.state.zipFile);
    if (this.state.toasts.length > 0) { this.dismissToast() }

    this.setState({
      loaded: false, error: '', showExtractedText: false, titleDeleteSuccess: '',
      titleDeleteError: '', indexCreatedSuccess: false
    });

    var tKeys = this.state.targetKeys;
    let finalObj = {};
    tKeys.forEach((key, i) => {

      let x = key.split("-");
      //console.log("x", x);
      let y = x[0].split(":");
      //console.log("y", y);
      if (finalObj[y[0]]) {
        finalObj[y[0]].push(y[1])
      } else {
        finalObj[y[0]] = [];
        finalObj[y[0]].push(y[1])
      }
    });
    //console.log("finalObj", finalObj)

    axios.get(apiConstants.GET_ACTIVE_TASKS(this.props.userName),
      {
        headers: {
          'Content-Type': "application/json",
          'x-api-key': this.props.apiKey
        }
      },
    ).then((response) => {
      //console.log("response.data", response.data);
      if (response.data.activeTasks && response.data.activeTasks.length > 0) {
        this.setState({ loaded: true });
        this.toastRetry(apiConstants.IN_PROGRESS_MSG);
      } else {
        this.props.setProgressStatus({ "state": "LOADING", "current": 0, "total": 0, "percentage": 0, "success": 0, "errors": 0, "error_files": [], "status": "Loading" }, false, "", this.state.knowledgeBaseName);


        const bodyFormData = new FormData();
        bodyFormData.set('knowledgeBaseName', this.state.knowledgeBaseName);
        bodyFormData.set('splitText', this.props.documentIngestion === "sections" ? true : false);
        bodyFormData.set('customExtractionEndpoint', this.props.customExtractionUrl);
        bodyFormData.set('mimeType', this.props.customExtractionUrl.length > 0 ? 'custom' : this.state.bulkMimeType);
        bodyFormData.set('knowledgeType', this.state.bulkKnowledgeType === 'Knowledge Base Article' ? 'raw' : this.state.bulkKnowledgeType.toLowerCase());
        bodyFormData.set('userName', this.props.userName);
        bodyFormData.set('zipfile', ((this.state.bulkMimeType === 'pdf' || this.state.bulkMimeType === 'pptx' || this.state.bulkMimeType === 'docx' || this.state.bulkMimeType === 'scanned pdf' || this.state.bulkMimeType === 'csv' || this.state.bulkMimeType === 'txt' || this.state.bulkMimeType === 'jpg' || this.state.bulkMimeType === 'png' || this.state.bulkMimeType === 'json' || this.state.bulkMimeType === 'html' || this.props.customExtractionUrl.length > 0)) ? this.state.zipFile !== null ? this.state.zipFile : 'None' : 'None');
        if (Object.keys(finalObj).length > 0) {
          Object.keys(finalObj).forEach((obj) => {
            bodyFormData.set(obj, finalObj[obj].join());
          })
        }

        //console.log("bodyFormData", bodyFormData)
        axios.post(apiConstants.BULK_INGESTION,
          bodyFormData,
          {
            headers: {
              'Content-Type': "multipart/form-data",
              'x-api-key': this.props.apiKey
            }
          }
        ).then((response) => {
          //handle success
          //console.log(response);
          if (response.data["Task id"]) {
            this.setState({ loaded: true });
            this.props.getTaskStatus(response.data["Task id"], this.state.knowledgeBaseName);
          } else {
            this.toastRetry(response.data);
            this.props.setProgressStatus({}, false, "", "");
            this.setState({ loaded: true, inProgress: false });
          }
        })
      }
    }).catch((error) => {
      console.log(error);
      this.toastRetry(error.toString());
      this.props.setProgressStatus({}, false, "", "");
      this.setState({ loaded: true, inProgress: false });
    });


  };



  fileChange = (files, e) => {
    //console.log("files", files);
    this.setState({ file: files });
  };

  zipFileChange = (files, e) => {
    //console.log("zip files", files);
    this.setState({ zipFile: files });
  };

  knowledgeTypeSelect = (value) => {
    this.setState({ knowledgeType: value, mimeType: '',targetKeys: [] })
  };

  bulkKnowledgeTypeSelect = (value) => {
    this.setState({ bulkKnowledgeType: value, bulkMimeType: '',targetKeys: [] })
  };

  docNameChange = (value) => {
    this.setState({ documentName: value })
  };


  isURL = (str) => {
    //var pattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str);
  }

  urlChange = (value) => {

    if (value.length > 0 && !this.isURL(value)) {
      this.setState({ docUrl: value, docUrlError: true, file: null, fileName: null })
    } else {
      this.setState({ docUrl: value, docUrlError: false, file: null, fileName: null })
    }
  }

  bulkUrlChange = (value) => {
    if (value.length > 0 && !this.isURL(value)) {
      this.setState({ bulkDocUrl: value, bulkDocUrlError: true, zipFile: null, zipFileName: null })
    } else {
      this.setState({ bulkDocUrl: value, bulkDocUrlError: false, zipFile: null, zipFileName: null })
    }
  }

  viewExtractedText = () => {
    if (this.state.extractedText != null) {
      this.setState({ showExtractedText: !this.state.showExtractedText, showExtractedDoc: false, mimeType: this.state.extractedText.document.mimeType });
    } else {
      this.setState({ showExtractedText: !this.state.showExtractedText, showExtractedDoc: false });

    }
  };
  viewExtractedDoc = () => {
    this.setState({
      showExtractedDoc: !this.state.showExtractedDoc, listKbDocs: !this.state.listKbDocs, createKnowledgeBase: false,
      showKnowledgeBase: true, editing: false, showExtractedText: false, buttonLabel: "CREATE KNOWLEDGE BASE", titleDeleteSuccess: '',
      titleDeleteError: ''
    });
  };
  newDocClick = () => {
    //console.log("file", this.state.file)
    this.setState({
      documentName: '',
      knowledgeType: '',
      docUrl: '',
      mimeType: '',
      file: null,
      showMimeSelect: false,
      uploadType: 'url',
      fileUrl:"",
      fileName: '', showExtractedText: false, fileUrlError: false, docUrlError: false, bulkDocUrlError: false, zipFileUrlError: false, targetKeys: []
    });
    setTimeout(() => this.setState({ showMimeSelect: true }), 1)
    // this.props.setProgressStatus({"state":'',"percentage":0},false,"","");
  };

  newBulkDocClick = () => {
    //console.log("zipfile", this.state.zipFile)
    this.setState({
      bulkKnowledgeType: '',
      bulkMimeType: '',
      bulkDocUrl: '',
      showMimeSelect: false,
      zipFile: null,
      bulkUploadType: 'upload',
      zipFileName: '', showExtractedText: false, fileUrlError: false, docUrlError: false, bulkDocUrlError: false, zipFileUrlError: false, targetKeys: []
    });
    setTimeout(() => this.setState({ showMimeSelect: true }), 1)
    // this.props.setProgressStatus({"state":'',"percentage":0},false,"","");
  };

  handleLoadStart = () => {
    this.setState({ progress: 0 });
  };
  createMarkup = () => {
    return { __html: this.state.htmlData };
  }

  setTags = (tag) => {
    this.setState({ customTags: tag });
    /* this.setState((prevState, props) => {
       prevState.extractedText.document.contentTags = tag;
       return {extractedText: prevState.extractedText};
     })*/
  }
  setAutoTags = (tags) => {
    //let tag = _.cloneDeep(this.state.extractedText.document.contentTags);
    this.state.extractedText.document.extra_pack.contentTags = tags;
    this.setState({ customTags: this.state.extractedText });
    /* this.setState((prevState, props) => {
       prevState.extractedText.document.contentTags = tag;
       return {extractedText: prevState.extractedText};
     })*/
  }



  onPackChange = value => {
    //console.log('onChange ', value);

    let tKeys = value;//this.state.targetKeys;
    let finalKeys = [];
    let finalObj = {};
    tKeys.forEach((key, i) => {
      if(typeof key === 'string'){
        let x = key.split("-");
        //console.log("x", x);
        let y = x[0].split(":");
        //console.log("y", y);
        if (finalObj[y[0]]) {
          finalObj[y[0]].push(y[1])
        } else {
          finalObj[y[0]] = [];
          finalObj[y[0]].push(y[1])
        }
        
      } else{
        
        key.children.forEach((c, i) => {
          let x = c.key.split("-");
          //console.log("x", x);
          let y = x[0].split(":");
          //console.log("y", y);
          if (finalObj[y[0]]) {
            finalObj[y[0]].push(y[1])
          } else {
            finalObj[y[0]] = [];
            finalObj[y[0]].push(y[1])
          }
          finalKeys = [...finalKeys,c.key]
        });
      }
     
    });
    //console.log("finalObj", finalObj);

    this.setState({ targetKeys: finalKeys.length>0 ? finalKeys : value });
  };

  render() {

    const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Knowledge Base name',
      value,
      onChange: this.onChange,
      id: "placeholder-only-title",
      className: "w-100 input-focus md-text-field md-text-field--margin md-full-width md-text",
      onBlur: this.onBlur

    };

    const {
      toasts,
      fileName,
      progress,
      sending,
      uploadProgress,
      buttonLabel, showKnowledgeBase, createKnowledgeBase, showCheckbox, showTools
    } = this.state;

    let progressBar;
    if (typeof progress === 'number') {
      progressBar = (
        <span className="file-inputs__upload-form__progress">
          <LinearProgress id="file-upload-status" value={progress} />
        </span>
      );
    } else if (sending || typeof uploadProgress === 'number') {
      progressBar = (
        <span className="file-inputs__upload-form__progress">
          <LinearProgress id="file-upload-server-status" query value={uploadProgress} />
        </span>
      );
    }

    const kbNames = this.state.searchedItems.length > 0 && this.state.searchedItems.map((item, index) => {
      return (
        <li className={`list-group-item text-left border ${index > 9 ? this.state.loadMore ? `d-flex d-block` : `d-none` : "d-flex"} flex-row p-1 w-100 `} key={index} id={index} onMouseEnter={this.onSearchHover} onMouseLeave={this.onSearchNotHover}>

          {/*<Checkbox
                id={`c${index}`}
                name={item}
                label=""
                value={item}
                ref={(check) => { this.checkInput = check; }}
                onChange={(e) => {if(e){this.state.itmsChecked.push(item);} else {this.state.itmsChecked.pop()}  }}         
                className={`d-inline check-box ${showCheckbox == item || this.state.itmsChecked.length>0 ? "visible" : "invisible"}`}
              />
              <i className={`knowledge-color-mark ${showCheckbox != item && this.state.itmsChecked.length == 0  ? "visible" : "invisible"}`}></i>
                <a className="pb-1 d-inline-block search-items">{item}</a>*/}
          <i className={`knowledge-color-mark ${showCheckbox != item.name && this.state.itmsChecked.length == 0 ? "visible" : "invisible"}`}></i>
          <input className={`styled-checkbox ${showCheckbox == item.name || this.state.itmsChecked.length > 0 ? "visible" : "invisible"}`} style={{ "zIndex": "9999999999999" }} id={`c${index}`} name={item.name} value={item.name} type="checkbox" checked={item.checked} onClick={this.checkboxChange} />
          <label className={`${showCheckbox == item.name || this.state.itmsChecked.length > 0 ? "visible" : "invisible"}`} htmlFor="styled-checkbox-22" style={{ "zIndex": "555", "width": "auto" }}></label>
          <a className="text-left" onClick={this.editItem}>{item.name}</a>
          <i className={`fa fa-trash text-right float-right ml-auto pt-1 d-none ${showCheckbox == item.name || this.state.itmsChecked.length > 0 ? "visible" : "invisible"}`} /*onClick={()=> {this.state.searchedItems.splice(index,1);this.state.knowledgeBaseNames.splice(index,1);}}*/></i>
        </li>
      );
    });

    //console.log("final", this.state.knowledgeBaseNames)

    //var x = document.getElementById("snackbar");

    // Add the "show" class to DIV
    //if(x !== null){x.className = "show"};

    const fruit = [
      ...this.state.knowledgeType === 'FAQ' ? [] : [{
        val: 0,
        label: 'XML',
        items: [{ parentVal: 0, val: 'dita', label: 'dita' }]
      }], 
      ...this.state.knowledgeType === 'FAQ' ? [] : [{
        val: 1,
        label: 'Presentation',
        items: [{ parentVal: 1, val: 'pptx', label: 'pptx' }]
      }],
      {
        val: 2,
        label: 'Web',
        items: [{ parentVal: 2, val: 'html', label: 'html' }]
      },
      {
        val: 3,
        label: 'Documents',
        items: this.state.knowledgeType === 'FAQ' || this.state.bulkKnowledgeType === 'FAQ' ? [{ parentVal: 3, val: 'pdf', label: 'pdf' }, { parentVal: 3, val: 'csv', label: 'csv' }] : [{ parentVal: 3, val: 'docx', label: 'docx' }, { parentVal: 3, val: 'pdf', label: 'pdf' }, { parentVal: 3, val: 'txt', label: 'txt' }]
      },
      ...this.state.knowledgeType === 'FAQ' ? [] : [{
        val: 4,
        label: 'OCR',
        items: [{ parentVal: 4, val: 'scanned pdf', label: 'scanned pdf' }, { parentVal: 4, val: 'jpg', label: 'jpg' }, { parentVal: 4, val: 'png', label: 'png' }]
      }]
      /*,
      {
        val: 5,
        label: 'Spreadsheets'
      }*/

    ];

    return (

      <div
        className="file-inputs__upload-form form-table container-fluid pl-0 pr-0 kb-upload"
      >


        {progressBar}

        <div className="clearfix mt-3 border pt-3 pl-3 pr-3 pb-2 rounded">
          {showKnowledgeBase ? <div className="float-left"><h3 className="mb-0"><img src={book} className="mr-3" /> Knowledge Bases</h3></div> : ""}
          {createKnowledgeBase ? <div className="float-left  w-75">
            {/* <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps}
        ref={this.storeInputReference}
         />*/}

            <input type="text" placeholder='Knowledge Base name' disabled={this.state.listKbDocs} value={this.state.knowledgeBaseName} onChange={this.onChange} id="placeholder-only-title"
              className="w-100 input-focus md-text-field md-text-field--margin md-full-width md-text" onBlur={this.onBlur} ref={(input) => { this.nameInput = input; }} />
            {!this.state.matched ? <span className="text-danger">Knowledgebase name should not contain capital letters and special characters.</span> : ""}
          </div> : ""}
          <div className="float-right"><Button flat primary swapTheming disabled={this.state.extractedText != null || !this.state.matched || this.state.editing || (buttonLabel === 'SAVE' && this.state.knowledgeBaseName.length === 0)} className="md-primary text-white d-inline-block" onClick={this.createKnowledgebase}>{buttonLabel}</Button></div>
        </div>
        <div className="clearfix border border-top-0 pt-3 pl-3 pr-3 pb-3 text-center rounded shadow">

          {this.state.itmsChecked.length > 0 && this.state.searchedItems.length > 0 && buttonLabel === 'CREATE KNOWLEDGE BASE' ? <div className="clearfix mb-4">
            <div className="float-left w-75 pl-3 pt-2 align-middle"><input className={`styled-checkbox d-none`}
              style={{ "zIndex": "9999999999999" }} id="select-all" name="selectall" value="selectall" type="checkbox"
              checked={this.state.selectAll} onChange={this.allCheckboxChange} disabled={true} />
              <label htmlFor="select-all" style={{ "zIndex": "555" }}></label></div>
            {/*<Checkbox
                id="tools"
                name="listTools"
                label=""
                value="selectall"               
                onChange={(e) => e ? this.setState({selectAll:true}) : this.setState({selectAll:false})  }             
                className='d-inline check-box float-left'
       />*/}
            <div className="align-self-end float-right">
              <Button className="mr-2" onClick={this.deleteSelectedItems} raised >DELETE</Button>
              <Button onClick={this.cancelClick} raised>CANCEL</Button>
            </div>
          </div> : !this.state.createKnowledgeBase ? <TextField id=""
            placeholder="Search Knowledge Bases"
            type="text"
            rightIcon={<FontIcon iconClassName="fa fa-search"></FontIcon>}
            size={10}
            fullWidth={true}
            onChange={this.filterList}
            className="mb-3"
          /> : ""}

          <Loader loaded={this.state.loaded}></Loader>
          {this.state.error.length > 0 ? <div className="text-danger p-3">{this.state.error}</div> : ""}
          {/*this.state.titleDeleteSuccess.length>0 && <p className="text-success">{this.state.titleDeleteSuccess}</p>*/}
          {/*this.state.titleDeleteError.length>0 && <p className="text-danger">{this.state.titleDeleteError}</p>*/}
          {/*this.state.editDocIndexSuccess.length>0 && <p className="text-success">{this.state.editDocIndexSuccess}</p>*/}
          {/*this.state.indexDeleteSuccess != null && this.state.indexDeleteSuccessMsg.length > 0 ? <div className="text-success p-3">{this.state.indexDeleteSuccessMsg}</div> : ""*/}
          {/*this.state.indexDeleteSuccess != null && this.state.indexDeleteErrorMsg.length > 0 ? <div className="text-danger p-3">{this.state.indexDeleteErrorMsg}</div> : ""*/}
          {this.state.createKnowledgeBase || this.state.editing && this.state.loaded ? <div>
            {this.state.indexCreatedSuccess || this.state.editing && this.state.extractedText == null && this.state.loaded && this.state.titles.length === 0 ? <div>
              <p>No knowledge document has been created yet. <a className="text-primary" data-toggle={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? 'tooltip' : 'modal'}`} title={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? apiConstants.IN_PROGRESS_MSG : ''}`} onClick={this.newDocClick} data-target="#formModal">Create the first one.</a></p>
              <p>you can also ingest bulk documents. <a className="text-primary" data-toggle={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? 'tooltip' : 'modal'}`} title={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? apiConstants.IN_PROGRESS_MSG : ''}`} onClick={this.newBulkDocClick} data-target="#bulkFormModal">Create the bulk ingestion.</a></p>
              <p>Knowledge documents are articles or FAQs that the engine can use to generate responses to user queries.</p></div> : this.state.listKbDocsData.length == 0 ?
              <div>
                <p>After you name and save this knowledge base, you can add knowledge documents such as websites, FAQs or knowledge base articles.</p>
                {this.state.titles.length > 0 || this.state.extractedText !== null || !this.state.loaded ? "" : <div className="d-flex flex-row justify-content-center">



                  {/* <Transfer
        dataSource={this.state.mockData}
        listStyle={{
          width: 300,
          height: 300,
        }}
        targetKeys={this.state.targetKeys}
        onChange={this.handleChange}
        render={this.renderItem}
      />*/}
                  {/*<div className='control-pane'>
                <div className="control-section">
                    <div className="dual-list-wrapper d-flex flex-row">
                        <div className="dual-list-groupa w-50">
                            <h4 className="text-left pl-4">Available Entities</h4>
                            <ListBoxComponent dataSource={this.state.dataA} fields={this.fields}  scope="#combined-listbox" toolbarSettings={this.toolbarSettings}
                            itemTemplate='<div class="list-wrapper"><span class="text font-weight-bold">${text}</span><br/><span class="fields">${field1}</span><br/><span class="fields">${field2}</span><br/><span class="fields">${field3}</span></div>'
                            />
                        </div>
                        <div className="dual-list-groupb w-50">
                            <h4>Selected Entities</h4>
                            <ListBoxComponent id="combined-listbox" dataSource={this.state.dataB}  fields={this.fields}  ref={listview => {
            this.listviewInstance = listview;}}
                            itemTemplate='<div class="list-wrapper"><span class="text font-weight-bold">${text}</span><br/><span class="fields">${field1}</span><br/><span class="fields">${field2}</span><br/><span class="fields">${field3}</span></div>'
                            />
                        </div>
                    </div>
                </div>
            </div>*/}

                </div>}






              </div>
              : ""}

            {this.state.extractedText !== null ? <div>
              <div className="text-left"><a className="text-primary d-inline ml-3" data-toggle={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? 'tooltip' : 'modal'}`} data-target="#formModal" onClick={this.newDocClick} title={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? apiConstants.IN_PROGRESS_MSG : ''}`}>+ New Document</a></div>
              <div className="text-left"><a className="text-primary d-inline ml-3" data-toggle={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? 'tooltip' : 'modal'}`} onClick={this.newBulkDocClick} data-target="#bulkFormModal" title={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? apiConstants.IN_PROGRESS_MSG : ''}`}>+ New bulk document ingestion</a></div>
              <table className="table table-bordered text-left">
                <tbody>
                  <tr>
                    <th>Document Name</th>
                    <th>Knowledge Type</th>
                    <th>Mime Type</th>
                    {this.state.extractedText.document && this.state.extractedText.document.mimeType === 'html' ? <th>Source/path</th> : ""}
                  </tr>
                  <tr>
                    <td>{this.state.extractedText.document && this.state.extractedText.document.title !== null && this.state.extractedText.document.title.length > 0 ? this.state.extractedText.document.title : this.state.documentNameSuccess} (<a style={{ "cursor": "pointer" }} className="text-primary" onClick={this.viewExtractedText} primary>view detail</a>)</td>
                    <td>{this.state.extractedText.document && this.state.extractedText.document.knowledgeType.toUpperCase()}</td>
                    <td>{this.state.extractedText.document && this.state.extractedText.document.mimeType.toUpperCase()}</td>
                    {this.state.extractedText.document && this.state.extractedText.document.mimeType == 'html' ? <td><a href={this.state.extractedText.document.url} target="blank">{this.state.extractedText.document.url}</a></td> : ""}
                  </tr>
                </tbody>
              </table>

              {this.state.extractedText.document && this.state.extractedText.document.extraction_algo && this.state.extractedText.document.extraction_algo.length > 0 ? <><p className="d-block text-success font-weight-bold">Extraction done using {this.state.extractedText.document.extraction_algo} policy</p>
              {this.state.extractedText.document.mimeType === "html" && this.state.extractedText.document.knowledgeType === "faq" && <p className="d-block text-success font-weight-bold">If extraction not done, kindly try with different document type.</p>}
              </> : ""}

            </div> : ""}

            {this.state.listKbDocs && this.state.listKbDocsData.hits && this.state.listKbDocsData.hits.total && this.state.listKbDocsData.hits.total.value > 0 && this.state.extractedText == null ? <div>
              <div className="text-left"><a className="text-primary d-inline ml-3" data-toggle={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? 'tooltip' : 'modal'}`} title={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? apiConstants.IN_PROGRESS_MSG : ''}`} data-target="#formModal" onClick={this.newDocClick}>+ New Document</a></div>
              <div className="text-left"><a className="text-primary d-inline ml-3" data-toggle={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? 'tooltip' : 'modal'}`} title={`${this.state.inProgress || this.props.currentTaskId.length > 0 ? apiConstants.IN_PROGRESS_MSG : ''}`} onClick={this.newBulkDocClick} data-target="#bulkFormModal">+ New bulk document ingestion</a></div>
              {this.state.titles.length > 0 ? <div className="d-flex flex-row text-left pt-2 pl-3"><div style={{ "maxWidth": "80%" }}>
                <div className="form-group d-flex flex-row mb-0">
                  <label for="sel1" className="pt-2 pr-2">Titles</label>
                  <SelectEditable titles={this.state.titles} selectedTitle={this.state.selectedTitle} titleChange={this.titleChange} titleKeyPress={this.titleKeyPress} />

                </div>
              </div>
                <div className="pl-1 pt-1 border-0" style={{ "maxWidth": "20%" }}><button onClick={this.filterTitles}><i className="fa fa-filter"></i></button> <button onClick={this.deleteTitle}><i className="fa fa-trash"></i></button></div>
              </div> : ""}
              <ExtractedText content={this.state.listKbDocsData.hits.hits} closeDetail={this.viewExtractedDoc} listDocs={true} />

            </div>

              : ""}

            {this.state.showExtractedText ? this.state.mimeType === 'pdf' || this.state.mimeType === 'scanned pdf' || this.state.mimeType === 'html' || this.state.mimeType === 'pptx' || this.state.mimeType === 'docx' || this.state.mimeType === 'txt' || this.state.mimeType === 'png' || this.state.mimeType === 'jpg' ?
              <div className="d-flex flex-column"><div className="d-flex flex-wrap flex-fill">

                <ResizableBox className="box" width={420} height={420} lockAspectRatio={true}
                  minConstraints={[300, 300]} maxConstraints={[Infinity, Infinity]}>
                  {this.state.mimeType === 'html' ? <Frame
                    initialContent="<div id='mountHere'></div>"
                    mountTarget='#mountHere'

                  >
                    <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                  </Frame> : this.state.mimeType === 'pptx' || this.state.mimeType === 'docx' || this.state.mimeType === 'dita' ? <div className="d-flex flex-column h-100 align-content-center justify-content-center">No Preview available</div> : this.state.mimeType === 'txt' && this.state.uploadedData !== undefined ? <div className="text-left" style={{ "maxHeight": "100%", "overflow": "auto" }} dangerouslySetInnerHTML={{ __html: this.state.uploadedData.toString().split("\n").join("<br/>").split("\n\n").join("<br/><br/>") }}></div> :
                    this.state.mimeType === 'png' || this.state.mimeType === 'jpg' ? <img className="imagePreview" src={this.state.uploadedData} alt={this.state.fileName} /> :
                      <embed src={this.state.uploadedData} style={{ "width": "100%", "height": "100%" }} />}
                </ResizableBox>

                <ResizableBox className="box ml-2" width={420} height={420} lockAspectRatio={true} minConstraints={[300, 300]} maxConstraints={[Infinity, Infinity]}
                ><ExtractedText content={this.state.extractedText} submitEditedForIndexing={(content) => this.setState({ extractedText: content })} closeDetail={this.viewExtractedText} listDocs={false} inProgress={this.state.inProgress} /></ResizableBox>
              </div>
                {this.state.extractedText.document && this.state.extractedText.document.extra_pack && this.state.extractedText.document.extra_pack.contentTags && this.state.extractedText.document.extra_pack.contentTags.length > 0 && <div><h3 className="text-left pl-3">Tags</h3>
                  <AddTag tags={this.state.extractedText.document.extra_pack.contentTags} customTags={this.state.customTags} setAutoTags={(tags) => this.setAutoTags(tags)} setTags={(tags) => this.setTags(tags)} /></div>}
                {this.state.extractedText.document && this.state.extractedText.document.extra_pack && this.state.extractedText.document.extra_pack.summary && this.state.extractedText.document.extra_pack.summary.length > 0 && <div><h3 className="text-left pl-3">Summarized Text</h3>
                  <p className="text-left pl-3 pr-3">{this.state.extractedText.document.extra_pack.summary}</p></div>}

                <div>
                  {this.state.extractedText !== null && this.state.extractedText.document && Array.isArray(this.state.extractedText.document.pages.page[0].sections.section) && this.state.extractedText.document.pages.page[0].sections.section.length > 0 &&
                    <><button className="btn md-background--primary rounded-pill" disabled={this.state.inProgress} onClick={this.submitEditedForIndexing}>Submit</button>
                    <button className="btn md-background--primary rounded-pill ml-2" disabled={this.state.inProgress} onClick={()=>this.discardExtraction(this.state.extractedText.document.s3_content_url)}>Discard</button>
                    </>
                  }
                </div>
              </div>
              :
              <div>
                <ExtractedText content={this.state.extractedText} submitEditedForIndexing={(content) => this.setState({ extractedText: content })/*this.submitEditedForIndexing(content)*/} closeDetail={this.viewExtractedText} listDocs={false} inProgress={this.state.inProgress} />
                {this.state.extractedText.document && this.state.extractedText.document.extra_pack && this.state.extractedText.document.extra_pack.contentTags && this.state.extractedText.document.extra_pack.contentTags.length > 0 && <div><h3 className="text-left pl-3">Tags</h3>
                  <AddTag tags={this.state.extractedText.document.extra_pack.contentTags} customTags={this.state.customTags} setAutoTags={(tags) => this.setAutoTags(tags)} setTags={(tags) => this.setTags(tags)} /></div>}
                {this.state.extractedText.document && this.state.extractedText.document.extra_pack && this.state.extractedText.document.extra_pack.summary && this.state.extractedText.document.extra_pack.summary.length > 0 && <div><h3 className="text-left pl-3">Summarized Text</h3>
                  <p className="text-left pl-3 pr-3">{this.state.extractedText.document.extra_pack.summary}</p></div>}

                <div>
                  {this.state.extractedText !== null && this.state.extractedText.document && Array.isArray(this.state.extractedText.document.pages.page[0].sections.section) && this.state.extractedText.document.pages.page[0].sections.section.length > 0 &&
                    <>
                    <button className="btn md-background--primary rounded-pill" disabled={this.state.inProgress} onClick={this.submitEditedForIndexing}>Submit</button>
                    <button className="btn md-background--primary rounded-pill ml-2" disabled={this.state.inProgress} onClick={()=>this.discardExtraction(this.state.extractedText.document.s3_content_url)}>Discard</button>
                    </>
                  }
                </div>
              </div>
              : ""
            }
          </div>

            : this.state.searchedItems.length > 0 ? <div><ul className="list-group p-0 mt-3" id="suggestion">
              {kbNames}

            </ul>
              {this.state.searchedItems.length > 10 ? <div><Button className={`mx-auto show-more`} onClick={() => this.setState({ loadMore: true })} flat >Show
                more...</Button>
                <Button className="mx-auto show-more" onClick={() => this.setState({ loadMore: false })} flat primary>Show
                  Less...</Button></div> : ""}
            </div> : <p>No Knowledge Base has been created yet.</p>
          }
        </div>
        {/*<table className="w-100">
            <tr>
        <td colSpan="2"><FileUpload
          id="server-upload-file"
          label="Browse"
          required
          accept="application/*,text/*"
          onLoad={this.handleLoad}
          onLoadStart={this.handleLoadStart}
          onProgress={this.handleProgress}
          name="file"
          className="file-inputs__upload-form__file-upload d-inline-block w-25 pr-3"
          primary
          icon={<i>&nbsp;</i>}
          inherit="true"
          
        />
        <TextField
          id="server-upload-file-field"
          placeholder="No file chosen"
          value={fileName}
          className="file-inputs__upload-form__file-field w-75"
          readOnly
          fullWidth={false}
        /></td>
        </tr>
        <tr>
          <td>Elastic Search URL</td>
          <td><input type="text" value="" className="pt-1 d-block w-100"/></td>
        </tr>
        <tr className="border border-bottom-0">
            <td className="align-top"><input type="text" value="" className="pt-2 pb-1"/></td>
            <td className="align-top">
                <Button raised flat primary swapTheming  className="bg-primary text-dark" onClick={this.createKnowledgebase}>{buttonLabel}</Button>
                
            </td>
            </tr>
            <tr className="border border-top-0">
              <td colSpan="2" className="text-center"><div className="p-3 d-block">
                    <p>No Knowledge Base has been created yet.</p>
                </div></td>
             </tr>  

        <tr>
                 <td colSpan="2" className="text-center">
                 
                 <button type="button" className="btn btn-secondary rounded border" data-dismiss="modal">Upload</button> 
                 <button type="button" data-dismiss="modal" className="btn btn-secondary rounded border ml-2">Cancel</button></td>
                 </tr>
    </table>*/}
        <div className="modal fade" id="formModal">
          <div className="modal-dialog">
            <div className="modal-content">


              <div className="modal-header md-background--primary">
                <h6 className="modal-title text-white">CREATE NEW DOCUMENT</h6>
                <button type="button" className="close text-white" data-dismiss="modal">×</button>
              </div>


              <div className="modal-body mr-4 pt-0">
                <TextField
                  id="floating-center-title"
                  label="Document Name*"
                  lineDirection="center"
                  placeholder="Enter document name"
                  className="md-cell md-cell--bottom d-block w-100" required={true} onChange={this.docNameChange}
                  value={this.state.documentName}
                />

<SelectField
                  id="knowledge-type"
                  label="Knowledge Type*"
                  placeholder="select"
                  className="md-cell d-block w-100"
                  menuItems={["FAQ", "Knowledge Base Article"]}
                  required={true}
                  onChange={this.knowledgeTypeSelect}
                  value={this.state.knowledgeType}
                />

                <TreeSelect 
                treeData={this.state.mockData}
                disabled={this.state.knowledgeType === "FAQ"}
                  className="pl-2 pt-3"
                  value={this.state.targetKeys}
                  /*showCheckedStrategy={TreeSelect.SHOW_CHILD}*/
                  onChange={this.onPackChange}
                  treeCheckable={true}
                  treeDefaultExpandAll={true}
                  placeholder='Select Packs'
                  style={{
                    width: '100%',
                  }} 
                  /* for select all 
                  treeData={[
                    {
                      title: (
                        <React.Fragment>
                          {this.state.targetKeys.length === this.state.mockData.length && (
                            <span
                              style={{
                                display: "inline-block",
                                color: "#ccc",
                                cursor: "not-allowed"
                              }}
                            >
                              Select all
                            </span>
                          )}
                          {this.state.targetKeys.length < this.state.mockData.length && (
                            <span
                              onClick={() => this.onPackChange(this.state.mockData)}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer"
                              }}
                            >
                              Select all
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;
                          {this.state.targetKeys.length === 0 && (
                            <span
                              style={{
                                display: "inline-block",
                                color: "#ccc",
                                cursor: "not-allowed"
                              }}
                            >
                              Unselect all
                            </span>
                          )}
                          {this.state.targetKeys.length > 0 && (
                            <span
                              onClick={() => this.onPackChange([])}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer"
                              }}
                            >
                              Unselect all
                            </span>
                          )}
                        </React.Fragment>
                      ),
                      value: "xxx",
                      disableCheckbox: true,
                      disabled: true
                    },
                    ...this.state.mockData
                  ]}    */            
                   />

                


                {this.state.showMimeSelect ? <div className={`${this.props.customExtractionUrl.length > 0 ? 'disable-select' : this.state.knowledgeType === 'FAQ' ? 'faq' : ''}`}>
                  <Select
                    placeholder="Select MIME Type"
                    list={fruit}
                    onSelectChange={(item) => { this.mimeSelect(item) }}
                    value={this.state.mimeType}
                  /></div> : ""}

                <div className="d-flex flex-row"> <SelectionControlGroup
                  id="selection-control-group-radios"
                  name="radio-example"
                  type="radio"
                  label="DATA SOURCE"
                  className="pl-1 ml-1"
                  value={this.state.uploadType}
                  onChange={this.changeUploadType}
                  controls={[{
                    label: 'URL',
                    value: 'url',
                    disabled: (((this.state.mimeType === '' && this.props.customExtractionUrl.length === 0) || (this.state.mimeType === 'pdf' || this.state.mimeType === 'pptx' || this.state.mimeType === 'docx' || this.state.mimeType === 'dita' || this.state.mimeType === 'scanned pdf' || this.state.mimeType === 'csv' || this.state.mimeType === 'txt' || this.state.mimeType === 'jpg' || this.state.mimeType === 'png' || this.state.mimeType === 'json'))),
                    className: "w-25"
                  }, {
                    label: 'Upload file from your computer',
                    value: 'upload',
                    className: "w-25 pt-4",
                    disabled: (this.state.mimeType === '' && this.props.customExtractionUrl.length === 0)
                  }]}
                />

                  <div className="d-flex flex-column w-75"><TextField
                    id="floating-center-title"
                    label="URL*"
                    lineDirection="center"
                    placeholder="http://www.example.com/abc"
                    className="d-block"
                    disabled={(this.state.mimeType === '' && this.props.customExtractionUrl.length === 0) || (this.state.mimeType === 'pdf' || this.state.mimeType === 'scanned pdf' || this.state.mimeType === 'csv' || this.state.mimeType === 'txt' || this.state.mimeType === 'jpg' || this.state.mimeType === 'png' || this.state.mimeType === 'pptx' || this.state.mimeType === 'docx') || (this.state.uploadType !== 'url')}
                    onChange={this.urlChange} value={this.state.docUrl}
                    type="url"
                  />
                    {this.state.docUrlError ? <span className="d-inline-block text-danger">Please Enter a valid url</span> : ""}
                    <div className="pl-0 pt-5 d-block"><FileUpload
                      id="server-upload-file"
                      label="Choose file"
                      onLoad={this.handleLoad}
                      onLoadStart={this.handleLoadStart}
                      required
                      accept={this.state.mimeType === 'html' ? ["text/html"] : this.state.mimeType === 'pdf' ? ["application/pdf"] : this.state.mimeType === 'scanned pdf' ? ["application/pdf"] : this.state.mimeType === 'csv' ? ".csv" : this.state.mimeType === 'txt' ? ["text/plain"] : this.state.mimeType === 'jpg' ? ["image/jpeg"] : this.state.mimeType === 'png' ? ["image/png"] : this.state.mimeType === 'json' ? ["application/json"] : this.state.mimeType === 'pptx' ? ["application/vnd.openxmlformats-officedocument.presentationml.presentation"] : this.state.mimeType === 'docx' ? ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] : this.state.mimeType === 'dita' ? ["application/zip"] : ""}
                      name="file"
                      allowDuplicates={true}
                      className="file-inputs__upload-form__file-upload ml-0 rounded-pill "
                      onChange={this.fileChange}
                      icon={null}
                      disabled={(this.state.mimeType === '' && this.props.customExtractionUrl.length === 0) || (this.state.uploadType !== 'upload')}
                      primary
                      iconBefore
                    />
                      <TextField
                        id="server-upload-file-field"
                        placeholder="No file chosen"
                        value={this.state.fileName}
                        className="file-inputs__upload-form__file-field d-block"
                        readOnly
                        fullWidth={false}
                        disabled={(this.state.mimeType === '' && this.props.customExtractionUrl.length === 0)}
                      />
                      {this.state.file !== null && ((this.state.mimeType === 'html' && this.state.file.type !== "text/html") || ((this.state.mimeType === 'pdf' || this.state.mimeType === 'scanned pdf') && this.state.file.type !== "application/pdf") || (this.state.mimeType === 'csv' && (this.state.file.type !== "application/vnd.ms-excel" && this.state.file.type !== "text/csv")) || (this.state.mimeType === 'pptx' && this.state.file.type !== "application/vnd.openxmlformats-officedocument.presentationml.presentation") || (this.state.mimeType === 'docx' && this.state.file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") || (this.state.mimeType === 'txt' && this.state.file.type !== "text/plain") || (this.state.mimeType === 'jpg' && this.state.file.type !== "image/jpeg") || (this.state.mimeType === 'png' && this.state.file.type !== "image/png") || (this.state.mimeType === 'json' && this.state.file.type !== "application/json")) ? <span className="text-danger">The file type doesn't match with mime type</span> : ""}
                      <TextField
                        id="url-file-upload"
                        placeholder="Enter the file URL"
                        value={this.state.fileUrl}
                        className="d-block"
                        type="url"
                        fullWidth={false}
                        onChange={this.fileUrlChange}
                       
                        disabled={true}
                      />
                      {this.state.fileUrlError ? <span className="d-inline-block text-danger">Please Enter a valid url</span> : ""}
                    </div>

                  </div>
                </div>

              </div>


              <div className="modal-footer border-top-0 d-flex flex-column">
                {this.props.customExtractionUrl.length > 0 ? <div ><p className="text-info">Custom extraction end point is already in use. to change it please click on <a data-dismiss="modal" style={{ "textDecoration": "underline", "cursor": "pointer" }} onClick={this.props.showSettings}>settings</a> to change</p></div> : ""}
                {this.state.loaded && <Button className="mx-auto" onClick={this.createKnowledgeDoc} 
                disabled={(this.props.customExtractionUrl.length > 0 && (this.state.docUrl.length === 0 && this.state.fileUrl.length !== 0 && this.state.file === null || this.state.docUrlError || this.state.fileUrlError || this.state.knowledgeType == '' || this.state.documentName == '')) || (this.props.customExtractionUrl.length === 0 && (this.state.knowledgeBaseName == '' || this.state.documentName == '' ||
                  (this.state.mimeType === 'html' ? (this.state.docUrl.length === 0 && this.state.file === null || this.state.docUrlError || this.state.fileUrlError)
                   : 
                   this.state.file === null || this.state.fileUrlError) || this.state.mimeType == '' || this.state.knowledgeType == '' || (this.state.file !== null && (this.state.mimeType === 'html' && this.state.file.type !== "text/html" || 
                  (this.state.mimeType === 'pdf' || this.state.mimeType === 'scanned pdf') && this.state.file.type !== "application/pdf" || this.state.mimeType === 'csv' && (this.state.file.type !== "application/vnd.ms-excel" && this.state.file.type !== "text/csv") || this.state.mimeType === 'pptx' && this.state.file.type !== "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
                    this.state.mimeType === 'docx' && this.state.file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || this.state.mimeType === 'dita' && (this.state.file.type !== "application/x-zip-compressed" && this.state.zipFile.type !== "application/zip") || this.state.mimeType === 'txt' && this.state.file.type !== "text/plain" || this.state.mimeType === 'jpg' && this.state.file.type !== "image/jpeg" || this.state.mimeType === 'png' && this.state.file.type !== "image/png" || this.state.mimeType === 'json' && this.state.file.type !== "application/json"))))
                    ? true : false} data-dismiss="modal" raised primary>create</Button>}

              </div>

            </div>
          </div>
        </div>

        <div className="modal fade" id="bulkFormModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header md-background--primary">
                <h6 className="modal-title text-white">CREATE NEW DOCUMENT</h6>
                <button type="button" className="close text-white" data-dismiss="modal">×</button>
              </div>


              <div className="modal-body mr-4 pt-0">

              <SelectField
                  id="knowledge-type"
                  label="Knowledge Type*"
                  placeholder="select"
                  className="md-cell d-block w-100"
                  menuItems={["FAQ", "Knowledge Base Article"]}
                  required={true}
                  onChange={this.bulkKnowledgeTypeSelect}
                  value={this.state.bulkKnowledgeType}
                />

                <TreeSelect 
                treeData={this.state.mockData}
                 disabled={this.state.bulkKnowledgeType === "FAQ"}
                  className="pl-2 pt-3"
                  value={this.state.targetKeys}
                  /*showCheckedStrategy={TreeSelect.SHOW_CHILD}*/
                  onChange={this.onPackChange}
                  treeCheckable={true}
                  treeDefaultExpandAll={true}
                  placeholder='Select Packs'
                  style={{
                    width: '100%',
                  }} 
                  /*treeData={[
                    {
                      title: (
                        <React.Fragment>
                          {this.state.targetKeys.length === this.state.mockData.length && (
                            <span
                              style={{
                                display: "inline-block",
                                color: "#ccc",
                                cursor: "not-allowed"
                              }}
                            >
                              Select all
                            </span>
                          )}
                          {this.state.targetKeys.length < this.state.mockData.length && (
                            <span
                              onClick={() => this.onPackChange(this.state.mockData)}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer"
                              }}
                            >
                              Select all
                            </span>
                          )}
                          &nbsp;&nbsp;&nbsp;
                          {this.state.targetKeys.length === 0 && (
                            <span
                              style={{
                                display: "inline-block",
                                color: "#ccc",
                                cursor: "not-allowed"
                              }}
                            >
                              Unselect all
                            </span>
                          )}
                          {this.state.targetKeys.length > 0 && (
                            <span
                              onClick={() => this.onPackChange([])}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer"
                              }}
                            >
                              Unselect all
                            </span>
                          )}
                        </React.Fragment>
                      ),
                      value: "xxx",
                      disableCheckbox: true,
                      disabled: true
                    },
                    ...this.state.mockData
                  ]} */                 
                  />

                

                {this.state.showMimeSelect ? <div className={`${this.props.customExtractionUrl.length > 0 ? 'disable-select' : this.state.bulkKnowledgeType === 'FAQ' ? 'faq' : ''} bulk-faq`}><Select
                  placeholder="Select MIME Type"
                  list={fruit}
                  onSelectChange={(item) => { this.bulkMimeSelect(item) }}
                  value={this.state.bulkMimeType}

                /></div> : ""}






                <div className="d-flex flex-row"> <SelectionControlGroup
                  id="selection-control-group-radios1"
                  name="radio-example1"
                  type="radio"
                  label="DATA SOURCE"
                  className="pl-1 ml-1"
                  value={this.state.bulkUploadType}
                  onChange={this.changeBulkUploadType}
                  controls={[{
                    label: 'URL',
                    value: 'url',
                    disabled: true,
                    className: "w-25"
                  }, {
                    label: 'Upload file from your computer',
                    value: 'upload',
                    className: "w-25 pt-4",
                    disabled: (this.state.bulkMimeType === '' && this.props.customExtractionUrl.length === 0)
                  }]}
                />

                  <div className="d-flex flex-column w-75"><TextField
                    id="floating-center-title"
                    label="URL*"
                    lineDirection="center"
                    placeholder="http://www.example.com/abc"
                    className="d-block"
                    disabled={(this.state.bulkMimeType === '' && this.props.customExtractionUrl.length === 0) || (this.state.bulkMimeType === 'pdf' || this.state.bulkMimeType === 'pptx' || this.state.bulkMimeType === 'docx' || this.state.bulkMimeType === 'dita' || this.state.bulkMimeType === 'scanned pdf' || this.state.bulkMimeType === 'csv' || this.state.bulkMimeType === 'txt' || this.state.bulkMimeType === 'jpg' || this.state.bulkMimeType === 'png') || (this.state.bulkUploadType !== 'url')}
                    onChange={this.bulkUrlChange} value={this.state.bulkDocUrl}
                    type="url"
                  />
                    {this.state.bulkDocUrlError ? <span className="d-inline-block text-danger">Please Enter a valid url</span> : ""}
                    <div className="pl-0 pt-5 d-block"><FileUpload
                      id="server-upload-file1"
                      label="Choose file"
                      onLoad={this.handleBulkLoad}
                      onLoadStart={this.handleBulkLoadStart}
                      required
                      accept={["application/zip"]}
                      name="zipfile"
                      allowDuplicates={true}
                      className="file-inputs__upload-form__file-upload ml-0 rounded-pill "
                      onChange={this.zipFileChange}
                      icon={null}
                      disabled={(this.state.bulkMimeType === '' && this.props.customExtractionUrl.length === 0) || (this.state.bulkUploadType !== 'upload')}
                      primary
                      iconBefore
                    />
                      <TextField
                        id="server-upload-file-field"
                        placeholder="No file chosen"
                        value={this.state.zipFileName}
                        className="file-inputs__upload-form__file-field d-block"
                        readOnly
                        fullWidth={false}
                        disabled={(this.state.bulkMimeType === '' && this.props.customExtractionUrl.length === 0)}
                      />
                      {this.state.zipFile !== null && (this.state.zipFile.type !== "application/x-zip-compressed" && this.state.zipFile.type !== "application/zip") ? <span className="text-danger">The file is not a zip file</span> : ""}
                      <TextField
                        id="url-file-upload"
                        placeholder="Enter the file URL"
                        value={this.state.zipFileUrl}
                        className="d-block"
                        type="url"
                        fullWidth={false}
                        onChange={this.zipFileUrlChange}

                        disabled={true}/*{(this.state.bulkMimeType === '' && this.props.customExtractionUrl.length === 0) || (this.state.bulkMimeType === 'html')}*/
                      />
                      {this.state.zipFileUrlError ? <span className="d-inline-block text-danger">Please Enter a valid url</span> : ""}
                    </div>

                  </div>
                </div>

              </div>


              <div className="modal-footer border-top-0 d-flex flex-column">
                {this.props.customExtractionUrl.length > 0 ? <div ><p className="text-info">Custom extraction end point is already in use. to change it please click on <a data-dismiss="modal" style={{ "textDecoration": "underline", "cursor": "pointer" }} onClick={this.props.showSettings}>settings</a> to change</p></div> : ""}
                {this.state.loaded && <Button className="mx-auto" onClick={this.bulkIngestion} disabled={(this.props.customExtractionUrl.length > 0 && (this.state.zipFile === null || this.state.bulkKnowledgeType == '' || (this.state.zipFile !== null &&
                  (this.state.zipFile.type !== "application/x-zip-compressed" && this.state.zipFile.type !== "application/zip")))) || (this.props.customExtractionUrl.length === 0 && (this.state.knowledgeBaseName == '' || (this.state.bulkMimeType === 'html' ? (this.state.bulkDocUrl.length === 0 && this.state.zipFile === null || this.state.bulkDocUrlError || this.state.zipFileUrlError) : this.state.zipFile === null || this.state.zipFileUrlError) || this.state.bulkMimeType == '' || this.state.bulkKnowledgeType == '' || (this.state.zipFile !== null && (this.state.zipFile.type !== "application/x-zip-compressed" && this.state.zipFile.type !== "application/zip")))) ? true : false} data-dismiss="modal" raised primary>create</Button>}
              </div>

            </div>
          </div>
        </div>

        <Snackbar
          id="example-snackbar"
          toasts={this.state.toasts}
          autohide={this.state.autohide}
          onDismiss={this.dismissToast}
        />

      </div>




    );
  }
}
export default AdminUpload;
