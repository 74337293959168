import React, { Component } from 'react';
//import extractReactComponents from "html-to-react-components";
//import Highlight from 'react-highlighter';
import Mark from 'mark.js'
import $ from 'jquery';
import logo from '../../images/curiosm_logo.png';
import '../../App.css';
//import sample from '../../gifts-entertainment-policy.pdf'
//import htmlfile1 from '../../TheBestofScottishBusiness.html'
//import htmlfile2 from '../../Shouldprivateinvestors.html'
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { pdfjs } from 'react-pdf';
import Frame from 'react-frame-component';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
var htmlDoc = {__html: Page};
var originalText = '';
//console.log("htmlfile1",htmlfile1);
const highlightPattern = (text, highlightWords) => {
  // This is the only major change -- rather than searching for a string, create a regex to search
  // for any strings in a provided array
  const regex = new RegExp(highlightWords, 'gi')
  const splitText = text.split(regex)

  if (splitText.length <= 1) {
    return text;
  }

  const matches = text.match(regex)

  return splitText.reduce(
    (arr, element, index) =>
      matches[index]
        ? [...arr, element, <mark key={index}>{matches[index]}</mark>]
        : [...arr, element],
    []
  )
}
const highlightPattern1 = (text, pattern) => {
  console.log("text", text);
  console.log("typeoftext", typeof (text));

  /*let finalText = null;

  text.map((maintext,index) => {
    originalText = originalText + maintext.str;
    const textToFind = originalText.indexOf(pattern);
    const b = "<mark>";
    console.log("textToFind",textToFind);
    finalText = [originalText.slice(0, textToFind), b, originalText.slice(textToFind),"</mark>"].join('');
 
     console.log("finalText",finalText);
    
  });
  return finalText;*/
  // originalText = originalText + text;






  /*if(text.length <= 1){
   const textToFind = originalText.indexOf(pattern);
   const b = "<mark>";
   console.log("textToFind",textToFind);
   const finalText = [originalText.slice(0, textToFind), b, originalText.slice(textToFind),"</mark>"].join('');
   
   console.log("finalText",finalText);
   return finalText
  }*/
  const splitText = text.split(pattern);
  if (splitText.length <= 1) {
    return text;
  }
  console.log("splitText", splitText);
  const matches = text.match(pattern);
  console.log("matches", matches);
  const patternSplit = pattern.split("");
  return splitText.reduce((arr, element, index) => (matches[index] ? [
    ...arr,
    element,
    <mark key={index}>
      {matches[index]}
    </mark>,
  ] : [...arr, element]), []);

  // const splitText = text.split(" ").join("!").split("!");
  //const patternSplit = pattern.split(" ").join("!").split("!");
  /* return splitText.map((stext,index) => patternSplit.map((ptext,index) =>  (stext == ptext) ?
   <mark key={index}>{stext}</mark> : ""
 ));*/

  /* let result = [];
   for(let i = 0; i <= splitText.length-1; i++){    
     let break1 = false;
     let break2 = false;
     console.log("i",i," ",splitText[i]);
     for(var j = i; j <= patternSplit.length-1; j++){
       console.log("j",j," ",patternSplit[j]);
       if(splitText[i] == patternSplit[j]){
       result.push(<mark key={i}>{splitText[i]}</mark>); 
        break1 = true;
        console.log("equal");
       } else {
          if(result.length <= i)
           result.push(splitText[i]); 
          
         
       }
       if(break1 || break2){
         break;
       }
         
     } 
      
   }
   console.log("result",result);
   return result;*/


  /*if (text.includes(pattern)) {
    return (<mark>{text}</mark>)
  }*/

};
let finalTextItem = null;
class Pdfrender extends Component {

  state = {

    numPages: null,
    pageNumber: 5,
    str: '',
    itemIndex: 5.1,
    searchText: "Compliance will periodically monitor records of gifts and entertainment given and received"
    
  }

  componentDidMount() {
    /*var context = document.getElementById("mountHere"); // requires an element with class "context" to exist
    var instance = new Mark(context);
    var text = this.props.textToHighlight.replace("<em> "," ").replace(" </em>"," ");
    console.log("text",text);
    var x = instance.mark(text, {"element":"mark","separateWordSearch": false,"iframes": true,"iframesTimeout": 5000});
    console.log("text",text);
    console.log("x",x);*/
    
    //console.log("x.body.scrollTop",y.body.scrollTop)
    //var val = y.getBoundingClientRect();
   setTimeout(() => {console.log("docget",document.getElementsByTagName("iframe"));
   var x = document.getElementsByTagName("iframe")[0].contentWindow.document;
   var y = x.querySelector('mark');
   console.log("x",x);
   console.log("y",y);
   var scrollTop = x.documentElement.scrollTop;
   console.log("x.documentElement.scrollTop",x.documentElement.scrollTop);
   if(y !== null){y.scrollIntoView()}
  },1000);
  }

  mount = () => {
    var context = document.querySelector(".highlight p"); // requires an element with class "context" to exist
    var instance = new Mark(context);
    var text = this.props.textToHighlight.replace("<em> "," ").replace(" </em>"," ");
    console.log("text",text);
    instance.mark(text, {"element":"mark","separateWordSearch": false});
  }

  makeTextRenderer = searchText => textItem => highlightPattern(textItem.str, searchText)

  onChange = event => this.setState({ searchText: event.target.value });

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
  onDocumentSourceSuccess = (page) => {
    $('.react-pdf__Document div').each(function (eq, el) {
      el = $(el);
      el.attr('id', 'p' + el.attr("data-page-number"));

    });
  }


  createMarkup = () => {
    return {__html: this.props.fileContent};
  }

  // customTextRenderer = ({ str }) => { return (<mark>{str}</mark>) }

  render() {
    const { pageNumber, numPages } = this.state;
    const { searchText } = this.state;
    const range = (start, stop, step) => Array.from({ length: (stop - start) / step }, (_, i) => start + (i * step));
    const pagesList = range(1, numPages + 1, 1);
    console.log("pagesList", pagesList);
    
  //  const extrac = extractReactComponents( '`'+htmlfile1+'`', {componentType : "stateless", moduleType: false });
   // console.log("extrac",extrac);

    console.log("pdf props",this.props);

   
    var options = {
      "element": "mark",
      "className": "",
      "exclude": [],
      "separateWordSearch": false,
    "diacritics": false,
      "iframes": true,
      "iframesTimeout": 5000,
      "acrossElements": false,
      "ignoreGroups": 0,
      "each": function(node){
          // node is the marked DOM element
      },
      "filter": function(textNode, foundTerm, totalCounter){
          // textNode is the text node which contains the found term
          // foundTerm is the found search term
          // totalCounter is a counter indicating the total number of all marks
          //              at the time of the function call
          return true; // must return either true or false
      },
      "noMatch": function(term){
          // term is the not found term
      },
      "done": function(counter){
          // counter is a counter indicating the total number of all marks
      },
      "debug": false,
      "log": window.console
  };
 
    return (

      
           
              
              
              
              <div className="highlight">


<Frame
  initialContent="<div id='mountHere'></div>"
  mountTarget='#mountHere'
  
  >
  <div dangerouslySetInnerHTML={this.createMarkup()}></div>
</Frame>


             {/* <iframe src={this.props.fileUrl} />*/}

             {/* <div dangerouslySetInnerHTML={this.createMarkup()} >           
                
  
    </div>*/}
              </div>
          
         



        


     




    );
  }
}

export default Pdfrender;
