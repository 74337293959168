import React, { Component } from 'react';
import ReactJson from 'react-json-view'

class JsonViewer extends Component {
   
   
      render() {
       
        return (
         <div style={{"overflowWrap":"break-word"}}>
             
             <ReactJson  src={this.props.jsonObj} collapseStringsAfterLength={10} />
        </div>
        )
      }
    }
   
export default JsonViewer


