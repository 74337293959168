import React from "react";

export default function UnderMaintenance({healthMaintenance}){

return (
 <div className="container-fluid">
     <div className="row">

<div className="col-sm-2 clearfix">
  <header className="">

    <span><img src="image/PS_Logo_RGB.svg" className="App-logo float-left mt-3 pr-2" alt="Curiosum" /> 
    <p className="font-weight-bold pt-3 mb-0 d-block">
    <img src='image/logo_h.png' className="kaas-logo float-left pr-1" alt="Curiosum" /> <br/> <span className="small-text pl-1 d-inline-block mt-1 w-50 text-left">Cognitive Search</span></p>
    </span>

  </header>
  </div>
  </div>
  <div className="d-flex flex-column shadow mt-3 container-fluid border rounded">
  {healthMaintenance && <h3 className="text-center p-1">Kindly refresh the page after sometime.</h3>}
     <div className="w-100 text-center p-3">
     <img src="image/maintenance.gif" alt="Under Maintenance" className="rounded mt-5"/>
     
     </div>


     </div>
 </div>
);
}