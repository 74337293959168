const DNS = `https://ktdemo.autokaas.com`
const NODE_SERVER_URL = `${DNS}`;//`${DNS}/node`;
const BASE_URL = `https://cognitive.autokaas.com/recentsearch`;

// endpoints called via node server:
export const VERIFY_USER_LOGIN = `${DNS}/user/login`;
export const GET_STACK_STATUS = `${DNS}/on_demand/response`
export const SEARCH_RESULTS = `${DNS}/curiosum`;
export const YOU_MAY_ASK = `${NODE_SERVER_URL}/you_may_ask`;
export const GETS3HTMLDATA = `${NODE_SERVER_URL}/kb/getS3HtmlData`;//need help
export const DELETES3HTMLDATA = `${NODE_SERVER_URL}/kb/deleteS3HtmlData`;
export const QUERY_SUGGESTION = `${DNS}/querySuggestion`;
export const USERKBMAPPING = `${NODE_SERVER_URL}/userKbMapping`;
export const GETADMINKBDETAILS = `${NODE_SERVER_URL}/adminKbMapping`;
export const GETKNOWLEDGEBASES = `${DNS}/user/listKnowledgeBase`;//`${NODE_SERVER_URL}/getKnowledgeBases`;
export const GETKNOWLEDGEBASEDOCS =  `${NODE_SERVER_URL}/getKnowledgeBaseDocs`;
export const CREATEKNOWLEDGEBASEDOCS = `${DNS}/kb/extract`;//`${DNS}/kb/ElasticSearch/ExtractText`;//createKnowledgeBaseDocs1;
export const BULK_INGESTION = `${DNS}/kb/injestion`;
export const STOP_INGESTION = `${DNS}/kb/stop`;//`${NODE_SERVER_URL}/stopIngestion`;
export const EXTRACT_TASK_STATUS = (taskId) =>`${DNS}/kb/extractStatus/${taskId}`;
export const BULK_INGESTION_TASK_STATUS = (taskId) => `${DNS}/kb/status/${taskId}`;//`${NODE_SERVER_URL}/taskStatus`;
export const CREATEKBDOCSINDEXING = `${DNS}/kb/ElasticSearch/IndexContent`;//`${NODE_SERVER_URL}/editKbDocs`;
export const CREATEKNOWLEDGEBASE = `${DNS}/kb/ElasticSearch/CreateKnowledgeBase`;//`${NODE_SERVER_URL}/createKnowledgeBase`;
export const DELETEKNOWLEDGEBASE = `${DNS}/kb/ElasticSearch/MultiDeleteKnowledgeBase`;//`${NODE_SERVER_URL}/deleteKnowledgeBase`;
export const CURIOSUM_CHAT = `${NODE_SERVER_URL}/curiosumChat`;
export const GET_FUZZY_MATCH = `${DNS}/fuzzymatch`;
export const GET_TITLES = `${DNS}/kb/ElasticSearch/GetDocTitles`;
export const DELETE_TITLES = `${DNS}/kb/ElasticSearch/DeleteContent`;//`${NODE_SERVER_URL}/deleteTitles`;
export const GETDOCSBYTITLES = `${DNS}/kb/ElasticSearch/GetDocsByTitle`;
export const GET_LIST_PACKS = `${DNS}/kb/listPacks`;
export const ADD_USER = `${DNS}/user/addUser`;
export const GET_USERS = `${DNS}/user/getUsers`;
export const DELETE_USER = `${DNS}/user/deleteUser`;
export const GET_ACTIVE_TASKS = (userName) =>`${DNS}/user/activeTasks/${userName}`;
export const IN_PROGRESS_MSG = `Already ingestion is in progress, only one ingestion is supported at a time, please try later`;
export const CUSTOM_TEST_END_POINT = `${DNS}/testEndPoint`;
export const SUBMIT_FEEDBACK = `${DNS}/user/feedback`;
export const SUBMIT_RATING = `${DNS}/feedback/submitFeedback`;
export const GET_IP = `${DNS}/getIP`;//not used
export const GET_MODELS = `${DNS}/ltrwrapper/getModels`;
export const DELETE_MODEL = `${DNS}/ltrwrapper/deleteModel`;
export const TRAIN_MODEL = `${DNS}/ltrwrapper/dispatchTrainingTask`;
export const TRAINING_STATUS = (taskId) => `${DNS}/ltrwrapper/getTaskStatus/${taskId}`;
export const GET_EXAMPLE_COUNT = `${DNS}/judgment/getExampleCount`;

// nav links:
export const CHATBOT_NAV_LINK = `https://chatbot.autokaas.com`;
export const BOTMETRICS_NAV_LINK = `https://botmetrics.autokaas.com`;
export const QAMGMT_NAV_LINK = `https://qamanagement.autokaas.com`;
export const KAAS_VIDEO_URL = `https://youtu.be/b3lHMALNtiI`;

export const UI_SESSION_TIMER = 10800;//default set to 3 hours it is 10800 seconds. set for 10 minutes as default ; convert minutes to seconds 10*60=600. 
export const API_LOOP_TIMER = 20000;//every 20 seconds API will loop
export const ALL_PLATFORMS_SERVICES_TIMER = 600000;//10 minutes => converted to milliseconds

export const ALL_SERVICES_HEALTH = `${DNS}/serviceshealth/allservices_health`;
export const ALL_PLATFORM_HEALTH = `${DNS}/serviceshealth/platform_health`;
export const RECENT_SEARCH = `https://ktdemo.autokaas.com/recentsearch/pastqueries`;
export const REGISTER_USER = `https://ktdemo.autokaas.com/user/registerUser`;
export const GET_DEX_KEYS = `https://cognitive.autokaas.com/node/getDexKeys`;
/*{
    "keys": [
      {
        "use": "sig",
        "kty": "RSA",
        "kid": "39c9ceec28354a5c6d227a3de48fd5c9f7b3d5ff",
        "alg": "RS256",
        "n": "qLcDW_cDo1JHXd4mI5aGox0JOP9V4Ve0xn13Ax84isldDVmH8gD272DNGUkf2Q4CoazfqApZyA01nS4JLS3t6FR5MDyFIONOcJYPXe2dMMwa9FOXzrmP7c1DTemveGZoWZww5pfGmZWbcr_ZWGObFPgAcLyMPH5HndlTgwkm_HcvUo5RnigTwGWVlno2DniZbfYlEzxsuNU_fnzauu5pwj2SjXvdRr-IPpR1zX8KeKtU9EFekRcRySlm07dNvs4u4F8bltfRWDABr8vTx8iwsMToYbr1e2pnzgSefycmtslPDc6kcz8NNAgAexJ0eTlKZLzlNeIbdOFnqIH3NMOVZQ",
        "e": "AQAB"
      }
    ]
  };*/

