import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import TextTruncate from 'react-text-truncate';
class NodeLabel extends React.PureComponent {
   
   plusClick = (evt) => {
       this.props.onPlusClick(evt.target.id);
   }
      render() {
        const {className, nodeData} = this.props
    //console.log("this.props",this.props);
        return (
          <div className={className} data-tip data-for={nodeData.name} style={{width: '150px'}} >
     <ReactTooltip id={nodeData.name} className="toolTip" type="info" effect="solid" place="right">
       <span>{nodeData.toolTip}</span>
      </ReactTooltip>
            <div className="border-bottom pb-2 text-center clearfix"><strong className="text-left" >{nodeData.name}</strong></div>
         {nodeData.result && <div className="tNodeContent">
             {
                 this.props.data.qna_response && this.props.data.qna_response.map((detail, index) => 
                 
                 (nodeData.name == 'faq' && index < 1 || nodeData.name != 'faq' ? <div className="clearfix" key={index}><a className="float-left w-75 text-left">
                        <TextTruncate
    line={1}
    truncateText="…"
    text={ (nodeData.name == "Document Retriever") ? detail.full_content_unranked && detail.full_content_unranked.length > 0 ? detail.full_content_unranked : "" : (nodeData.name == "faq") ? detail.machineComprehensionAnswer :(nodeData.name == "Reranking") ? detail.full_content : (nodeData.name == "Machine Comprehension") ? detail.machineComprehensionAnswer : (nodeData.name == "Validator") ? detail.curiosum_top_answer : ""}
    
/>
                    
                    </a>{/*<span className={nodeData.name == "Document Retriever" ? "fa fa-file-pdf-o float-right pl-2 align-middle w-25 pt-1" : "" }></span>*/} 
             { (detail.faq == 0 && detail.knowledgebase == 0) || (detail.faq == 1 && detail.knowledgebase == 0) ? "" : <span className="fa fa-plus-square-o float-right pl-2 align-middle w-25 pt-1" /*onClick={this.plusClick}*/ id={index}></span>}</div>
                  : "") )
             }
            {/*<br/>
            <a>url 2..... </a><span className={nodeData.name == "Document Retriever" ? "fa fa-file-pdf-o float-right pl-2" : ""}></span> <span className="fa fa-plus-square-o float-right pl-2"></span><br/>
            <a>url 3..... </a> <span className={nodeData.name == "Document Retriever" ? "fa fa-file-pdf-o float-right pl-2" : ""}></span> <span className="fa fa-plus-square-o float-right pl-2"></span>*/}
            </div>
         }
                   {nodeData.result && nodeData._children && 
              <button className = "btn bg-none text-right" onClick={this.props.onClick}>{nodeData._collapsed ? <i className="fa fa-expand"></i> : <i className="fa fa-compress"></i>}</button>
            }
      
          </div>
        )
      }
    }
   
export default NodeLabel


