import React, { Component } from 'react';
import Pagination from '../pagination.js'
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ContentEditable from 'react-contenteditable'


class ExtractedText extends Component {
    constructor(props) {
        super(props);    
    this.state = {
        allCountries: [],
        currentCountries: [],
        currentPage: null,
        totalPages: null,
        viewDocsDetail : false,
        viewDocsDetailData : null,
        defaultType:"all",
        page:0,
        editedContent:{}
      }
        
      }

      componentDidMount() {
        if(this.props.listDocs){
          const allCountries = this.props.content;
        this.setState({ allCountries });
        }else{       
            const allCountries = this.props.content.document ? this.props.content.document.pages.page : [];
          this.setState({ allCountries });
         
        }
        
      };
      
      onPageChanged = data => {
        const { allCountries } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
    
        const offset = (currentPage - 1) * pageLimit;
        const currentCountries = allCountries.slice(offset, offset + pageLimit);
    
        this.setState({ currentPage, currentCountries, totalPages });
      };

      viewExtractedDoc = (data) => {
          this.setState({viewDocsDetail: !this.state.viewDocsDetail,viewDocsDetailData : data})
      };

      editContent = (e,i,index,type) => {      
        const contentToEdit = this.props.content;
        if(type === "question"){
        
        contentToEdit.document.pages.page[i].sections.section[index].heading = e.target.textContent;        
          //console.log("edited question",contentToEdit.document.pages.page[i].sections.section[index].heading);
        }else if(type === "answer"){
         
          contentToEdit.document.pages.page[i].sections.section[index].content = e.target.textContent;        
            //console.log("edited answer",contentToEdit.document.pages.page[i].sections.section[index].content);
        }

        this.props.submitEditedForIndexing(contentToEdit);

        //  this.setState({editedContent:contentToEdit});
      };

      submitEditedContent = () => {
          this.props.submitEditedForIndexing(this.props.content);
      }
   
      render() {
           const {
            allCountries,
            currentCountries,
            currentPage,
            totalPages
          } = this.state;
          const totalCountries = allCountries.length;
      
          if (totalCountries === 0) return null;
      
          const headerClass = [
            "text-dark py-2 pr-2 m-0",
            currentPage ? "border-gray border-right" : ""
          ]
            .join(" ")
            .trim();
        return (
            <div className="container mb-2" style={{"maxHeight":"100%","overflow": "auto"}}>
        {this.state.viewDocsDetail ? <div>
          <a className="fa fa-close d-block text-right p-2" onClick={this.viewExtractedDoc}></a>
          <div>            
             
                
                 <div className="d-flex flex-row border p-3">
                    <div className="w-100 d-flex flex-column text-left">
                    {this.state.viewDocsDetailData._source.type === "raw" ? <p><strong >Heading:</strong></p> : "" }
                    {this.state.viewDocsDetailData._source.type === "raw" ? <p>{this.state.viewDocsDetailData._source.heading}</p> : "" }
                    {this.state.viewDocsDetailData._source.type === "faq" ? <p><strong >Question:</strong></p> : ""}
                    {this.state.viewDocsDetailData._source.type === "faq" ? <p>Q. {this.state.viewDocsDetailData._source.heading}</p> : ""}
                    {this.state.viewDocsDetailData._source.type === "faq" ? <p><strong >Answer:</strong></p> : ""}                    
                    {this.state.viewDocsDetailData._source.type === "raw" ? <p><strong>Content:</strong></p> : ""}
                    <p  className="content-overlap">{this.state.viewDocsDetailData._source.content}</p>
                    </div>
                   
                 </div>
                      
          </div>
        </div> :
        <div>
          <div className="w-100 px-2 py-2 d-flex flex-row flex-wrap align-items-center justify-content-between">
          {!this.props.listDocs ? <div><div className="d-flex flex-row align-items-center">
              <h6 className={headerClass}>
                <strong className="text-secondary">{totalCountries}</strong>{" "}
                {this.props.listDocs ? "Hits" : "Sections"}
              </h6>
              {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
              )}
            </div>
            <div className="d-flex flex-row py-2 align-items-center">
              <Pagination
                totalRecords={totalCountries}
                pageLimit={11}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </div></div> : ""}
            {this.props.listDocs ? <div className="ml-auto"><a className="fa fa-close" onClick={()=>this.props.closeDetail()}></a></div> : <div><a className="fa fa-close" onClick={()=>this.props.closeDetail()}></a></div>}
          </div> 
          {this.props.listDocs ? <div> 
            
            
            <div className="w-100">

 <ReactTable
          data={allCountries}
          resolveData={data => data.map(row => row)}
          page = {this.state.page}
          onPageChange={(pageIndex) => this.setState({page:pageIndex})}
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value}
          filterable
          filtered={[{ // the current filters model
            id: 'over',
            value: this.state.defaultType
          }]}
          columns={ [     
                {
                  Header: "ID",
                  accessor: "_id",
                  Cell: ( row ) => <span>{row.value} <br/> (<a style={{"cursor":"pointer"}} className="text-primary"
                  onClick={()=>this.viewExtractedDoc(row.original)} primary="true">view detail</a>)</span>,
                  filterable: false,
                  sortable: false
                },
                allCountries[0]._source.type ? {
                  Header: "Knowledge Type",
                  accessor: d => d._source.type ? d._source.type : "",
                  id: "over",
                  filterable:true,
                  maxWidth: 130,
                  Cell: ({ value }) => (value),
                  filterMethod: (filter, row) => {     
                   
                    if (filter.value === "all") {
                      return true;
                    }
                    if (filter.value === "raw") {
                      return row[filter.id] == "raw";
                    }
                   
                    if (filter.value === "faq") {
                      return row[filter.id] == "faq";
                    }
                   
                  },
                  Filter: ({ filter, onChange } ) =>
                  <select 
                      onChange={event => {onChange(event.target.value);this.setState({defaultType:event.target.value})}}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : this.state.defaultType}
                    >
                    <option value="all">showall</option>
                      <option value="faq">faq</option>
                      <option value="raw">raw</option>
                      
                    </select>
                }:{show:false},
                {
                  Header: "Title",
                  accessor: "_source",
                  Cell: ({value}) => <a href={value.fileUrl?value.fileUrl : value.s3_content_url ? value.s3_content_url : '#'} {...((value.fileUrl && value.fileUrl.length>0) || (value.s3_content_url && value.s3_content_url.length>0) ?  {target:"blank"} : {})}>{value.title}</a>,
                  filterable: false,
                  sortable: false,
                  
                }
               /*{
                  Header: "File URL",
                  accessor: "_source.fileUrl",
                  Cell: ({value}) => <a href={value} target="blank">{value}</a>,
                  filterable: false,
                  sortable: false                 
                }*/
            
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />

{/*<table className="table table-bordered text-left" >
  <tbody>
   <tr>
     <th>ID</th>
     <th>Knowledge Type</th>
     <th>Title</th>
     <th>File URL</th>     
     </tr>
     {currentCountries.map((data,index)=>(
       <tr>
         <td>{data._id}<br/> (<a style={{"cursor":"pointer"}} className="text-primary"
         onClick={()=>this.viewExtractedDoc(data)} primary="true">view detail</a>)</td>
         <td>{data._source.type}</td>
         <td>{data._source.title}</td>
         <td><a href={data._source.fileUrl} target="blank">{data._source.fileUrl}</a></td>
         </tr>
     ))}
     </tbody>
    </table>*/}
     
</div> 
        </div> : <div id={`${this.props.content.document.mimeType === 'html' || this.props.content.document.mimeType === 'pdf' || this.props.content.document.mimeType === 'scanned pdf' || this.props.content.document.mimeType === 'txt' || this.props.content.document.mimeType === 'png' || this.props.content.document.mimeType === 'jpg' ? 'previewEditkbScroll' : 'editKbScroll'}`}> <div className="d-flex flex-row border p-3">
                    <div className="w-80 text-left"><p><strong>Question</strong></p></div>
                    <div className="w-20 text-center"><p><strong>Enabled</strong></p></div>
                    </div>
           {currentCountries.map((section,i) => (
             Array.isArray(section.sections.section) && section.sections.section.map((details,index) => (
              <div key={index}>
                
                 <div className="d-flex flex-row border border-top-0 p-3">
                    <div className="w-80 d-flex flex-column text-left">
                    {this.props.content.document.knowledgeType == 'faq' ? <p><strong >Question:</strong></p> : <p><strong >Heading:</strong></p>}
                    {this.props.content.document.knowledgeType == 'faq' ? <div className="d-flex flex-row"><p className="d-inline">Q. </p>
                    
                    <ContentEditable
              
              html={details.heading} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onKeyUp={(e)=>this.editContent(e,i,index,"question")} // handle innerHTML change
              tagName='div' // Use a custom HTML tag (uses a div by default)
              className="border p-1"
              style={{width:"100%", maxHeight:"200px", overflow:"auto"}}
            />
                    {/*<p className="d-inline-block w-100" contenteditable="true" onKeyUp={(e)=>this.editContent(e,i,index,"question")}>{details.heading}</p>*/}</div> : <div>
                    <ContentEditable
                     
              //innerRef={this.contentEditable2}
              html={details.heading} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onKeyUp={(e)=>this.editContent(e,i,index,"question")} // handle innerHTML change
              tagName='div' // Use a custom HTML tag (uses a div by default)
              className="border p-1"
              style={{width:"100%", maxHeight:"200px", overflow:"auto"}}
            />
                      {/*<p contenteditable="true" onKeyUp={(e)=>this.editContent(e,i,index,"question")}>{details.heading}</p>*/}</div>}
                    {this.props.content.document.knowledgeType === 'faq' ? <p><strong>Answer:</strong></p> : <p><strong>Content:</strong></p>}
                    <ContentEditable
              //innerRef={this.contentEditable3}
             
              html={details.content} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onKeyUp={(e)=>this.editContent(e,i,index,"answer")} // handle innerHTML change
              tagName='div' // Use a custom HTML tag (uses a div by default)
              className="border p-1 content-overlap"
              style={{width:"100%", maxHeight:"200px", overflow:"auto"}}
            />
                    {/*<p className="content-overlap" onKeyUp={(e)=>this.editContent(e,i,index,"answer")} contenteditable="true">{details.content}</p>*/}
                    </div>
                    <div className="w-20 text-center"><strong>Enabled</strong></div>
                 </div>
            </div>
            
          ))))}</div>}
         {!this.props.listDocs ? <div className="w-100 px-2 py-2 d-flex flex-row flex-wrap align-items-center justify-content-between">
            <div className="d-flex flex-row align-items-center">
              <h6 className={headerClass}>
                <strong className="text-secondary">{totalCountries}</strong>{" "}
                Sections
              </h6>
              {currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Page <span className="font-weight-bold">{currentPage}</span> /{" "}
                  <span className="font-weight-bold">{totalPages}</span>
                </span>
              )}
            </div>
            <div className="d-flex flex-row py-2 align-items-center">
              <Pagination
                totalRecords={totalCountries}
                pageLimit={11}
                pageNeighbours={1}
                onPageChanged={this.onPageChanged}
              />
            </div>
            {/*<div className={`${this.props.content.document.mimeType === 'html' || this.props.content.document.mimeType === 'pdf' || this.props.content.document.mimeType === 'txt' || this.props.content.document.mimeType === 'png' || this.props.content.document.mimeType === 'jpg' ? 'docEditSubmit' : ''}`}>
              {Array.isArray(allCountries[0].sections.section) && allCountries[0].sections.section.length>0 && <button className="btn md-background--primary rounded-pill" disabled={this.props.inProgress} onClick={this.submitEditedContent}>Submit</button>}
            </div>*/}
          </div> : ""}
        </div>}
      </div>
        )
      }
    }
   
export default ExtractedText


