import React, { Component } from 'react';
import {
  Button, DialogContainer,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  FileInput
} from 'react-md';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import * as apiConstants from '../../service-api-constants.js';
import Loader from 'react-loader';
//import Loader from '../../components/loader/loader'
import _ from 'lodash'

import axios from 'axios';
import { Input, ListItemText } from '@material-ui/core';

class KnowledgeBaseMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      knowledgeBaseNames: [],
      knowledgeBaseName: "",
      currentKb: null,
      openError: false,
      updateSuccess: false,
      updateError: false,
      vertical: 'top',
      horizontal: 'center',


      SocialData: [],
      toasts: [],
      visible: false,
      userNameToDelete: ''
    }

  }

  componentDidMount() {
    this.getUsers();
    this.getIndex();
  };

  show = (user) => {
    this.setState({ visible: true, userNameToDelete: user });
  };

  hide = () => {
    this.setState({ visible: false, userNameToDelete: '' });
  };

  toastRetry = (text) => {
    this.addToast(text, 'ok', false);
  };
  addToast = (text, action, autohide = true) => {
    this.setState((state) => {
      const toasts = state.toasts.slice();
      toasts.push({ text, action });
      return { toasts, autohide };
    });
  };

  dismissToast = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  };

  handleAddSocial = () => {
    let array = this.state.SocialData;
    array.push({ user_name: "", password: '', comment: "", kb_list: [] });
    this.setState({ SocialData: array });
  }
  handleRemoveSocial = (idx) => {
    let someArray = this.state.SocialData;
    someArray.splice(idx, 1);
    this.setState({ SocialData: someArray });
  }

  usernameChange = (e, i) => {
    this.state.SocialData[i]["user_name"] = e.target.value;
    // this.state.SocialData[i]["query_id"] = i;
    this.setState({ SocialData: this.state.SocialData })
  }

  passwordChange = (e, i) => {
    this.state.SocialData[i]["password"] = e.target.value;
    // this.state.SocialData[i]["query_id"] = i;
    this.setState({ SocialData: this.state.SocialData })
  }

  commentsChange = (e, i) => {

    this.state.SocialData[i]["comment"] = e.target.value;
    this.setState({ SocialData: this.state.SocialData })
  };

  submitData = () => {

    this.setState({ loaded: false });
    import('axios')
      .then(axios => {
        const data = this.state.SocialData.map((user, i) => {
          let users = { user_name: user.user_name, password: user.password, comment: user.comment, kb_list: user.kb_list.join(","),api_key: "" };
          return users;
        })
        //console.log("data", data)
        axios.post(apiConstants.ADD_USER,
          { "users": data, admin: this.props.userName === "admin" ? true : false },
          { headers: { 'Content-Type': "application/json" } }
        ).then((response) => {
          //console.log("response.data", response.data);
          this.setState({ loaded: true });
          if (response.data.errors === 0) {
            this.toastRetry(`${response.data.total} users added successfully`);
            this.setState({ SocialData: [] });
            this.getUsers();
          } else {
            this.toastRetry(response.data.error_message ? response.data.error_message : "Error in adding User")
          }

        }).catch((error) => {
          //console.log("error",error);
          this.toastRetry(error.toString())
          this.setState({ loaded: true });
        });
      });

  };

  deleteUser = (user) => {
    if (user.length > 0) {
      this.setState({ loaded: false });
      let currentUser = _.cloneDeep(user)
      axios.post(apiConstants.DELETE_USER,
        { "userName": user, admin: this.props.userName === "admin" ? true : false },
        { headers: { 'Content-Type': "application/json" } }
      ).then((response) => {
        //console.log("response.data", response.data);
        this.hide();
        if (response.data.status && response.data.status === true) {
          this.toastRetry(`user with username '${currentUser}' is deleted successfully`)
          this.getUsers();
        } else {
          this.toastRetry(`Error in deleting user '${currentUser}'`)
        }

        this.setState({ loaded: true });

      }).catch((error) => {
        //console.log("error",error);
        this.hide();
        this.toastRetry(error.toString());
        this.setState({ loaded: true });
      });
    }

  };


  getUsers = () => {
    this.setState({ loaded: false });
    axios({
      method: 'get',
      url: apiConstants.GET_USERS,
      config: {
        headers: { 'Content-Type': 'application/json' },
      }
    })
      .then((response) => {
        //handle success
        //console.log(response);
        const data = response.data;
        //console.log("data", data);
        //reactLocalStorage.remove('var');
        this.setState({ allUsers: data, loaded: true });
      })
      .catch((error) => {
        //console.log("error", error);
        this.setState({ error: error.toString(), loaded: true });
      });
  };


  getIndex = () => {
    this.setState({ loaded: false });
    axios.get(apiConstants.GETKNOWLEDGEBASES,
      {
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.props.apiKey
        },
        params: { userName: this.props.userName }
      })
      .then((response) => {
        //handle success
        //console.log(response);
        //const data = JSON.parse(response.data);
        const data = typeof response.data === "string" ? JSON.parse(response.data) : response.data;
        //console.log("data", data);
        const kbNames = data.map((kb) => {
          return kb.name
        });
        //console.log("kbNames", kbNames);
        //reactLocalStorage.remove('var');
        this.setState({ knowledgeBaseNames: kbNames, loaded: true });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ error: error.toString(), loaded: true });
      });
  };

  userSelect = (value) => {
    this.setState({ currentUser: value })
  };

  kbSelect = (event, i) => {
    this.state.SocialData[i]["kb_list"] = event.target.value;
    // this.state.SocialData[i]["query_id"] = i;
    this.setState({ SocialData: this.state.SocialData })
  };


  onKbClick = (e) => {
    //console.log("e", e.target.value)
  }

  handleClose = () => {
    this.setState({ openError: false, updateError: false, updateSuccess: false });
  };

  render() {
    const { allUsers, knowledgeBaseNames, openError, updateError, updateSuccess, vertical, horizontal, visible } = this.state;

    const actions = [{
      onClick: () => this.deleteUser(this.state.userNameToDelete),
      primary: true,
      children: 'Delete',
    }, {
      onClick: this.hide,
      primary: true,
      children: 'Cancel',
    }];
    return (
      <div>
        <h4 className="font-weight-bold">User Management</h4>

        <Loader loaded={this.state.loaded}></Loader>

        <div className="d-flex flex-column kb-map ">
          <div className="d-flex flex-row border-bottom">
            <h4 className="w-24 mb-0 text-left">Username</h4>
            <h4 className="w-24 mb-0 text-left pl-2">Knowledge base</h4>
            <h4 className="w-24 mb-0 text-left pl-2">Password</h4>
            <h4 className="w-24 mb-0 text-left pl-2">Comment</h4>
          </div>
          {this.state.allUsers.map((user, index) => (
            <div key={index} className="w-100 rounded pt-0 mt-0 pb-4 d-flex flex-row border-bottom">

              <input type="text" className="form-control bg-white border mt-1-4 w-25" placeholder="Enter username" value={user.user_name} readOnly="true" />

              <FormControl className="d-block ml-2 w-25">
                <InputLabel className="d-block w-100" htmlFor={`knowledgebases-${index}`}>No Knowledge base</InputLabel>
                <Select
                  multiple

                  value={Array.isArray(user.kb_list) ? user.kb_list : []}
                  style={{
                    maxHeight: 48 * 4.5 + 8,
                    width: "98%",
                  }}
                  input={<Input id={`knowledgebases-${index}`} />}
                  renderValue={(selected) => selected.join(', ')}
                  inputProps={{
                    name: 'index',
                    id: `knowledgebases-${index}`,
                    className: "select-kb w-100",
                    //readOnly:true
                  }}

                >
                  {user.kb_list && user.kb_list.map((name) => (
                    <MenuItem key={name} value={name} id={`knowledgebase-${index}`} className="d-flex w-100 p-0">
                      <Checkbox checked />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}

                </Select>
              </FormControl>

              <input type="password" className="form-control border mt-1-4 ml-0 w-25" placeholder="Enter password" value={user.password} readOnly="true" />


              <textarea className="form-control mt-1-4 ml-2 w-25" id="comment" value={user.comment} onChange={(e) => this.commentsChange(e, index)} readOnly="true"></textarea>
              <button className="btn float-right delete" onClick={() => this.show(user.user_name)}><i className="fa fa-trash"></i></button>
            </div>



          ))}

          <DialogContainer
            id="speed-boost"
            visible={visible}
            title="Delete User"
            onHide={this.hide}
            aria-describedby="speed-boost-description"
            modal
            actions={actions}
          >
            <p id="speed-boost-description" className="md-color--secondary-text">
              {`Do you want to delete user with username ' ${this.state.userNameToDelete} ' ?`}
            </p>
          </DialogContainer>

          {this.state.SocialData.map((Social, idx) => (
            <div key={idx} className="w-100 rounded pt-0 mt-0 pb-4 d-flex flex-row border-bottom">

              <input type="text" className="form-control bg-white border mt-1-4 w-25" placeholder="Enter username" value={Social.user_name} onChange={(e) => { this.usernameChange(e, idx) }} /><span className="mt-1-4 text-danger">*</span>

              <FormControl className="d-block ml-2 w-25">
                <InputLabel className="d-block w-100" htmlFor={`knowledgebase-${idx}`}>Choose Knowledge Base</InputLabel>
                <Select
                  multiple
                  value={Social.kb_list}
                  onChange={(e) => this.kbSelect(e, idx)}
                  inputProps={{
                    name: 'index',
                    id: `knowledgebase-${idx}`,
                    className: "select-kb w-100"
                  }}
                >
                  <MenuItem value="" disabled id="kb-empty" className="d-block w-100">Select KB</MenuItem>
                  {
                    knowledgeBaseNames.map((name) => (
                      <MenuItem value={name} id={`knowledgebase-${idx}`} className="d-block w-100">{name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

              <input type="password" className="form-control border mt-1-4 ml-0 w-25" placeholder="Enter password" value={Social.password} onChange={(e) => { this.passwordChange(e, idx) }} /><span className="mt-1-4 text-danger">*</span>


              <textarea className="form-control mt-1-4 ml-2 w-25" id="comment" value={Social.comment} onChange={(e) => this.commentsChange(e, idx)}></textarea>
              <button className="btn float-right delete" onClick={() => this.handleRemoveSocial(idx)}><i className="fa fa-trash"></i></button>
            </div>



          ))}

        </div>
        {this.state.SocialData.length > 0 && (this.state.SocialData[this.state.SocialData.length - 1].user_name.length === 0 || this.state.SocialData[this.state.SocialData.length - 1].password.length === 0) ?
          <div className="text text-info">Please enter username &amp; password before creating new user</div> : ""
        }
        <button
          className="btn border bg-light mt-3 add-user"
          type="button"
          onClick={this.handleAddSocial}
          disabled={this.state.SocialData.length > 0 && (this.state.SocialData[this.state.SocialData.length - 1].user_name.length === 0 || this.state.SocialData[this.state.SocialData.length - 1].password.length === 0)}
        >
          <i className="fa fa-plus"></i> Add user
        </button>

        {this.state.SocialData.length > 0 && <button className="btn btn-primary border  float-right mt-3" onClick={this.submitData} disabled={this.state.SocialData[0].user_name.length === 0 || this.state.SocialData[0].password.length === 0 || (this.state.SocialData.length > 0 && (this.state.SocialData[this.state.SocialData.length - 1].user_name.length === 0 || this.state.SocialData[this.state.SocialData.length - 1].password.length === 0))}>Submit</button>}



        <Snackbar
          id="example-snackbar"
          toasts={this.state.toasts}
          autohide={this.state.autohide}
          onDismiss={this.dismissToast}
        />
      </div>
    )
  }
}

export default KnowledgeBaseMap;


