export const BEING_DISCUSSED = {
                                  "type": "AdaptiveCard",
                                  "body": [
                                      {
                                          "type": "Container",
                                          "items": [
                                              {
                                                  "type": "TextBlock",
                                                  "size": "Large",
                                                  "weight": "Bolder",
                                                  "text": "Being Discussed"
                                              }
                                          ]
                                      }
                                  ],
                                  "actions": [

                                     {
                                          "type": "Action.Submit",
                                          "title": "Commercial Property"
                                        },
                                        {
                                          "type": "Action.Submit",
                                          "title": "Private Investors"
                                        },
                                        {
                                          "type": "Action.Submit",
                                          "title": "Real Estate Investment Trusts"
                                        },
                                        {
                                          "type": "Action.Submit",
                                          "title": "Open-ended Property Funds"
                                        }
                                  ],
                                  "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                                  "version": "1.0"
                              };

 export const TRENDING_QUESTION = {
                                 "type": "AdaptiveCard",
                                 "body": [
                                     {
                                         "type": "Container",
                                         "items": [
                                             {
                                                 "type": "TextBlock",
                                                 "size": "Large",
                                                 "weight": "Bolder",
                                                 "text": "Trending Questions"
                                             },
                                             {
                                                 "type": "ColumnSet",
                                                 "columns": [
                                                     {
                                                         "type": "Column",
                                                         "items": [
                                                             {
                                                                 "type": "TextBlock",
                                                                 "spacing": "None",
                                                                 "text": "Created {{DATE(2018-01-18T06:08:39Z,SHORT)}}",
                                                                 "isSubtle": true,
                                                                 "wrap": true
                                                             }
                                                         ],
                                                         "width": "stretch"
                                                     }
                                                 ]
                                             }
                                         ]
                                     },
                                     {
                                         "type": "Container",
                                         "separator": "true",
                                         "style": "emphasis",

                                         "items": [
                                             {
                                                 "type": "TextBlock",
                                                 "size": "Medium",
                                                 "weight": "Bolder",
                                                 "text": "How much is the workforce of glasgow?",
                                                 "color": "accent"

                                             },
                                             {
                                                 "type": "TextBlock",
                                                 "text": "Arnold Clark Group Year founded 1954 Based in Glasgow Industry Motor Sir Arnold Clark opened his first car showroom on Glasgows Park Road in 1954 and the company now stands as the largest privatelyowned business in Scotland with more than 8000 employees, 200 dealerships and around 2 million customers across the UK. The company reported record sales figures in 2015 with its turnover rising by 11.9 to 3.3 billion.",
                                                 "wrap": true
                                             },
                                             {
                                                 "type": "FactSet",
                                                 "facts": [
                                                     {
                                                         "title": "URL:",
                                                         "value": "http://awesomepotato.com/pdfofpotato"
                                                     },
                                                     {
                                                         "title": "Source:",
                                                         "value": "Product Manual"
                                                     },
                                                     {
                                                         "title": "Published:",
                                                         "value": "{{DATE(2018-05-27T06:08:39Z,SHORT)}}"
                                                     }
                                                 ]
                                             }
                                         ]
                                     },
                                    {
                                         "type": "Container",
                                         "separator": "true",
                                         "style": "emphasis",

                                         "items": [
                                             {
                                                 "type": "TextBlock",
                                                 "size": "Medium",
                                                 "weight": "Bolder",
                                                 "text": "What are differences between reits and open ended property funds?",
                                                 "color": "accent"

                                             },
                                             {
                                                 "type": "TextBlock",
                                                 "text": "One key characteristic of REITs, however, is that they must distribute at least 90 of their taxable income to their investors. REITs may be worth considering as a relatively straightforward route into commercial property if you are comfortable with the associated risks. Openended property funds OPFsOpenended funds are a type of coowned fund that can continue to issue or redeem i.e. cancel shares according to investor appetite. While REITs are closedended meaning they only issue a stipulated number of shares the pot of money an OPF has to invest in property will continue growing as more investors buy into the fund. Equally, it can diminish if investors withdraw cash from the fund.",
                                                 "wrap": true
                                             },
                                             {
                                                 "type": "FactSet",
                                                 "facts": [
                                                     {
                                                         "title": "URL:",
                                                         "value": "http://awesomepotato.com/pdfofpotato"
                                                     },
                                                     {
                                                         "title": "Source:",
                                                         "value": "Bloomberg"
                                                     },
                                                     {
                                                         "title": "Published Date:",
                                                         "value": "{{DATE(2018-09-03T06:08:39Z,SHORT)}}"
                                                     }
                                                 ]
                                             }
                                         ]
                                     }
                                 ],
                                 "actions": [

                                     {
                                         "type": "Action.OpenUrl",
                                         "title": "View",
                                         "url": "http://delvmplconceptnet:8585/curiosum"
                                     }
                                 ],
                                 "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
                                 "version": "1.0"
                             };