import React, { Component } from 'react';



class ValidatorDetail extends Component {
    
    render() {
        console.log("this.props",this.props);
        const validatorProp = this.props.details.qna_response ? this.props.details.qna_response[0] : {};
    
    return (
    <div className="clearfix pt-4 mt-4" >
        <table className="table table-bordered rounded radius-lg ">       
    <tbody>   
         
            <tr><td><strong>Answer Entity</strong></td><td>{validatorProp.answerEntity && validatorProp.answerEntity.length>0 ? validatorProp.answerEntity.join(", "): ""}</td></tr>
            <tr><td><strong>Expected Entity</strong></td><td>{validatorProp.expectedEntity && validatorProp.expectedEntity.length>0 ? validatorProp.expectedEntity.join(", ") : ""}</td></tr>
          
     
    </tbody>
    </table>         
  </div>
        );
      }
    }
    export default ValidatorDetail;
