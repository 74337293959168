import React from "react";


export default function KaasLoader({loaded}){

return (
 <div className={`${loaded ? 'invisible': 'visible'} zoom-in-out-box`} style={{backgroundImage: `url('./image/logo.png')`}}>
     
 </div>
);
}
