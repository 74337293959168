import React, { Component } from 'react';

//import pslogo from '../../images/logo1.JPG';
import pslogo from '../../images/PS_Logo_RGB.svg';
import kaasLogo from '../../images/kaas_logo.JPG';
import '../../App.css';
import * as apiConstants from '../../service-api-constants.js';
//import Loader from 'react-loader';
import Loader from '../../components/loader/loader'
import axios from 'axios';
import Cookies from 'js-cookie';
var jwt = require('jsonwebtoken');
var jwkToPem = require('jwk-to-pem');

class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userName : "",
      Password : "",
	  feedbackType:'comments',
	  comment:'',
	  file:null,
	  name:'',
	  email:'',
	  emailError:false,
	   submitted:false,
	   loaded:true,
	   feedbackResponse:'',
     feedbackStatus:false,
     ssoLogin:false,
     register:false,
     loggedIn:true, adminLoggedIn: false,
     emailId:"",
     registerUsername:"",
     registerError:false,
     emailValid:true,
     errorMsg:"",
     reason:"",
     otherReason:"",
     registerLoading:false,
     afterRegister:false     
    }
  }

  componentDidMount(){
    this.checkToken(true)
  }

  ssoLoginClick = () => {
    this.setState({ssoLogin:true})
    this.checkToken(false);
  }

  resetAllValues = () => {
    this.setState({emailId:"",
    registerUsername:"",
    registerError:false,
    emailValid:true,
    errorMsg:"",
    reason:"",
    otherReason:"",
    registerLoading:false,
    afterRegister:false,
    userName : "",
    Password : "",
    imageFile:null
  })
  }

  registerUser = (username,kb) => {
    this.setState({registerLoading:true});
    axios.post(apiConstants.REGISTER_USER,
      {
        "users": [
          {
            "user_email": this.state.emailId,
            "name": this.state.registerUsername,
            "comment": this.state.reason === "others" ? this.state.otherReason : this.state.reason
          }
        ]
      },
      {
        headers: {
          'Content-Type': "application/json",
          'x-api-key': this.props.apiKey
        }
      }
    ).then((response) => {
      //handle success
      //console.log(response);
      if (response.status === 200) {
        //console.log(response.data);
        
        if(response.data.errors===0){
          this.setState({loggedIn:false,register:false,afterRegister:true,registerLoading:false,registerError:false})
              
        }else{
          this.setState({registerError:true,errorMsg:response.data.error_message,registerLoading:false,loggedIn:false,register:false,afterRegister:false});
         }
        }
       this.setState({loggedIn:false})
      
      
    }).catch((error) => {
      console.log(error);
      this.setState({registerLoading:false,loggedIn:false});
    });   
  };

  registerNow = () => {
    this.setState({register:true,loggedIn:false,afterRegister:false});
    this.resetAllValues();
    this.props.setShowError(false)
  }

  loginNow = () => {
    this.setState({register:false,loggedIn:true,afterRegister:false});
    this.resetAllValues();
    this.props.setShowError(false)
  }

  reasonChange = (e) => {
    this.setState({reason:e.target.value});
  }
  
  otherReasonChange = (e) => {
    this.setState({otherReason:e.target.value});
  }

  emailIdChange = (e) => {
      this.setState({emailId:e.target.value});
      let emailValid = this.validateEmail(e.target.value)
      //console.log("emailValid",emailValid)
      if(emailValid){
        let name = e.target.value.split("@")
        if(name.length>0){
          this.setState({registerUsername:name[0]})
        }
        this.setState({emailValid:true})
        }else{
          this.setState({emailValid:false})
      }
      this.setState({registerError:false,errorMsg:""});
   };

  validateEmail = (email) => { 
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(email)){
        //Email valid. Procees to test if it's from the right domain (Second argument is to check that the string ENDS with this domain, and that it doesn't just contain it)
        if(email.indexOf("@publicissapient.com", email.length - "@publicissapient.com".length) !== -1){
            //VALID
            //console.log("VALID");
            return true
        }else{
          return false
        }
    }
}

  checkToken = (mount=false) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let id_token = params.id_token;
    console.log("id_token from url",id_token);    
    let oldToken = Cookies.get('id_token');
    if(oldToken === undefined && id_token && id_token.length>0){
      Cookies.set("id_token",id_token)
    }
    let token = Cookies.get('id_token');
    console.log("token from cookie", token)
    if (token===undefined) {      
      if(mount){        
        this.setState({ssoLogin:false})
      }else{
        window.location.href = `https://cognitive.autokaas.com/login`
      }
      
    }else {
      var decodedHeader = jwt.decode(token, { complete: true }).header;
      var ctx = this;
     axios.get(apiConstants.GET_DEX_KEYS).then(res => {
     /*   let res = {
          data:apiConstants.DEX_KEYS
        }*/
        var keySet = res.data.keys;
        var myJWK = keySet.filter(key => key.kid===decodedHeader.kid);
        if(myJWK.length === 0){
          console.log('invalid JWT');
          if(mount){
            this.setState({ssoLogin:false})
          }else{
            window.location.href = `https://cognitive.autokaas.com/login`
          }
          
        }
        else{
          var pem = jwkToPem(myJWK[0]);
          jwt.verify(token,pem,(err,decoded_token)=>{
            if(err){
              console.log(err);
              if(mount){
                this.setState({ssoLogin:false})
              }else{
                window.location.href = `https://cognitive.autokaas.com/login`
              }
            }
            else{
              if(mount){
                this.setState({ssoLogin:true})
                ctx.props.ssoLogin(decoded_token.email,true);
              }else{
              ctx.props.ssoLogin(decoded_token.email,true);
              }
            }
          });
        }
      });
    }
  }

  usernameChange = (e) => {this.setState({userName:e.target.value}); this.props.setShowError(false)};
  passwordChange = (e) => {this.setState({Password:e.target.value});this.props.setShowError(false)};
  keyPress = (e)=>{
	  if((e.key === "Enter" || e.keyCode === 13) && (this.state.userName.length>0 && this.state.Password.length>0)){this.props.logIn(this.state.userName,e.target.value)}
	  };
	  
	  feedbackChange = (e) => {
	   this.setState({feedbackType:e.currentTarget.value})
   };
   
   commentChange = (e) => {
	   this.setState({comment:e.currentTarget.value})
   };
   
    handleFile = e => {
    this.setState({file: e.target.files[0] });
  };
  
   nameChange = (e) => {
	    this.setState({name:e.target.value});
   };
  
   emailChange = (e) => {
	   //console.log("this.isEmail(e.target.value)",this.isEmail(e.target.value))
if(e.target.value.length > 0)	   {
	   if(this.isEmail(e.target.value)){
		  //console.log("valid url")
		  this.setState({emailError:false,email:e.target.value});
	  }else{
		  this.setState({emailError:true,email:e.target.value});  
	  }
}else{
	this.setState({emailError:false,email:e.target.value});
}
	  
   };
   isEmail = (str) => {
        //var pattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
     //   return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str); 
		return str.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      }
	  
	   submitFeedback = () => {
	   this.setState({submitted:true,feedbackResponse:'',feedbackStatus:false});
	   if(this.state.name.length>0 && this.state.email.length>0 && this.state.comment.length>0 && !this.state.emailError){
	   this.setState({loaded:false});
	   const bodyFormData = new FormData(); 
bodyFormData.set('feedbackType', this.state.feedbackType);
  bodyFormData.set('feedback', this.state.comment);
// bodyFormData.set('file', this.state.file !== null && this.state.file !== undefined && this.state.file.name ? this.state.file : 'None');   
  bodyFormData.set('name', this.state.name); 
  bodyFormData.set('email_id', this.state.email); 
  if(this.state.imageFile){bodyFormData.set('file', this.state.imageFile);}
  
    //console.log("bodyFormData",bodyFormData)
    axios.post(apiConstants.SUBMIT_FEEDBACK,
      bodyFormData,
     { headers: {'Content-Type': "multipart/form-data" ,
                  'x-api-key' : this.props.apiKey
    }}
      ).then((response) => {
        //handle success
        //console.log(response); 
		if(response.data.status){
		this.setState({feedbackResponse:'Feedback submitted Successfully!. We will get back to you soon.',feedbackStatus:response.data.status,submitted:false,loaded:true})
		}else{
			this.setState({feedbackResponse:'Error in submitting the feedback. Please try again',feedbackStatus:response.data.status,submitted:false,loaded:true})
		}
  }).catch((error) => {
    console.log(error);
    this.setState({loaded:true,submitted:false,feedbackStatus:false,feedbackResponse:error.toString()});
  });
	   }

  };
  
  feedbackClick = () => {
	  this.setState({feedbackType:'comments',
	  submitted:false,
	  comment:'',
	  file:null,
	  name:'',
	  email:'',
	  emailError:false,feedbackResponse:'',feedbackStatus:false})
  };

  setImageFile = (e) => {
    this.setState({imageFile: e.target.files[0]})
   }
    
    render() {
    const {modalLogin} = this.props;
    const {emailId,
    registerUsername,
   
    emailValid,
    
    reason,
    otherReason
    } = this.state
    return (
  
       <div className="container-fluid pl-0 pr-0">
      {!this.state.ssoLogin && <div>
      <header className={`${modalLogin ? `d-none` : `border-bottom d-flex flex-row w-100`}`}>

           <a className="w-24 d-inline-block float-left pb-1 pl-0"><img src={pslogo} className="App-logo float-left mt-3 pr-1" alt="Curiosum" /> 
            <p className="font-weight-bold pt-3 mb-0 d-block">
                <img src='image/logo_h.png' className="kaas-logo float-left pr-1" alt="Curiosum" /> <br/> <span className="small-text pl-1 d-inline-block mt-1  w-75 text-left">Cognitive Search</span></p>
           
          
                
               </a>

            <div className="w-52 pl-3 pr-5">
          <ul className="nav pt-3 ">
  
  <li className="nav-item dropdown" style={{"width":"auto"}} data-toggle="tooltip" title="Coming soon!">
    <a className="nav-link dropdown-toggle pl-0 pr-5 d-inline-block disabled" data-toggle="dropdown">Why Curiosum?</a>
    <div className="dropdown-menu">
    <a className="dropdown-item font-weight-bold" href="#">Features</a>
      <a className="dropdown-item" href="#">Project Management</a>
      <a className="dropdown-item" href="#">Integrations</a>
      <a className="dropdown-item" href="#">Actions</a>
      <div className="dropdown-divider"></div>
      <a className="dropdown-item" href="#">Case Studies</a>
    </div>
  </li>
  <li className="nav-item pl-5 pr-5" style={{"width":"auto"}} data-toggle="tooltip" title="Coming soon!">
    <a className="nav-link dropdown-toggle disabled" data-toggle="dropdown">Enterprise</a>
    <div className="dropdown-menu">
      <a className="dropdown-item" href="#">Link 1</a>
      <a className="dropdown-item" href="#">Link 2</a>
      <a className="dropdown-item" href="#">Link 3</a>
    </div>
  </li>
  <li className="nav-item pl-5 pr-5" style={{"width":"auto"}} data-toggle="tooltip" title="Coming soon!">
    <a className="nav-link dropdown-toggle disabled" data-toggle="dropdown">Explore</a>
    <div className="dropdown-menu">
      <a className="dropdown-item" href="#">Link 1</a>
      <a className="dropdown-item" href="#">Link 2</a>
      <a className="dropdown-item" href="#">Link 3</a>
    </div>
  </li>
  
</ul>
            </div>
<div className="w-24">
<div className="pl-5 pt-3 pr-5 float-right"  >
    <a className="btn btn-warning" href="#" data-toggle="modal" data-target="#myModal" onClick={this.feedbackClick}>Feedback</a>
  </div>
</div>
          </header>
          
 
          <div className="modal" id="myModal" data-backdrop="static" data-keyboard="false">
  <div className="modal-dialog">
    <div className="modal-content p-3">

     
      <div className="modal-header d-flex flex-column pb-1">
        <h3 className="modal-title">Feedback Form</h3>
        <p>We would love to hear your thoughts, concerns or problems with anything so we can improve</p>
      </div>

     
      <div className="modal-body">
        <form className={`${this.state.submitted ? 'was-validated' : 'needs-validation'}`}  novalidate>
		
		 <div className="form-group pt-3">
    <label for="email"  className="font-weight-bold">Feedback Type</label><br/>
    <div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input" name="feedbackType" value="comments" checked={this.state.feedbackType === 'comments'} 
                                       onChange={this.feedbackChange} />Comments
  </label>
</div>
<div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input" name="feedbackType"  value="bug_reports" checked={this.state.feedbackType === 'bug_reports'} onChange={this.feedbackChange} />Bug Reports
  </label>
</div>
<div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input" name="feedbackType"  value="questions" checked={this.state.feedbackType === 'questions'} onChange={this.feedbackChange} />Questions
  </label>
</div>
  </div>
  
   
  <div className="form-group pt-3">
  <label for="comment" className="font-weight-bold">Describe Feedback: <span className="text-danger">*</span></label>
  <textarea className="form-control" rows="5" id="feedback" value={this.state.comment} onChange={this.commentChange} required></textarea>
</div>



	 <div className="form-group pt-3">
    <label for="name"  className="font-weight-bold">Name: <span className="text-danger">*</span></label>
    <input type="text" className="form-control" placeholder="Enter your name" id="name" value={this.state.name} onChange={this.nameChange} required/>
  </div>
		
		 <div className="form-group pt-3">
    <label for="email"  className="font-weight-bold">Email address: <span className="text-danger">*</span></label>
    <input type="email" className="form-control" placeholder="Enter email" id="email" value={this.state.email} onChange={this.emailChange} required/>
	{this.state.emailError ? <span className="text-danger pl-2 mt-2">Please enter a valid Email id</span> : ""}
  </div>

  <div className="custom-file mt-3">
    <input type="file" className="custom-file-input" id="validatedCustomFile"  onChange={this.setImageFile} accept="image/*"/>
    <label className="custom-file-label" for="validatedCustomFile">{this.state.imageFile && this.state.imageFile.name ? this.state.imageFile.name : `Choose file...`}</label>
    <div className="invalid-feedback">invalid file</div>
    </div>
  
   <div className="form-group pt-3 text-center">
   <button type="button" className="btn btn-outline-primary" disabled={!this.state.loaded} onClick={this.submitFeedback}>Submit Feedback</button>
   <button type="button" className="btn btn-outline-primary ml-2" disabled={!this.state.loaded} data-dismiss="modal">Close</button>
   </div>
    
	{this.state.feedbackResponse.length>0 ? this.state.feedbackStatus ? <div className="p-1 text-success">{this.state.feedbackResponse}</div> : <div className="p-1 text-danger">{this.state.feedbackResponse}</div> : ""}	
          </form>
      </div>
      

    </div>
  </div>
</div>    
<Loader loaded={this.state.loaded}></Loader>
<div className={`${modalLogin ? `mt-0 pt-0` : `mt-5 pt-5`} d-flex flex-row w-100`}>
<div className={`${modalLogin ? `d-none` : `w-24 pt-5`}`}></div>
<div className={`${modalLogin ? `w-100` : `w-52 pl-3 pr-3 pt-5`}`}>

<div className=" d-flex flex-row">
<div className={`${modalLogin ? `d-none` : `w-52 pt-0 mt-5 mr-2`}`}>
<h1 className="text-dark mb-3">Next Generation<br/>Cognitive Intelligence Platform 
</h1>
<p className="lead-mktg pr-2">
Work smarter, make better strategic decisions, and gain a competitive edge with groundbreaking AI search technology.</p>
<p className="lead-mktg pr-2">Gain dramatic time savings with one search across all content sets and review results on one screen. Stay alerted to new disclosures. Find what others miss.</p>
<p className="lead-mktg pr-2">Advanced Deep Learning algorithms allow you to search across millions of documents with a few clicks, dramatically reducing your time to insight. </p>
        
</div>
{this.state.loggedIn && <div className={`${modalLogin ? `w-100 p-5 m-0 ` : `w-60 p-4 m-5`} border rounded justify-content-center  align-center`}>
<h3>Log in to KaaS Cognitive Search</h3>
          <form>
  <div className="form-group pt-3">
    <label htmlFor="email">Username:</label>
    <input type="email" className="form-control" id="email" autoComplete="off" placeholder="Username" value={this.state.userName} onChange={this.usernameChange} />
  </div>
  <div className="form-group pt-2">
    <label htmlFor="pwd">Password:</label>
    <input type="password" className="form-control" id="pwd" placeholder="Enter Password" value={this.state.Password} onChange={this.passwordChange} onKeyPress={this.keyPress} />
  </div>
  { this.props.showError && <div className="text text-danger pb-2">
      UserName or Password is incorrect.
  </div>
}
  
  <button type="button" disabled={this.state.userName.length===0 || this.state.Password.length===0} onClick={()=>this.props.logIn(this.state.userName,this.state.Password)} className="btn btn-light border rounded-pill font-weight-bold pl-3 pr-3 mt-4">Login</button>
  <button type="button" onClick={this.ssoLoginClick} data-toggle="tooltip" title="Coming soon!" className="btn btn-light border rounded-pill font-weight-bold pl-3 pr-3 mt-4 ml-2">SSO Login</button>
  <div className="mt-3"><a style={{cursor:"pointer",textDecoration:"underline"}} onClick={this.registerNow}>Register now</a> to request access.</div>
</form>
</div>}

{this.state.register && <div className={`${modalLogin ? `w-100 p-5 m-0 ` : `w-60 p-4 m-5`} border rounded justify-content-center  align-center`}>
<h3>Register</h3>
          <form>
  <div className="form-group pt-3">
    <label htmlFor="email">email (use publicis sapient email id)*</label>
    <input type="email" className="form-control" id="email" autoComplete="off" placeholder="xyz@publicissapient.com" value={this.state.emailId} onChange={this.emailIdChange} />
  </div>
  <div className="form-group pt-2">
    <label htmlFor="reason">reason*</label><br/>
    <select value={this.state.reason} onChange={this.reasonChange} className="w-100 p-2 form-control">
    <option value="">Select an option&hellip;</option>
    <option value="client">for client demo</option>
    <option value="internal">for internal demo</option>
    <option value="others">others</option>
</select>

{this.state.reason === "others" &&  <div className="form-group pt-3"><span>Other reason*</span>
        <input type="text" className="w-100 p-2 form-control" onChange={this.otherReasonChange} value={this.state.otherReason} placeholder="enter the reason" /></div>}
  </div>
  { this.props.showError ? this.state.userName.length===0 || this.state.Password.length===0 ? <div className="text text-danger pb-2">
      Please enter username &amp; password
  </div> : <div className="text text-danger pb-2">
      UserName or Password is incorrect.
  </div>: <div></div>
}

{!this.state.emailValid && <p className="text text-danger">Email is not valid. Please use Publicis Sapient email id</p>}
       
  
  <button type="button" disabled={registerUsername.length === 0 || emailId.length === 0 || reason.length === 0 || (reason === "others" && otherReason.length === 0) || !emailValid} onClick={this.registerUser} className="btn btn-light border rounded-pill font-weight-bold pl-3 pr-3 mt-2"> 
  <div className={`spinner-border spinner-border-sm ${this.state.registerLoading ? 'd-inline-block' : 'd-none'}`} role="status">  <span className="sr-only">Loading...</span>
</div> Register</button>
  <div className="mt-3"><a style={{cursor:"pointer",textDecoration:"underline"}} onClick={this.loginNow}>Login now</a></div>
</form>
</div>}

{this.state.registerError && <div className={`${modalLogin ? `w-100 p-5 m-0 ` : `w-50 p-4 m-5`} border rounded justify-content-center  align-self-center`}>
  <p  className="text text-danger">
       
      {this.state.errorMsg}
    
     </p>
     <span>
       Have access details? <span style={{cursor:"pointer",textDecoration: "underline", fontWeight:"bold"}} onClick={this.loginNow}>Login Now</span>.
     </span>
     </div>}


{this.state.afterRegister && <div className={`${modalLogin ? `w-100 p-5 m-0 ` : `w-50 p-4 m-5`} border rounded justify-content-center  align-self-center`}>
       <p>Thank you for registering. Please check your email for login details.</p>
       <span>
       Have access details? <span style={{cursor:"pointer",textDecoration: "underline", fontWeight:"bold"}} onClick={this.loginNow}>Login Now</span>.
     </span>
     </div>}

</div>


</div>
<div className={`${modalLogin ? `d-none` : `w-24 pt-5`}`}></div>
     </div> 


    </div>}
      </div>  
       
        );
      }
    }
    Login.defaultProps = {
      modalLogin:false
    }
    export default Login;
    
