import React, { useEffect } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios';
import * as apiConstants from '../../service-api-constants.js';

var suggestions = [];



function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.question, query);
  const parts = parse(suggestion.question, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? "bold" : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function getSuggestions(value,suggest) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
//console.log("suggestions",suggest)
  return inputLength < 3
    ? []
    : suggest
}

function getSuggestionValue(suggestion) {
  return suggestion.question;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function Autocomplete(props) {
    //console.log("props",props);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    single: '',
    popper: '',
    question: '',
    error: ''
  });

useEffect(() => {
  setState({
    ...state,
    question: props.searchText,
  });
}, [props.searchText]);

  const [stateSuggestions, setSuggestions] = React.useState([]);
  const getSuggestion = (value) => {
    //console.log("getSuggestion",value);   
    suggestions = [];   
                   suggestions = value;
                  // setSuggestions(value);
                   //setState({stateSuggestions:response.data});               
   };
 //getSuggestion(props.suggestions);
  //this.setSuggestions(props.suggestions);

  

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value,props.suggestions));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleChange = name => (event, { newValue }) => {
      suggestions=[];
      const value = newValue;
     // const innertext = event.target.innerText;
   // let text = newValue.split(" ");
   //let textLen = text.length-1;
   // console.log("text",text);
    //console.log("question value",value);
   // console.log("innerhtml",event.target.innerHTML);
   // if(value.length > 2){
   //    props.youMayAsk(true,value);
       
   // } 

if(props.homePageClicked){  
    
if(value.length === 1){
setState({
        ...state,
        question: value,
      });
props.setSearchText(value);
}
else{
setState({
        ...state,
        question: "",
      });
props.setSearchText("");

}
}else{
props.setSearchText(value);
    setState({
        ...state,
        question: value,
      });

}


    
  };
if(props.homePageClicked){
//console.log("outside fn homePageClicked");

 handleChange('single');

}

  const renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
  
    return (
    
      <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        }
        
      }}
      {...other}
     
    />  
    );
  }
  
 {/*
  <input type="text" className="form-control rounded shadow" value={props.searchText} 
      onChange={(e)=>{props.handleChange(e);handleChange('single')} }
      onKeyPress = {(e) => props.onKeyPress(e)} 
      placeholder="Enter what do you want to know about" aria-label="Input group example" aria-describedby="btnGroupAddon" />
  */}
  

  const autosuggestProps = {
    renderInputComponent,
    suggestions: props.suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          classes,
          id: 'react-autosuggest-simple',
          placeholder: 'Enter what do you want to know about',
          value: props.homePageClicked ? "" : state.question,
          onChange: handleChange('single'),
          onKeyPress: props.onKeyPress
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
          </div>
  );
}

export default Autocomplete;