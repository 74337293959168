import React, { Component } from 'react';
import '../../App.css';
import './searchresultdetail.css';
import changeCase from "change-case";


class Searchresultdetail extends Component {
    constructor(props) {
        super(props);
 this.state = {
            pdfUrl:'',
			showMore:false
        };
    }

    componentDidMount(){
        if(this.props.details.s3_content_url){
        let urlArray = this.props.details.s3_content_url.split("/");
//console.log("urlArray",urlArray);
let bucket = urlArray[2];
//console.log("bucket",bucket);
let key = `${urlArray[3]}/${urlArray[4]}`;
//console.log("key",key);
this.setState({pdfUrl:`https://${bucket}.s3.us-east-2.amazonaws.com/${key}`})
        }
    }
    pdfClick = (evt) => {const text = this.props.details.highlight.length > 0 ? this.props.details.highlight[0] : this.props.details.validatedPassage; const fileurl = this.props.details.s3_content_url; /*console.log("text.fileUrl",text,fileurl);*/this.props.onPdfClick(text,fileurl)};
    toCamelCase = function(string) {
        //var string="A'B B C DE";
        string = string.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
            return match.charAt(match.length-1).toUpperCase();
        });
        return string.charAt(0).toLowerCase() + string.substring(1);
        //break string into array for(var i = 1; i < string.length; i++){ var testCase = string[i][0]; if(testCase !== testCase.toUpperCase()){ string = string[i].replace(string[i].charAt(0), string[i].charAt(0).toUpperCase()); } } 
    }
	showMore = () => {
      this.setState({showMore:!this.state.showMore})
    };
	
    render() {
        let obj = this.props.details;
       

      //  let passage = this.props.details.passage.split(" ").join("");
       // passage = passage.replace('\"', "")
      //  passage.replace("<em>","<strong>");
      //  passage.replace("</em>","</strong>");
      
    //}
  
       // console.log("text",text);
        
        return (
            ((obj.title && obj.title.length > 0) || (obj.title_unranked && obj.title_unranked.length > 0) || (obj.fileUrl && obj.fileUrl.length > 0) ||  (obj.fileUrl_unranked && obj.fileUrl_unranked.length > 0) || (obj.machineComprehensionPassage && obj.machineComprehensionPassage.length > 0) ||  (obj.full_content && obj.full_content.length > 0) || (obj.full_content_unranked && obj.full_content_unranked.length > 0)) ? <div className="pt-2 pr-3 pl-3 pb-2 d-flex flex-row clearfix radius-lg" id="searchdetail">
                <div className="col-sm-12 text-left p-0">
                    <div className="clearfix">
                    <h5 className="d-inline-block w-100 float-left mb-0">
                        {this.props.fromChatBot || this.props.fromTree 
                        ? 
                        <a className="pdflink">{changeCase.titleCase(obj.title ? obj.title : obj.title_unranked)} {obj.heading ? `: ${obj.heading}` : ""}</a> 
                        : 
                        obj.fileUrl || obj.s3_content_url//this.state.pdfUrl.length > 0 
                         ? 
                         <a className="pdflink" href={obj.fileUrl && obj.fileUrl.length>0 ? obj.fileUrl : obj.s3_content_url && obj.s3_content_url.length>0 ? obj.s3_content_url : "#"/*this.state.pdfUrl*/} target="blank">{changeCase.titleCase(obj.title ? obj.title : obj.title_unranked)} {obj.heading ? `: ${obj.heading}` : ""}</a> 
                         : 
                         <a className="pdflink">{changeCase.titleCase(obj.title ? obj.title : obj.title_unranked)} {obj.heading ? `: ${obj.heading}` : ""}</a>
                         }
						
						
						</h5>
                        {/*<div className="dropdown d-inline-block w-25 float-right text-right">
                            {this.props.fromChatBot || this.props.fromTree ? "" : <a className="btn bg-none p-0 disabled" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Read Further <i className="fa fa-angle-down"></i>
                            </a>}

                            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <a className="dropdown-item" href="#">Action</a>
                                <a className="dropdown-item" href="#">Another action</a>
                                <a className="dropdown-item" href="#">Something else here</a>
                            </div>
                        </div>*/}
                        
                    </div>
                    {/*this.props.fromChatBot ? "" : <a className="pdflink w-100" target="blank" href={obj.fileUrl ? obj.fileUrl : obj.fileUrl_unranked} style={{"overflowWrap":"break-word"}} /*onClick={this.pdfClick}*//*>{obj.fileUrl ? obj.fileUrl : obj.fileUrl_unranked}</a>*/}
                      
         {/*<strong>Confidence Score : {obj.flag > 1 ? <i className="text-success fa fa-star"></i> : <i className="text-warning fa fa-star-half-o"></i>}</strong>*/}
             {obj.showMore && obj.showLess && obj.showLess.length>0 ? <ul className="ml-0 pl-0 text-left mb-0">
            <li className="pt-1 content-overlap">
            <span dangerouslySetInnerHTML={{__html: this.state.showMore ? obj.showMore : obj.showLess }}></span>
            {obj.showMore !== obj.showLess.slice(0,-3) && <a onClick={this.showMore} className="pdflink"> {this.state.showMore ? 'show less' : 'show more'}</a>}
            </li>
          
                       
                    </ul>: ""}

                    {obj.full_content && this.props.fromTree && obj.full_content.length>0 ? <ul className="ml-2 pl-2 text-left">
                        
                        <li className="pt-4" dangerouslySetInnerHTML={{__html: obj.full_content}}></li>
                   
                </ul>: ""}

                {obj.full_content_unranked && this.props.fromTree && obj.full_content_unranked.length>0 ? <ul className="ml-2 pl-2 text-left">
                        
                        <li className="pt-4" dangerouslySetInnerHTML={{__html: obj.full_content_unranked}}></li>
                   
                </ul>: ""}

                 
                 {this.props.fromChatBot ? "" : <div className="text-left p-0 w-100 border-top d-none">

                    {obj.tags && obj.tags.split(",").map((tag, index) => (
                        <a disabled={true} key={index} className="border-0 d-inline mr-3 mt-2 pdflink" style={{"color":"color: #1a0dab"}}>{tag}</a>
                    ))}
                    {obj.tags_unranked && this.props.fromTree && obj.tags_unranked.split(",").map((tag, index) => (
                        <a disabled={true} key={index} className="border-0 d-inline mr-3 mt-2 pdflink" style={{"color":"color: #1a0dab"}}>{tag}</a>
                    ))}
                </div>}


                </div>
                

                

            </div> : ""
        );
    }
}

export default Searchresultdetail;
