import React, { Component } from 'react';
import { Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import '../../App.css';
import { SelectionControlGroup, SVGIcon,
    Checkbox,
    SelectionControl,Slider,Button,FontIcon, ListItem, MenuButton } from 'react-md';
import * as apiConstants from '../../service-api-constants.js';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import axios from 'axios';
//import Loader from '../../components/loader/loader'
import Loader from 'react-loader';

const {confirm} = Modal;

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docIngestion:'sections',
            ingestionPolicy:'None',
			customExtraction:'',
            preciseChecked:true,
			urlError:false,
			jsonData:{},
testDataSource:'url',
submitted:false,
kbType:'',
fileUrl:'',
file:null,
fileUrlError:false,
loaded:true,
customUrl:'',
models:[],
selectedModels:[""],
modelToDelete:'',
exampleCount:0
        };
    }

componentDidMount(){
		if(this.props.customExtractionUrl.length>0){
			this.setState({customUrl:this.props.customExtractionUrl});
		}
		if(this.props.docIngestion.length>0){
			this.setState({docIngestion:this.props.docIngestion});
    }
    this.getModels();
    this.getExampleCount();
  }

  componentDidUpdate(prevProps){
    //console.log("prevProps",prevProps);
    if(prevProps.trainingStatus.status === 'PENDING' && this.props.trainingStatus.status === 'SUCCESS'){
      this.getModels();
    }
    if(prevProps.trainingStatus.status === 'PENDING' && this.props.trainingStatus.status === 'FAILURE'){
      Modal.error({
        content: `Need more examples to train`,
      });
    }
    if(this.props.knowledgeBase !== prevProps.knowledgeBase){
      this.getModels();
      this.getExampleCount();
    }

 }

  showConfirm = () => {
    confirm({
      title: 'Training Info',
      centered:true,
      icon: <ExclamationCircleOutlined />,
      content: <span>knowledgeBase : {this.props.knowledgeBase} <br/> Number of examples : {this.state.exampleCount}</span>,
     okText:"Confirm",
      onOk: () => {
        this.props.trainModel();
      },
      onCancel: () => {
        
      },
    });
  }

  showDelete = (modelName) => {
    confirm({
      title: 'Are you sure delete this Model?',
      centered:true,
      icon: <ExclamationCircleOutlined />,
      content: <span>Model name : {modelName}</span>,
     okText:"Delete",
      onOk: () => {
        this.deleteModel(modelName);
      },
      onCancel: () => {
        
      },
    });
  }
  
  getModels = () => {
    axios.post(apiConstants.GET_MODELS,
      {"kb_name" : this.props.knowledgeBase},
     { headers: {'Content-Type': "application/json" }}
      ).then((response) => {
          //handle success
          //console.log(response);
        if(Array.isArray(response.data)){
          const allModels = response.data.map((model)=>{
            return model.model_name
          })
           this.setState({models:response.data,selectedModels:allModels});
         }else{
          this.setState({models:[]});
         }
      })
      .catch((error) => {
          //console.log("error",error);
         
          this.setState({models:[]})
      });
  };

  getExampleCount = () => {
    axios.post(apiConstants.GET_EXAMPLE_COUNT,
      {"kb_name" : this.props.knowledgeBase},
     { headers: {'Content-Type': "application/json" }}
      )
      .then((response) => {
          //handle success
          //console.log(response);
          if(response.data.exampleCount){
             this.setState({exampleCount:response.data.exampleCount})
          }       
      })
      .catch((error) => {
          console.log("error",error);
          this.setState({error:error.toString(),loaded:true,knowledgeBaseNames:[],currentUserIndex:""});
      });
  };  

  deleteModel = (modelName) => {
    this.setState({modelToDelete:modelName})
    axios.post(apiConstants.DELETE_MODEL,
      {"kb_name" : this.props.knowledgeBase,"model_name": modelName},
     { headers: {'Content-Type': "application/json" }}
      ).then((response) => {
          //handle success
          //console.log(response);
          if(response.data.status && response.data.status === 'Deleted'){
            this.setState({modelToDelete:""})
            this.getModels();
            Modal.success({
              content: `Model - '${modelName}' is deleted successfully`,
            });
          }else{
            this.setState({modelToDelete:""})
          }
        
      })
      .catch((error) => {
          console.log("error",error);
          this.setState({modelToDelete:""})
      });
  };

    ingestionPolicyChange = (value, e) => {
    this.setState({ingestionPolicy:value})
   };
   documentIngestionChange = (value, e) => {
	   this.setState({docIngestion:value});   
   };
   
   clear = () => {
	   this.setState({testDataSource:'url',submitted:false,urlError:false,file:null,kbType:'',fileUrl:'',fileUrlError:false});
	   this.props.setCustomExtractionUrl("");
   };
   
   isURL = (str) => {
        //var pattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
        return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
      }
   
   textBlur = () => {
	   if(this.props.customExtractionUrl.length > 0){
	  if(this.isURL(this.props.customExtractionUrl)){
		  //console.log("valid url")
		  this.setState({urlError:false});
	  }else{
		  this.setState({urlError:true});  
	  }
	   }
   }
   
   customExtractionChange = (e) => {	
 if(e.target.value.length > 0){
	  if(this.isURL(e.target.value)){
		  //console.log("valid url")
		  this.setState({urlError:false,customUrl:e.target.value});
	  }else{
		  this.setState({urlError:true,customUrl:e.target.value});  
	  }
	   }else{   
	    this.setState({urlError:false,customUrl:e.target.value});	   
	   }	   
   };
   
   mcChange = (value, e) => {
    this.props.setMachineComprehension(value);
   };
   
   preciseChange = (checked,event) => {
   
        
            this.props.showPreciseAnswer(checked);
       
   
   };
   
   execute = () => {
	   this.setState({submitted:true,jsonData:{}});
	   if(this.props.customExtractionUrl.length > 0 && (this.state.fileUrl.trim().length>0 || (this.state.file !== null && this.state.file !== undefined && this.state.file.name)) && this.state.kbType.length>0 && !this.state.urlError && !this.state.fileUrlError){
	   this.setState({loaded:false});
	   const bodyFormData = new FormData(); 
bodyFormData.set('customExtractionEndpoint', this.props.customExtractionUrl);
  bodyFormData.set('url', this.state.testDataSource === 'url' ? this.state.fileUrl.trim() : 'None');
 bodyFormData.set('knowledgeType', this.state.kbType === 'RAW' ? 'raw' : this.state.kbType.toLowerCase());   
  bodyFormData.set('file', this.state.testDataSource === 'file' ? this.state.file : 'None'); 
  
    //console.log("bodyFormData",bodyFormData)
    axios.post(apiConstants.CUSTOM_TEST_END_POINT,
      bodyFormData,
     { headers: {'Content-Type': "multipart/form-data" }}
      ).then((response) => {
        //handle success
        //console.log(response); 
		this.setState({jsonData:response.data,submitted:false,loaded:true})
  }).catch((error) => {
    console.log(error);
    this.setState({loaded:true,jsonData:error});
  });
	   }

  };
   
   dataSourceChange = (e) => {
	   this.setState({testDataSource:e.currentTarget.value})
   };
kbTypeChange = (e) => {
	   this.setState({kbType:e.target.value})
   };
   
   fileUrlChange = (e) => {
	    if(this.isURL(e.target.value)){
		  //console.log("valid url")
		  this.setState({fileUrlError:false,fileUrl:e.target.value});
	  }else{
		  this.setState({fileUrlError:true,fileUrl:e.target.value});  
	  }
	  
   };
   
   handleFile = e => {
    this.setState({file: e.target.files[0] });
  };

modelSelect = (event,i) => {
    
// this.state.SocialData[i]["query_id"] = i;
const value = event.target.value.filter((val,i)=>{
  return val !== ""
});
if(value.length>0){
  this.setState({selectedModels:value});
}else{
  this.setState({selectedModels:[""]});
}

};

setActiveModel = (modelName) => {
  this.props.setActiveModel(modelName);
    // this.setState({activeModel:modelName});
  
};

render() {
     
        return (
           <div className="border rounded p-3 mt-2 settings">
		  
               <div className="d-flex flex-row">
               <div className="w-50 float-left"><h2>Settings</h2></div>
               <div className="w-50 float-right text-right pt-2"><a className="fa fa-arrow-left " onClick={()=>this.props.closeSettings()}></a></div>
               </div>
               <div className="d-flex flex-column">
               <div className="w-100" data-toggle="tooltip" title="Coming soon!">
                   <h4 className="font-weight-bold">Search results per page:</h4>
                  <Slider
      id="disctete-ticks-slider"
      className="pl-3 pt-2"
      label=""
      discrete
      defaultvalue={10}
      min={5}
      max={100}
      step={5}
      discreteTicks={5}
      valuePrecision={2}
      primary
	  disabled
    />
                   </div>
                   <div className="w-100 d-flex flex-row mt-2">
                  <div className="w-50 border rounded p-3 mr-3">
				   <h4 className="font-weight-bold">Doument ingestion :</h4>
                   <SelectionControlGroup
				    inline
   id="docIngestion"
    name="docIngestion"
    type="radio"
    label=""   
    onChange={this.documentIngestionChange}
    value={this.state.docIngestion}
    controls={[{
      label: 'full document ',
      value: 'document',
    }, {
      label: 'Break into Sections',
      value: 'sections',
    }]}
  />
				  </div>
				  <div className="w-50 border rounded p-3" data-toggle="tooltip" title="Coming soon!">
				  <h4 className="font-weight-bold">PDF Ingestion Policy:</h4>
                   <SelectionControlGroup
				   disabled
                   inline
   id="ingestionPolicy"
   name="ingestionPolicy"
   type="radio"
   label=""
   onChange={this.ingestionPolicyChange}
   value={this.state.ingestionPolicy}
   controls={[ {
    label: 'Default',
    value: 'None',
  },
       {
     label: 'LApdf',
     value: 'LApdf',
   }, {
     label: 'Grobid',
     value: 'Grobid',
   }]}
 />
				  </div>
                   </div>
                    <div className="w-100 d-flex flex-row mt-3">
                   <div className="w-50 border rounded p-3 mr-3">
				   
				     <h4 className="font-weight-bold">Machine Comprehension :</h4>
                   <SelectionControlGroup
				    inline
					
   id="machineComprehension"
   name="machineComprehension"
   type="radio"
   label=""
   onChange={this.mcChange}
   value={this.props.machineComprehension}
   controls={[{
     label: 'Question Answering',
     value: 'v1',
   }, {
     label: "Question Answering with `No answer` capability",
     value: 'v2',
   }]}
 />
				   </div>
				   <div className="w-50 border rounded p-3">
				   <h4 className="font-weight-bold">Show header:</h4>
                   <SelectionControl
        id="custom-checkbox-icon-2"
        value="Show header"
        type="checkbox"       
        checked={this.props.showHeader}      
        onChange={this.preciseChange} 
        label="Show header"
       checkedCheckboxIcon={<svg style={{"width":"12px!important","height":"12px!important"}} viewBox="0 0 30 30">
        <path fill="#000000" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
    </svg>}
      />
				   </div>
                   </div>
				   <div className="w-100 border rounded mt-3 p-3">
                  <h4 className="font-weight-bold">Custom Ingestion Policy: <a href="https://kaas-custom-endpoint-guide.s3.us-east-2.amazonaws.com/custom-extraction-endpoint-guide.zip" download="custom_extraction_tutorial"><small>Self Starter Kit</small></a></h4>
                   <input type="text" className="form-control" onChange={this.customExtractionChange} value={this.state.customUrl} placeholder="Enter custom ingestion Endpoint URL" />
					   {this.state.urlError ? <span className="text-danger">Please enter a valid url</span> : ""}
					   
					   <div id="accordion">
					    <div className="card mt-3 p-0">
    <div className="card-header heading">
      <a className="card-link d-block text-dark font-weight-bold collapsed" data-toggle="collapse" href="#collapseOne" aria-expanded="false">
       Test it out
      </a>
    </div>
    <div id="collapseOne" className="collapse" data-parent="#accordion">
      <div className="card-body p-0">
	  
       <div className="bg-test">
	      
		   <div className="bg-white pl-3 pt-1 pb-2 pr-2">
		   <h4 className="mb-0 font-weight-bold">Parameters</h4>
		   </div>
		   <form className={`${this.state.submitted ? 'was-validated' : 'needs-validation'}`}  novalidate>
		   <div className="d-flex flex-row border-bottom border-top pl-3">
		     <h4 className="w-25 font-weight-bold mb-0">Name</h4>
			  <h4 className="w-50 font-weight-bold mb-0">Description</h4>
		   </div>
		   <div className="d-flex flex-row p-3">
		     <span className="w-25 d-inline-block align-self-center">Knowledge Type <span className="text-danger">* required</span><br/><span>String - FormData</span></span>
			 <select name="kbType" className="custom-select w-50" value={this.state.kbType} onChange={this.kbTypeChange} required >
    <option value="">Select knowledge type</option>
    <option value="raw">RAW</option>
    <option value="faq">FAQ</option>
    </select> 
	
		   </div>
		    <div className="d-flex flex-row p-3">
		    <span className="w-25 d-inline-block align-self-center">Data Source </span>
  <div className="w-50">
<div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input" name="dataSource" value="url" checked={this.state.testDataSource === 'url'} 
                                       onChange={this.dataSourceChange} />URL
  </label>
</div>
<div className="form-check-inline">
  <label className="form-check-label">
    <input type="radio" className="form-check-input" name="dataSource"  value="file" checked={this.state.testDataSource === 'file'} onChange={this.dataSourceChange} />File
  </label>
</div>
  </div> 
		   </div>
		   { this.state.testDataSource === 'url' && <div className="d-flex flex-row p-3">
		    <span className="w-25 d-inline-block align-self-center">URL <span className="text-danger">* required</span><br/><span>String - FormData</span> </span>
  <input type="url" className="form-control w-50" placeholder="Enter file URL" value={this.state.fileUrl} onChange={this.fileUrlChange} required/> 
  {this.state.fileUrlError ? <span className="text-danger pl-2 pt-2">Please enter a valid url</span> : ""}
		   </div> }
		   
		   {this.state.testDataSource === 'file' &&  <div className="d-flex flex-row p-3">		  
  <span className="w-25 d-inline-block align-self-center">File <span className="text-danger">* required</span><br/><span>file - FormData</span></span>
  {/* <input type="file" id="myFile" name="file" onChange={this.handleFile} className="w-50" required/>*/}
   <div className="custom-file w-50">
    <input type="file" className="custom-file-input" id="customFile" name="file" onChange={this.handleFile} required/>
    <label className={`custom-file-label ${this.state.file !== null && this.state.file !== undefined ? 'selected' : ""}`} for="customFile"><i className="fa fa-paperclip"></i> {this.state.file !== null && this.state.file !== undefined ? this.state.file.name : ""}</label>
  </div>
		   </div>}
		    <div className="d-flex flex-row p-3">		  
  
  <span className="w-25 d-inline-block align-self-center">Custom Extraction Endpoint <span className="text-danger">* required</span><br/><span>String - FormData</span></span>
  <input type="url" className="form-control w-50" onChange={this.customExtractionChange} value={this.state.customUrl} placeholder="Enter custom ingestion Endpoint URL" required />
					   {this.state.urlError ? <span className="text-danger pl-2 pt-2">Please enter a valid url</span> : ""}
		   </div>
		  <div className="d-flex flex-row p-3">
		  <div className="w-25"></div>
		  <div className="w-50">
		  <button type="button" className="btn btn-outline-primary" disabled={!this.state.loaded} onClick={this.execute}>Execute</button> <button type="button" disabled={!this.state.loaded} className="btn btn-outline-primary ml-3" onClick={this.clear}>Clear</button>
		  </div>
		  </div>
		   </form>
		    <div className="bg-white pl-3 pt-1 pb-2 pr-2">
		   <h4 className="mb-0 font-weight-bold">Responses</h4>
		   </div>
		    <Loader loaded={this.state.loaded}></Loader>
		   <div className="highlight-code">
		   <div className="example microlight" dangerouslySetInnerHTML={{__html: JSON.stringify(this.state.jsonData, undefined, 2).replace(/\n/g, "<br/>").replace(/[ ]/g, "&nbsp;") }}>
	
		   </div>
		   </div>
		   
	   </div>
	   
      </div>
    </div>
  </div>
					   </div>
					   
                   </div>

                   

<div className="w-100 border rounded mt-3 p-3">
                  <h4 className="font-weight-bold">Learn To Rank :</h4>
                  <div className="d-flex flex-row">
                  <div className="w-50 align-self-start mr-auto">
                  {this.state.models.length>0 ? <FormControl >
        
        <Select
    multiple
    
          value={this.state.selectedModels}
          onChange={(e)=>this.modelSelect(e)}
          displayEmpty
          
          inputProps={{
            name: 'index',
            id: 'models',
            className: "w-100 model-select"
          }}
        >
     <MenuItem value="" disabled className="d-block w-100">Select Models</MenuItem>
        {
            this.state.models.map((model,i)=>(
              <MenuItem key={i} value={model.model_name} className="d-block w-100">{model.model_name}</MenuItem>
            ))
        }            
        </Select>
      </FormControl> : <span>No models found for knowledge base '{this.props.knowledgeBase}'</span>}
      </div>
      <div className="align-self-end">
      {this.props.trainingStatus.status ? this.props.trainingStatus.status === 'SUCCESS' || this.props.trainingStatus.status === 'FAILURE' ? <button  className="btn btn-primary d-inline" onClick={this.showConfirm}>Train</button> : <button disabled  className="btn btn-info d-inline">Training...</button> : <button  className="btn btn-primary d-inline" onClick={this.showConfirm}>Train</button>}
    
      </div>
                  </div>
                  


        {this.state.selectedModels.length>0 && this.state.selectedModels[0] !== ""  &&
          <table className="table models mt-3 border rounded">
          <thead>
          <tr>
             <th style={{width:'100px'}}>Model Name</th> 
             <th style={{width:'100px'}}>Timestamp</th>                         
             <th style={{width:'100px'}}>NDCG@5</th>
             <th style={{width:'100px'}}>State</th> 
                   
             <th></th>
           </tr>
           </thead>
           <tbody>
          {this.state.selectedModels.map((model,i)=>(
           <tr>
             <td><Tooltip title={this.state.models.map((mod,i)=>
               model === mod.model_name && mod.metrics.length>0 ? (
                <span>Examples Dropped : {mod.metrics[1].value}<br/>
                Examples Used : {mod.metrics[2].value}
                </span>
               )  : "")}>{model}</Tooltip></td>
             <td>
             {this.state.models.map((mod,i)=>
               model === mod.model_name && mod.timestamp ? (
                mod.timestamp
               )  : "")}
               </td>            
                
               <td>
               {this.state.models.map((mod,i)=>
               model === mod.model_name && mod.metrics.length>0 ? (
                mod.metrics[0].value
               )  : "")}
               </td>
               <td>
               {this.state.models.map((mod,i)=>
               model === mod.model_name && this.props.activeModel === mod.model_name ? (
              <span className=" pl-3 pr-3 pt-1 pb-1" style={{borderRadius:"20px",border:"1px solid #1890ff",color:'#1890ff'}}>Active</span>

               ) : "")}
               </td>
               
               <td>
               {this.state.models.map((mod,i)=>
               model === mod.model_name ? this.props.activeModel === mod.model_name ? (
                <Button raised primary swapTheming onClick={()=>this.setActiveModel("")}>
                Deactivate
              </Button>
                /*<MenuButton
                id="menu-button-2"
                icon
                menuItems={[
                  <ListItem key={1} onClick={()=>this.setActiveModel("")} primaryText="Deactivate" />        
                ]}
                
                centered
                anchor={{
                  x: MenuButton.HorizontalAnchors.CENTER,
                  y: MenuButton.VerticalAnchors.BOTTOM,
                }}
              >
                more_vert
              </MenuButton>*/

               ) :  <Button raised primary swapTheming onClick={()=>this.setActiveModel(mod.model_name)} >
               Activate
             </Button>
             /*<MenuButton
               id="menu-button-2"
               icon
               menuItems={[
                 <ListItem key={1} onClick={()=>this.setActiveModel(mod.model_name)} primaryText="Activate" />        
               ]}
               
               centered
               anchor={{
                 x: MenuButton.HorizontalAnchors.CENTER,
                 y: MenuButton.VerticalAnchors.BOTTOM,
               }}
             >
               more_vert
             </MenuButton>*/ : "")}
               
              
              {this.state.modelToDelete === model ? <span class="d-inline-block pb-2">deleting...</span> : <button type="button" onClick={()=>this.showDelete(model)} className="btn pb-1"><i className="fa fa-trash"></i></button>}</td>          
           </tr>
          ))}
          </tbody>
          </table>
        }
</div>

<div className="w-100 text-center mt-2" style={{"zIndex":"8"}}> <button  className="btn btn-primary d-inline mt-3" disabled={this.state.urlError} onClick={()=>{this.props.setCustomExtractionUrl(this.state.customUrl.trim()); this.props.setDocumentIngestion(this.state.docIngestion);this.props.closeSettings()}}>SAVE SETTINGS</button></div>
                   </div>

           </div>
        );
    }
}

export default Settings;
