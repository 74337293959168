/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function SelectEditable(props) {
  const defaultProps = {
    options: props.titles,
    getOptionLabel: (option) => option,
  };

 

  const [value, setValue] = React.useState(null);

  return (
    <div style={{ width: 300 }}>
      <Autocomplete
        {...defaultProps}      
      id="combo-box-demo"      
      value={props.selectedTitle !== undefined && props.selectedTitle !== null ? props.selectedTitle : ""}
       
        onChange={(event, newValue) => {
          setValue(newValue);
          props.titleChange(event,newValue)
        }}
        onKeyPress={(e)=>props.titleKeyPress(e)}
        autoSelect={true}
      renderInput={(params) => <TextField {...params} label="Select titles" margin="none"  />}
    />
      
    </div>
  );
}

