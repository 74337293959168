import React, { Component } from 'react';
import logo from '../../images/curiosm_logo.png';
import '../../App.css';
import './searchresults.css';
import Searchresultdetail from '../search-result-detail/searchresultdetail.js';
import axios from 'axios';
import { ExpansionList, ExpansionPanel } from 'react-md';
import Rating from '@material-ui/lab/Rating';
import * as apiConstants from '../../service-api-constants.js';

class Searchresults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      similarQns:[],
      numPages: null,
      pageNumber: 5,
      str: '',
      itemIndex: 5.1,
	  value:0.0,
	  
    }
 }
  

  componentDidMount(){
   
      this.similarQns(this.props.showResults.faq_response);
  }
  componentWillReceiveProps(nextProps){
	   if(nextProps.showResults.faq_response && nextProps.showResults.faq_response.length !== this.props.showResults.faq_response.length){
		 this.similarQns(nextProps.showResults.faq_response);
	  }
   
  }

  similarQns = (res) => {
    const listOfTags = res;
      const unique = [];

listOfTags !== undefined && listOfTags.map(x => unique.filter(a => a.question === x.question).length > 0 ? null : unique.push(x));

//console.log("Similar Questions",unique);
this.setState({similarQns: unique});
  };

  renderPdf = (text,fileurl) => (this.props.showPdf(text,fileurl));
  youMayAsk = (expanded,question) => {
    this.props.youMayAsk(expanded,question);
  };
  
 randHex = (len) => {
 let maxlen = 8,
     min = Math.pow(16,Math.min(len,maxlen)-1), 
      max = Math.pow(16,Math.min(len,maxlen)) - 1,
      n   = Math.floor( Math.random() * (max-min+1) ) + min,
      r   = n.toString(16); 
    while ( r.length < len ) {
     r = r + this.randHex( len - maxlen );
  }
	 //console.log("r",r)
  return r;
};


  onRatingChange=(value, i, query_id, doc_id) => {
	  
           
			const id = this.randHex(12);
		
	
        
        const data = {
        "query_id" : query_id,
        "doc_id" : doc_id,
        "feedback_id": id,
        "feedback_rating":value,
        "feedback_binary":-1,
        "doc_position":i+1
			};
		//	console.log("data",data);
			axios.post(apiConstants.SUBMIT_RATING,
	data,
     { headers: {'Content-Type': "application/json" }}
      ).then((response) => {
        //handle success
       // console.log(response);	
        if(response.data.status && response.data.status === true){
          this.setState({[`value_${i}`]:value});
        }else{
          this.setState({[`value_${i}`]:0.0});
        }
        

    }).catch((error) => {
      console.log(error);
      this.setState({error:error.toString(),loaded:true});
    });
			
          };
  
  
  render() {
    const { similarQns} = this.state;
    const result = this.props.showResults;
    const showAddOn = this.props.showAddOn;
    //console.log("props",this.props.showResults);
    return ( 
            <div className="mx-auto w-100 float-none m-0 pl-0 pt-2 d-lg-block">
              {/*this.props.fromTree ? "" : !this.props.showPreciseAnswer && result.qna_response && result.qna_response[0].curiosum_top_answer && result.qna_response[0].curiosum_top_answer.length > 0 ? <div className="border rounded mt-1 pl-3 pr-3 pt-2 pb-2 shadow bg-light font-weight-bold radius-lg">
                <span className="text font-italic ansfont" >{result.qna_response[0].curiosum_top_answer}</span>
              </div> : this.props.showPreciseAnswer && result.qna_response && result.qna_response[0].machineComprehensionAnswer && result.qna_response[0].machineComprehensionAnswer.length > 0 ? <div className="border rounded mt-1 pl-3 pr-3 pt-2 pb-2 shadow bg-light font-weight-bold radius-lg">
                <span className="text font-italic ansfont" >{result.qna_response[0].machineComprehensionAnswer}</span>
    </div> : ""*/}
              {this.props.fromTree ? "" : 
              result.qna_response && result.qna_response.length > 0 && result.qna_response[0].curiosum_top_answer 
              ? (this.props.showPreciseAnswer && result.qna_response[0].knowledgebase === 1) || result.qna_response[0].faq === 1 
              ? 
              <div className="border rounded mt-1 pl-3 pr-3 pt-2 pb-2 shadow bg-light font-weight-bold radius-lg">
                <span className="text font-italic ansfont" dangerouslySetInnerHTML={{__html: result.qna_response[0].curiosum_top_answer }}></span> 
              </div> : 
              this.props.showPreciseAnswer && result.qna_response[0].knowledgebase === 1 
              ? 
              <div className="border rounded mt-1 pl-3 pr-3 pt-2 pb-2 shadow bg-light font-weight-bold radius-lg">
                <span className="text font-italic ansfont" dangerouslySetInnerHTML={{__html: result.qna_response[0].curiosum_top_answer }}></span></div> 
                : result.qna_response[0].curiosum_top_answer && result.qna_response[0].faq === 0 && result.qna_response[0].knowledgebase === 0 ? result.qna_response[0].curiosum_top_answer : "" 
                 : ""}             
               {this.props.fromTree ? "" : result.qna_response && result.qna_response.length > 0 && result.qna_response[0].machineComprehensionAnswer.length > 0 ? (result.qna_response[0].faq === 0 && result.qna_response[0].knowledgebase === 0) ? "" : <div className="bg-primary clearfix d-none text  border border-light shadow rounded p-1 pl-4">
                <div className="float-left">Is this a relevant answer?</div>
                <div className="float-right ml-5 mr-4"><a disabled={true} className="border-0 mr-2"><i className="fa fa-thumbs-o-up"></i></a> | <a disabled={true} className="bg-body border-0 ml-2"><i className="fa fa-thumbs-o-down"></i></a></div>
              </div> : ""}
              {!this.props.fromChatBot && !this.props.fromTree ? <div className="border shadow rounded mt-3 radius-lg">
              {result.qna_response && result.qna_response.length > 0 && result.qna_response.map((detail, index) => 
                this.props.fromChatBot ? (<Searchresultdetail key={index} details={detail} onPdfClick={this.renderPdf} fromChatBot={true} />) : ((this.props.fromTree) ? <Searchresultdetail key={index} details={detail} onPdfClick={this.renderPdf} fromTree={true} /> : 
                <div>
                  <Searchresultdetail key={index} details={detail} onPdfClick={this.renderPdf} />
				  {result.qna_response[0].knowledgebase === 0 && result.qna_response[0].faq === 0 ? "" : <div className="pl-3"> <Rating
          name={`rating_${index}`}
          value={this.state[`value_${index}`]}
		  precision={0.5}
		  className="d-inline-block mt-2"
          onChange={(event, newValue)=>this.onRatingChange(newValue,index,result.query_id ? result.query_id : "" ,detail.doc_id ? detail.doc_id : '')}
		  emptyIcon={<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path></svg>}
        /> {/*<span>{this.state[`value_${index}`]}</span>*/} </div>}
                {((index === 0 && index === result.qna_response.length-1) || (index === 1 && index < result.qna_response.length) ) && similarQns.length > 0 ? 
                <ExpansionList className="mr-3 ml-3 mb-2 border radius-lg">
                  <h4 className="pl-3 pt-2 bg-blue mb-0 pb-2 radius-lg border">People also ask</h4>
                  {similarQns.map((faq, ind) => 
                    ((ind <= 4 && this.props.searchText !== faq.question) || (showAddOn  && this.props.searchText != faq.question)) ? <ExpansionPanel label={faq.question} footer={null} onExpandToggle={(expanded)=>this.youMayAsk(expanded,faq.question)}>
                    <p>{faq.answer}</p>
                   
                  </ExpansionPanel> : <ExpansionPanel className="d-none"></ExpansionPanel>
                  )}
                  </ExpansionList>
                  
                  :""}</div>) 
              )}</div>
             :
             result.qna_response && result.qna_response.length > 0 && result.qna_response.map((detail, index) => 
                this.props.fromChatBot ? (<Searchresultdetail key={index} details={detail} onPdfClick={this.renderPdf} fromChatBot={true} />) : ((this.props.fromTree) ? <Searchresultdetail key={index} details={detail} onPdfClick={this.renderPdf} fromTree={true} /> : <Searchresultdetail key={index} details={detail} onPdfClick={this.renderPdf} />) 
              )}
              

              {this.props.fromChatBot || this.props.fromTree ? "" : (result.qna_response && result.qna_response.length > 0 && result.qna_response[0].faq == 0 && result.qna_response[0].knowledgebase == 0) ? "" : <div className="bg-primary clearfix d-none text border border-light shadow rounded mt-4 p-1 pl-4">
                <div className="float-left w-75">Found what you were looking for?</div>
                <div className="float-right w-25"><a className="border-0 mr-2" disabled={true}><i className="fa fa-thumbs-o-up"></i></a> | <a className="bg-body border-0 ml-2" disabled={true}><i className="fa fa-thumbs-o-down"></i></a></div>
              </div>}

            </div>



         
          




      

    );
  }
}

export default Searchresults;
