import React , { Component }from 'react';
import '../../App.css';
import {
  Button as MDbutton,
  CardActions,
  FileUpload,
  LinearProgress,
  Snackbar,
  
  TextField,
  FontIcon,
  Checkbox,
  SelectField,
  SelectionControlGroup,
  NavigationDrawer,
  FileInput,
  SVGIcon,Card, CardTitle, CardText, Slider,
  ExpansionList, ExpansionPanel
} from 'react-md';
import { Neo4jGraphRenderer } from 'neo4j-graph-renderer';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
//import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
  },
});
const drawerWidth = 240;


function getSteps() {
  return ['Populate Ontology', 'Query'];
}

class Ontology extends Component {
  constructor(props) {
    super(props);

    this.state = {
         error:'',
        loaded:true,
        submitted:false,
        activeStep: 0,   
        offerings:["Gremlin","GraphQL","Neo4jCQL"],
        Gremlin: false,
        GraphQL:false,
        Neo4jCQL:true,
        selectedOfferings:["Neo4jCQL"],
        query: 'CREATE ( bike:Bike { weight: 10 } ) CREATE ( frontWheel:Wheel { spokes: 3 } ) CREATE ( backWheel:Wheel { spokes: 32 } ) CREATE p1 = (bike)-[:HAS { position: 1 } ]->(frontWheel) CREATE p2 = (bike)-[:HAS { position: 2 } ]->(backWheel) RETURN bike, p1, p2'  ,
        populateType : "existing",
        formatType:'gremlin',
        file:null,
        renderNode: document.getElementById('navigation-drawer-demo')
    }
}


reset = () => {
    this.setState({submitted:false,query:''})
}

radioChange = (value, event) => {
   console.log("value",value);
   this.setState({populateType : value});
}

sentenceChange = (value) => {
    this.setState({query: value});
  };

formatChange = (value, event) => {
    console.log("value",value);
    this.setState({formatType : value,file:null,fileName:''});
 }

handleOfferingsChange = (value,event) => {
  const offerings = this.state.selectedOfferings;
 
  if(event.target.checked){
    const offeringsSelected = offerings.filter((offering)=>{
      return offering == event.target.value
    });
    if(offeringsSelected.length == 0){
       //const setOfferings = this.state.selectedOfferings;
       offerings.push(event.target.value);
       console.log("offerings",offerings);
       this.setState({ [event.target.value]: event.target.checked,selectedOfferings: offerings });
    }
  }else{
    const offeringsSelected = offerings.filter((offering)=>{
      return offering == event.target.value
    });
    if(offeringsSelected.length > 0){
         const selectedOfferings = this.state.selectedOfferings;
         const offeringsUpdate = selectedOfferings.filter((offering)=>{
          return offering != event.target.value
        });
        console.log("offeringsUpdate",offeringsUpdate);
        this.setState({ [event.target.value]: event.target.checked,selectedOfferings: offeringsUpdate });
   }
  }  
};

submitQuery = () => {
  if(this.state.query.length>0){
      this.setState({submitted:true});
  }
};

handleNext = () => {
  this.setState(state => ({
    activeStep: state.activeStep + 1,
    submitted:false
  }));
};

handleBack = () => {
  this.setState(state => ({
    activeStep: state.activeStep - 1,
  }));
};

handleReset = () => {
  this.setState({
    activeStep: 0,
    submitted:false
  });
};


fileChange = (files,e) => {
    console.log("files",files);
    this.setState({file:files});
  };
  handleLoad = ({ name, size }) => {
    this.progressTimeout = setTimeout(() => {
      this.progressTimeout = null;
      this.setState({ progress: null });
    }, 500);
    this.setState({ fileName: name, fileSize: size });
  };

  handleLoadStart = () => {
    this.setState({ progress: 0 });
  };

getStepContent = (step) => {
  switch (step) {
    case 0:
      return  <div className="d-flex flex-row">
        <SelectField
      id="select-field-2"
      label="Strings"
      placeholder="Placeholder"
      className="md-cell"
      menuItems={["GICS","FIBO","Product Hierarchy"]}
      
    />
    <MDbutton className="ml-5 mb-1 align-self-center rounded-pill white-space" onClick={this.analyze} raised primary>Populate Graph</MDbutton>
      </div>  ;
    case 1:
      return <div className="pl-4">

               <div className="d-flex flex-column">
       <div className="d-flex flex-row">
       

    <div className="d-flex flex-column w-75">   
    
    
    <TextField
      id="floating-center-title"
     
      lineDirection="center"
      placeholder="Type a query"
      className="text-field mt-3 w-100"
      value={this.state.query}
      rows={6}
      onChange={this.sentenceChange}
    />
    
     </div>




     <div className="pt-3">
<h5 className="pl-5">Graph Query Languages</h5>
{this.state.offerings.map((item,index)=>(
  <Checkbox
  id={`checkbox${index}`}     
  name={item}    
  label={item}
  value={item}
  checked={this.state[item]}
  inline={false}
  onChange={this.handleOfferingsChange}
  className="ml-5"
/>
)) }
<MDbutton className="mt-4 ml-5 align-self-end mb-1 rounded-pill white-space" onClick={this.submitQuery} raised primary>Submit</MDbutton>
       </div>
      </div>
      
        
    
      </div> 

            {this.state.submitted ? <Neo4jGraphRenderer url={`http://localhost:7474/db/data/transaction/commit`} user={`neo4j`} 
        password={`password`} query={this.state.query} /> : ""}
      </div>;
   
    default:
      return 'Unknown step';
  }
}



  
  render() {  
    const { activeStep,populateType,renderNode } = this.state;
    const steps = getSteps();
    
     // const classes = this.useStyles;
  //const theme = useTheme();
  //const [open, setOpen] = this.state;
  return (
    <div className="container border rounded shadow p-3 mt-2">
    <h5 className="font-weight-bold">{this.props.uploadOntology ? "Populate Ontology" : "Query Ontology"}</h5>

    {this.props.uploadOntology && <div><div className="d-flex flex-row w-100">
        <SelectionControlGroup
    id="selection-control-group-radios"
    name="radio-example"
    type="radio"
    inline="true"
    className="d-inline-block"
    label="Choose any one option for populating ontologies"
    defaultValue={this.state.populateType}
    onChange={this.radioChange}
    controls={[{
      label: 'Choose from Existing',
      value: 'existing',
    }, {
      label: 'Upload',
      value: 'upload',
    }
    ]}
  />
    </div> 
    <div>
{populateType == 'existing' && <div className="d-flex flex-row align-self-start w-50">



<SelectField
id="select-field-2"
label="Choose an option"
placeholder="Placeholder"
className="md-cell w-50"
menuItems={["GICS","FIBO"]}

/>
<MDbutton className="ml-3 mt-3 align-self-center rounded-pill white-space" onClick={this.analyze} raised primary>Populate Graph</MDbutton>
</div>}

{ populateType == 'upload' && <div className="d-flex flex-column pt-5">

<SelectionControlGroup
    id="selection-control-group"
    name="data-format"
    type="radio"
    inline="true"
    className="d-inline-block pl-3"
    label="Choose any one data format for upload"
    defaultValue={this.state.formatType}
    onChange={this.formatChange}
    controls={[{
      label: 'Gremlin (CSV)',
      value: 'gremlin',
    }, {
      label: 'RDF / XML format (rdfxml)',
      value: 'rdfxml',
    }, {
        label: 'N-Triples (ntriples)',
      value: 'ntriples', 
    }, {
        label: 'N-Quads (nquads)',
      value: 'nquads', 
    }, {
        label: 'Turtle (turtle)',
      value: 'turtle', 
    }
    ]}
  />

<div className="d-flex flex-row align-self-start pt-2">
 
<FileUpload
  id="server-upload-file"
  label="Choose file"
  onLoad={this.handleLoad}
  onLoadStart={this.handleLoadStart}
  required
  accept="image/*,video/*"
  name="file"
  className="file-inputs__upload-form__file-upload ml-3 mr-3 rounded-pill"
  onChange={this.fileChange}
  icon={null}
  primary
  iconBefore
/>
<TextField
  id="server-upload-file-field"
  placeholder="No file chosen"
  value={this.state.fileName}
  className="file-inputs__upload-form__file-field"
  readOnly
  fullWidth={false}
/>

<MDbutton className=" ml-3 align-self-center mb-1 rounded-pill" iconEl={<FontIcon iconClassName="fa fa-arrow-circle-up" />} disabled={!this.state.fileName || !this.state.loaded} onClick={this.uploadCredentials} raised primary>Upload</MDbutton>



</div></div>}
        </div> </div>}
        
{this.props.queryOntology && <div className="">

<div className="bg-light d-flex flex-row p-3 border">
<div className="bg-white w-90"><TextField
id="floating-center-title"
spellcheck="false"
lineDirection="center"
placeholder="Type a query"
className="text-field  w-100 border rounded shadow border-bottom-0 pl-2 pr-2"
value={this.state.query}
rows={1}
onChange={this.sentenceChange}
/></div>
<div className="d-flex flex-row p-1 w-10">
<span><i class="material-icons query-icon" onClick={this.submitQuery}>
play_arrow
</i></span>
<span><i class="material-icons query-icon">
star_border
</i></span>
<span><i class="material-icons query-icon" onClick={this.reset}>
clear
</i></span>
</div>
    </div>

{this.state.submitted && <div className="d-flex flex-row mt-3 bg-light">
<div className="nav flex-column nav-pills w-10 border p-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
  <a class="nav-link active border-bottom " id="v-pills-home-tab" data-toggle="pill" href="#v-pills-graph" role="tab" aria-controls="v-pills-graph" aria-selected="true"><i class="material-icons d-block">
scatter_plot
</i> Graph</a>
  <a class="nav-link border-bottom border-top" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-table" role="tab" aria-controls="v-pills-table" aria-selected="false"><i class="material-icons d-block">
table_chart
</i> Table</a>
  <a class="nav-link border-bottom border-top" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-text" role="tab" aria-controls="v-pills-text" aria-selected="false"><i class="material-icons d-block">
text_format
</i> Text</a>
  <a class="nav-link border-bottom border-top" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-code" role="tab" aria-controls="v-pills-code" aria-selected="false"><i class="material-icons d-block">
code
</i> Code</a>
</div>
<div class="tab-content w-90 border p-2" id="v-pills-tabContent">
  <div class="tab-pane fade show active" id="v-pills-graph" role="tabpanel" aria-labelledby="v-pills-graph-tab">
  
  {this.state.submitted ? <Neo4jGraphRenderer url={`http://localhost:7474/db/data/transaction/commit`} user={`neo4j`} 
password={`password`} query={this.state.query} /> : ""}
  
  </div>
  <div class="tab-pane fade" id="v-pills-table" role="tabpanel" aria-labelledby="v-pills-table-tab">...</div>
  <div class="tab-pane fade" id="v-pills-text" role="tabpanel" aria-labelledby="v-pills-text-tab">...</div>
  <div class="tab-pane fade" id="v-pills-code" role="tabpanel" aria-labelledby="v-pills-code-tab">...</div>
</div>
</div>}

{/*<div className="d-flex flex-column">
<div className="d-flex flex-row">


<div className="d-flex flex-column w-75">   


<TextField
id="floating-center-title"

lineDirection="center"
placeholder="Type a query"
className="text-field mt-3 w-100 border border-bottom-0"
value={this.state.query}
rows={6}
onChange={this.sentenceChange}
/>

</div>




<div className="pt-3">
<h5 className="pl-5">Graph Query Languages</h5>
{this.state.offerings.map((item,index)=>(
<Checkbox
id={`checkbox${index}`}     
name={item}    
label={item}
value={item}
checked={this.state[item]}
inline={false}
onChange={this.handleOfferingsChange}
className="ml-5"
/>
)) }
<MDbutton className="mt-4 ml-5 align-self-end mb-1 rounded-pill white-space" onClick={this.submitQuery} raised primary>Submit</MDbutton>
</div>
</div>



</div> */}
<div>

</div>

</div>}



     {/*<Stepper className="stepper" activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <div>
              {this.getStepContent(index)}
              <div className="">

              <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className="md-background--primary rounded-pill"
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className="md-background--primary rounded-pill ml-2"
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className="">
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={this.handleReset} className="">
            Reset
          </Button>
        </Paper>
      )}*/}


     
    </div>
  );
}
}
export default Ontology;