import React from 'react';
import {
    Chip,
    Button
  } from 'react-md';
  import _ from 'lodash';

class AddTag extends React.Component {
	constructor(props) {
        super(props);
        this.googleInput = React.createRef();
		this.handleFocus = this.handleFocus.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeypress = this.handleKeypress.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.handleClick = this.handleClick.bind(this);

		this.helperspan = null; // is set via ref
		
		this.state = {
			currentcolor: [
				"#ffffff",
				"#ffffff",
				"#ffffff",
				"#ffffff",
				"#ffffff",
				"#ffffff",
				"#ffffff",
				"#ffffff"
			],
			content_add: "",
			width: 100,
            myItems: [],
            add:false,
		};
		this.lastId = -1;
    }
    
    componentDidMount(){
      
    }


	handleFocus(event) {
		this.setState({ content_add: "" });
	}
	
	handleChange(event) {
        //console.log("onchange",event.target.value)
		const usr_input = event.target.value;
		this.setState({ content_add: usr_input });
	}

	handleKeypress(event) {
		if (event.key === "Enter") {
            let list = _.cloneDeep(this.props.tags);
            let items = _.cloneDeep(this.state.myItems);
            var currentcontent = this.state.content_add.trim();
			if (!currentcontent) {
				return; 
			}
            list.push(currentcontent);
            items.push(currentcontent);
            let finalList = [...new Set(list)];
            //console.log("finalList",finalList);
           // this.props.setTags(Array.from(finalList));
           this.props.setTags(Array.from([...new Set(items)]));
            this.setState({myItems:Array.from([...new Set(items)]),content_add: "",add:false});

		}
    }
    
    add = () =>{
        let list = _.cloneDeep(this.state.myItems);
        var currentcontent = this.state.content_add.trim();
        if (!currentcontent) {
            return; 
        }
        list.push(currentcontent);
        let finalList = [...new Set(list)];
        //console.log("finalList",finalList);
        this.setState({myItems:Array.from(finalList),content_add: ""});
           // this.props.setParaQns(this.state.myItems,this.props.item_id)
    }

	handleBlur(event) {
		this.setState({ content_add: event.target.value,add:false });
	}

	handleClick(event) {
		const idToRemove = Number(event.target.dataset["item"]);
        const newArray = this.state.tags.filter((listitem) => {return listitem.id !== idToRemove});
        this.props.setTags(newArray);
		//this.setState({ myItems: newArray });
    }
    
    removeTag = (tagName,mode) => {
        let tagList = _.cloneDeep(this.props.tags);
        let items = _.cloneDeep(this.state.myItems);
        let filteredList = tagList.filter((tag,i)=>{
            return tag !== tagName
        });

        let filteredItems = items.filter((tag,i)=>{
            return tag !== tagName
        });
        //console.log("filteredList",filteredList);
        this.props.setAutoTags(filteredList);
        this.props.setTags(filteredItems);
        this.setState({myItems:filteredItems});
    };
	
	

	componentDidUpdate(prevProps, prevState) {
		if (prevState.content_add !== this.state.content_add) {
			//console.log('did update, content:', this.helperspan.textContent, 'width', this.helperspan.offsetWidth);
			const helperWidth = this.helperspan.offsetWidth;
			this.setState({ width: Math.max(50, helperWidth + 1) });
		}
	}

	makeAddedList() {
		
		const elements =  this.props.tags !== undefined ? this.props.tags.map((tag, index) => (
            <Chip key={index} label={tag} className="mr-2 mt-3" onClick={()=>this.removeTag(tag,"auto")} removable />
			
		)) : []
		return elements

    }

    makeCustomList() {
		
		const elements =  this.props.customTags !== undefined ? this.props.customTags.map((tag, index) => (
            <Chip key={index} label={tag} className="mr-2 mt-3 bg-white border" onClick={()=>this.removeTag(tag,"custom")} removable />
			
		)) : []
		return elements

    }
    
    
    addTag = () => {       
      //  this.googleInput.current.focus();
       this.setState({add:true})
       setTimeout(()=>this.textInput.focus(),0);
    };

	render() {
		return (
			<div className="pl-3 pr-3 pb-3">
                <div className="d-flex flex-row flex-wrap">
                
				{this.makeAddedList()} 
                
                {this.makeCustomList()} 
                {this.state.add ? <div className="border p-1 mt-3 d-flex flex-row" style={{borderRadius:'16px'}} onBlur={this.handleBlur}>			
			<input
					id="add"
					type="text"
					name="initvalue"
					autoComplete="off"
				  maxLength="70"
                  onFocus={this.handleFocus}
					onChange={this.handleChange}
					onKeyPress={this.handleKeypress}
					style={{width:'50px'}}
					value={this.state.content_add}
                   className="border-0"
                   ref={(input) => { this.textInput = input; }}
				/>
{/*<button className="bg-white border-0 align-self-end" onClick={this.add} >+</button>*/}
				
                
</div> : <Button style={{marginTop:"10px"}} className="pt-1 border-0 align-self-end" onClick={this.addTag} icon><img src="https://img.icons8.com/android/24/000000/plus.png" alt="+" style={{width:"20px"}}/></Button>}
</div>
<span id="helperspan" className="invisible" ref={el => (this.helperspan = el)}>
					{this.state.content_add}
				</span>
	
			</div>
		);
	}
}
export default AddTag;