import React from "react";
import TextTypingEffect from "../components/TextTypingEffect/TextTypingEffect";
import "@lottiefiles/lottie-player";

export default function StackLoading(){

return (
 <div className="container-fluid">
     <div className="row">

<div className="col-sm-2 clearfix">
  <header className="">

    <span><img src="image/PS_Logo_RGB.svg" className="App-logo float-left mt-3 pr-2" alt="Curiosum" /> 
    <p className="font-weight-bold pt-3 mb-0 d-block">
    <img src='image/logo_h.png' className="kaas-logo float-left pr-1" alt="Curiosum" /> <br/> <span className="small-text pl-1 d-inline-block mt-1 w-50 text-left">Cognitive Search</span></p>
    </span>

  </header>
  </div>
  </div>
  <div className="d-flex flex-column shadow mt-3 container-fluid border rounded">
   
     <div className="w-100 text-center p-3 mt-5">
     <TextTypingEffect dataText={["Due to low traffic on our platform, we shut down some of our services. We are resuming back our services for you. Kindly wait for 8-10 minutes."]}/>
     <iframe className="w-50 rounded shadow  mt-5" style={{height:"50vh"}} src="https://www.youtube.com/embed/b3lHMALNtiI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
     
     
     </div>

     <div className="w-100  text-center">
    
        <lottie-player
  autoplay
  
  loop
  mode="normal"
  src="json/gamify.json"
  style={{width: "50vw", textAlign:"center",margin: '0 auto'}}
>
</lottie-player>
     </div>

     </div>
 </div>
);
}