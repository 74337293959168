import React, { Component } from 'react';
import '../../App.css';
import _ from 'lodash'

class Trending extends Component {
    constructor(props) {
        super(props);
        var trendarr = [];
        _.forEach(this.props.trendingProp, function(value){
            _.forEach(value.items, function(val){
                if(val.text !== undefined){
                    trendarr.push(val.text);
                }
            });
        });
    trendarr.shift();
    this.state = {
           content: trendarr,
		   showMore:false
        }
    }
	
	showMore = () => {
      this.setState({showMore:!this.state.showMore})
    };
	
    render() {
        return (
            <div className="d-lg-block border border-top-0 shadow mt-2 w-100 p-3 " style={{borderRadius: '20px'}}>
            <div><h3><b>Trending Questions</b></h3>
            <div className="dropdown d-inline" data-toggle="tooltip" title="Coming soon!">
            <a className="btn bg-none text-dark disabled" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Past Month <i className="fa fa-angle-down"></i>
            </a>
            <div className="dropdown-menu" style={{minWidth:"15px"}} aria-labelledby="dropdownMenuLink">
            <a className="dropdown-item" href="#">Day</a>
            <a className="dropdown-item" href="#">Week</a>
            <a className="dropdown-item" href="#">Month</a>
          </div>
            </div>
            <div className="card mt-lg-4 ">
            <div className="card-header"><b style={{lineHeight:"28px"}}>What is KaaS Cognitive Search Platform ?</b>
            <div className="dropdown d-inline invisible">
            <a className="btn bg-none float-right text-secondary disabled" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Read Further <i className="fa fa-angle-down"></i>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a className="dropdown-item" href="#">Action</a>
            <a className="dropdown-item" href="#">Another action</a>
            <a className="dropdown-item" href="#">Something else here</a>
            </div>
            </div>
            </div>
            <div className="card-body">
			
          {this.state.showMore ? <div>
			 <p>
		   KaaS Cognitive Search (KCS) is a micro-services architecture based solution. It uses the cognitive services available on Publicis Sapient's 'Knowledge-as-a-Service - KaaS' platform. 
KaaS's cognitive services help build any knowledge based platform using various AI techniques without getting bogged down by the challenges and complexities of the AI techniques.</p>

<p>Most of the existing enterprise solutions, use either keyword matching techniques along with some basic flavour of Semantic matching using a set of synonyms, or they use supervised machine learning based techniques that require huge amount of training on the kind of questions that could be asked by the user. Both of the approaches pose limitations on the scalability of the solutions and also impact the return on investment made by the organization.</p>

<p>KCS, goes beyond the use of keywords and tries to understand the question and the real intent of the user. It provides precise answer which could be a single word, a span of text, or couple of lines. It also has the capability to rank and validate the candidate answers to identify the best answer.</p>

<p>KCS's machine learning models are domain agnostic and can work on a diverse set of unstructured content without any further re-training. Most of KaaS's services used to build KCS are powered using the concepts of Deep learning and Transfer learning.</p>

<p>To summarize, the AI enabled KCS platform is enterprise-ready, user-friendly and its value proposition is unrivalled. <a onClick={this.showMore}  className="show-more-link"> show less</a></p>
			</div> : <div>
			 <p>
		   KaaS Cognitive Search (KCS) is a micro-services architecture based solution. It uses the cognitive services available on Publicis Sapient's 'Knowledge-as-a-Service - KaaS' platform. 
KaaS's cognitive services help build any knowledge based platform using various AI techniques without getting bogged down by the challenges and complexities of the AI techniques.</p>

<p>Most of the existing enterprise solutions, use either keyword matching techniques along with some basic flavour of Semantic matching using a set of synonyms, or they use supervised machine learning based techniques that require huge amount of training on the kind of questions that could be asked by the user. Both of the approaches pose limitations on the scalability of the solutions and also impact the return on investment made by the organization. <a onClick={this.showMore}  className="show-more-link"> show more</a></p>
			</div>}
		   
            <div>
            <a href="https://youtu.be/b3lHMALNtiI" target="blank">KaaS Cognitive Search</a>
            <i className="fa fa-bookmark-o p-3"></i>
            <i className="fa fa-share-alt p-3"></i>
            <i className="fa fa-ellipsis-v p-3"></i>
            </div>
            </div>
            </div>
        </div>
        <div className="card mt-lg-5">
            <div className="card-header"><b style={{lineHeight:"28px"}}>What's new, different from plain text search? </b>
            <div className="dropdown d-inline invisible" >
            <a className="btn bg-none float-right text-secondary disabled" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Read Further <i className="fa fa-angle-down"></i>
            </a>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a className="dropdown-item" href="#">Action</a>
            <a className="dropdown-item" href="#">Another action</a>
            <a className="dropdown-item" href="#">Something else here</a>
            </div>
          </div>
          </div>
            <div className="card-body">
			
			 {this.state.showMore ? <div>
			<p>
			More than 80% of the digital content is in the form of unstructured text. Whether it is emails, web pages, pdf or word documents, we come across unstructured text in all aspects of our life.</p>

<p>Building AI solutions to extract intelligence from unstructured text has always been a challenge. The ambiguity in the language, or the way text is phrased or interpreted, makes it difficult for the organizations to build solutions that could be used to surface the knowledge hidden in the text.</p>

<p>KaaS Cognitive Search (KCS) was designed to overcome these challenges. It understands any natural language questions and provides precise answers from the text on-the-fly.</p>

<p>Unlike the traditional platforms, KCS's Machine learning models are domain agnostic that work without any training. It can answer any kind of questions that do not need to be fed into the system in advance.</p>

<p>KCS can significantly cut-down the effort required to read through tonnes of text and makes the desired information available to the users on their fingertips with near zero lead time.  <a onClick={this.showMore} className="show-more-link"> show less</a></p>
			</div> : <div>
			 <p>
			More than 80% of the digital content is in the form of unstructured text. Whether it is emails, web pages, pdf or word documents, we come across unstructured text in all aspects of our life.</p>

<p>Building AI solutions to extract intelligence from unstructured text has always been a challenge. The ambiguity in the language, or the way text is phrased or interpreted, makes it difficult for the organizations to build solutions that could be used to surface the knowledge hidden in the text. <a onClick={this.showMore} className="show-more-link"> show more</a></p>
			</div>}			
			
		
            <div>
             <a href="https://youtu.be/b3lHMALNtiI" target="blank">KaaS Cognitive Search</a>
            <i className="fa fa-bookmark-o p-3"></i>
            <i className="fa fa-share-alt p-3"></i>
            <i className="fa fa-ellipsis-v p-3"></i>
            </div>
            </div>
            </div>
            <div className="card mt-lg-5">
            <div className="card-header"><b style={{lineHeight:"28px"}}>How user friendly is this solution? 
</b>
            <div className="dropdown d-inline invisible" >
            <a className="btn bg-none float-right text-secondary disabled" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Read Further <i className="fa fa-angle-down"></i>
            </a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a className="dropdown-item" href="#">Action</a>
          <a className="dropdown-item" href="#">Another action</a>
          <a className="dropdown-item" href="#">Something else here</a>
        </div>
        </div>
        </div>
        <div className="card-body"> 
		
		{this.state.showMore ? <div>
			<p>The KCS platform's easy to navigate user interface, makes it easy for the users to - </p>
<ol>
<li>On-board any kind of content. It does not require the users to understand the structure of the text or anything about Machine learning.</li>

<li>Phrase the questions in natural language in any form as desired.</li>

<li>It bridges the gap between the vocabulary used by the end user in the question as against the vocabulary used by the author of the content.</li>
</ol>

<p>The easy to use user-interface hides the complexity of the system making the information available on the finger tips.</p>

<p>KCS platform can also be used as the knowledge engine to power the chatbots. These chatbots do not require to be trained upfront on the kind of questions users can ask and also on the answers that are presented back to the users.</p>

<p>The use of Chatbots integrated with KCS to support answering user's questions, integrated with speech recognition capability, significantly improves the user experience.  <a onClick={this.showMore} className="show-more-link"> show less</a></p>
			</div> : <div>
			<p>The KCS platform's easy to navigate user interface, makes it easy for the users to - </p>
<ol>
<li>On-board any kind of content. It does not require the users to understand the structure of the text or anything about Machine learning.</li>

<li>Phrase the questions in natural language in any form as desired.</li>

<li>It bridges the gap between the vocabulary used by the end user in the question as against the vocabulary used by the author of the content. <a onClick={this.showMore} className="show-more-link"> show more</a></li>
</ol> 
			</div>}		
		
      
       <div>
        <a href="https://youtu.be/b3lHMALNtiI" target="blank">KaaS Cognitive Search</a>
           <i className="fa fa-bookmark-o p-3"></i>
           <i className="fa fa-share-alt p-3"></i>
           <i className="fa fa-ellipsis-v p-3"></i>
        </div>
        </div>
        </div>
        </div>
        );
      }
    }
    export default Trending;
